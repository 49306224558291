import { Theme } from '@material-ui/core/styles'
import theme_mirror from './mirror'
import theme_thairath_plus from './thairath_plus'

export type ThemeName = 'thairath-plus' | 'mirror'

export type CustomProperties = {
  app_color: string
  app_color_light: string
  logo_size: {
    width: string
    height: string
  }
}
export interface CustomTheme extends Theme {
  custom: CustomProperties
}

export default function getTheme(theme: ThemeName = 'thairath-plus') {
  switch (theme) {
    case 'thairath-plus':
      return { ...theme_thairath_plus }
    case 'mirror':
      return { ...theme_mirror }
    default:
      return { ...theme_thairath_plus }
  }
}
