import { Link, LinkBaseProps } from '@material-ui/core'
import React, { ReactElement } from 'react'
import { useHistory } from 'react-router-dom'

interface LinkRouterProps {
  to: string
  children: React.ReactNode
  linkProps?: LinkBaseProps
}

export default function LinkRouter(props: LinkRouterProps): ReactElement {
  const history = useHistory()
  return (
    <Link
      {...props?.linkProps}
      style={{
        cursor: 'pointer',
        ...props?.linkProps?.style
      }}
      onClick={() => history.push(props.to)}
    >
      {props.children}
    </Link>
  )
}
