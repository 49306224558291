import { gql } from '@apollo/client'

export const QUERY_GET_SUBTOPIC = gql`
  query ($params: SubTopicParams!) {
    SubTopic(params: $params) {
      _id
      value
      label
      topicRef
    }
  }
`

export const QUERY_GET_SUBTOPICS = gql`
  query {
    SubTopics {
      _id
      value
      label
      topicRef
    }
  }
`

export const QUERY_GET_SUBTOPICS_REF_TOPIC = gql`
  query ($params: SubTopicParams) {
    SubTopicsByTopic(params: $params) {
      _id
      value
      label
      topicRef
    }
  }
`