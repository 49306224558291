///<reference path= "../../../../node_modules/react-froala-wysiwyg/lib/index.d.ts" />
import { useContext, useEffect, useState, useRef } from 'react'
import 'froala-editor/css/froala_editor.pkgd.min.css'
import 'froala-editor/css/froala_style.css'
import 'froala-editor/js/plugins.pkgd.min.js'
import 'froala-editor/js/plugins/image.min.js'
import 'froala-editor/js/plugins/image_manager.min.js'
import 'froala-editor/js/plugins/paragraph_style.min.js'
import 'froala-editor/js/plugins/align.min.js'
import './plugins/elle_quote.js'
import './plugins/elle_quote.css'
import './plugins/customEmbeded.js'
import './plugins/customEmbeded.css'
import './plugins/paragraphStyle.css'
import './plugins/qa_block.js'
import FroalaEditorComponent from 'react-froala-wysiwyg'
import { ContentContext, ContentContextType } from '../context/ContentContext'
import useUpload from '@Hooks/useUpload'
import get from 'lodash.get'


interface EditorProps {
  index: number
}

export default function SettingEditor({ index }: EditorProps) {
  const editorRef = useRef<FroalaEditorComponent | null>(null);
  const {
    handleChange,
    content: [content],
  } = useContext(ContentContext) as ContentContextType
  const [text, setText] = useState<string>(
    get(content, `widgets[${index}].data[0].description`),
  )
  const [newImage, setNewImage] = useState<string>()
  const { onUpload } = useUpload()
  const handleModelChange = (model: any) => {
    setText(model)
    if (text !== model) {
      let keys = []
      let values = []
      keys.push(`widgets[${index}].data[0].description`)
      values.push(model)
      if (newImage) {
        keys.push(`images`)
        values.push([...content.images, newImage])
        setNewImage(undefined)
      }
      handleChange(keys, values)
    }
    // }
  }

  async function handleBeforeUpload(this: any, files: FileList | null) {
    console.log(`LOG: before upload ---> `)
    if (!files) {
      console.log(`LOG: is not have files`)
      return
    } else if (!content.id) {
      console.log(`LOG: is not have content id`)
      return
    }
    const res = await onUpload(files, 'image_files', {
      ref_id: content.id,
      res_type: 'froala',
    })

    if (res.status === 200) {
      const link = res.data[0].url
      this.image.insert(link, null, null, this.image.get())
      setNewImage(link)
    } else {
      this.image.insert(null, null, null, this.image.get())
      setNewImage(undefined)
    }
  }
  useEffect(() => {
    // setText()
    return () => { }
  }, [])
  return (
    <>
      <div className='editor-style'>
        <FroalaEditorComponent
          tag="textarea"
          ref={editorRef}
          onModelChange={handleModelChange}
          model={text}
          config={{
            key: process.env.REACT_APP_FROALA_KEY,
            pastePlain: true,
            placeholderText: 'รายละเอียด',
            charCounterCount: false,
            attribution: false,
            // autofocus: true,
            linkAlwaysBlank: true,
            toolbarSticky: true,
            heightMin: 500,
            useClasses: true,
            imageManagerLoadURL: `${process.env.REACT_APP_TR_PLUS_API}/api/images`,
            imageManagerLoadParams: {
              content_id: content.id,
              // res_type: 'froala',
            },
            imageDefaultWidth: 'auto',
            fontSize: ['8', '9', '10', '11', '12', '14', '16', '18', '24', '30', '36', '48', '60', '72', '96'],
            pluginsEnabled: [
              'align',
              'codeBeautifier',
              'codeView',
              'colors',
              // 'emoticons',
              // 'entities',
              // 'file',
              // 'fontAwesome',
              // 'fontFamily',
              'fontSize',
              'fullscreen',
              'image',
              // 'imageTUI',
              'imageManager',
              'inlineStyle',
              // 'inlineClass',
              // 'lineBreaker',
              // 'lineHeight',
              'link',
              'lists',
              'paragraphFormat',
              // 'quickInsert',
              // 'quote',
              // 'save',
              'table',
              'url',
              'video',
              // 'wordPaste',
              'elle_quote',
              'customEmbeded',
              'qa_block',
              'insertHR'
            ],

            toolbarButtons: {
              moreText: {
                buttonsVisible: 4,
                buttons: [
                  'bold',
                  'italic',
                  'underline',
                  'fontSize',

                  // more
                  'textColor',
                  'backgroundColor',
                  'strikeThrough',
                  'subscript',
                  'superscript',
                  // 'fontFamily',
                  'inlineClass',
                  'inlineStyle',
                  'clearFormatting',
                ],
              },
              moreParagraph: {
                buttonsVisible: 5,
                buttons: [
                  'paragraphStyle',
                  'paragraphFormat',
                  'formatUL',
                  'elle_quote',
                  'qa_block',

                  // more
                  'insertTable',
                  'alignLeft',
                  'alignCenter',
                  'alignRight',
                  'formatOL',
                  'formatOLSimple',
                  'alignJustify',
                  'lineHeight',
                  'outdent',
                  'indent',
                  // 'quote',
                  'insertHR'
                ],
              },
              moreRich: {
                buttonsVisible: 4,
                buttons: [
                  'insertLink',
                  'imageManager',
                  // 'imageUpload',
                  'insertVideo',
                  'customEmbededBtn',
                  // 'insertImage',
                  // 'insertTable',
                  // 'emoticons',
                  // 'fontAwesome',
                  // 'specialCharacters',
                  // 'insertFile',
                  // 'insertHR',
                ],
              },
              moreMisc: {
                buttons: [
                  'fullscreen',
                  'clearFormatting',
                  'html',

                  // more
                  'undo',
                  'redo',
                  // 'print',
                  // 'getPDF',
                  'spellChecker',
                  // 'selectAll',
                  'help',
                ],
                align: 'right',
                buttonsVisible: 3,
              },
            },
            paragraphStyles: {
              // qa_widget_title: 'QA Title',
              qa_widget_label: 'QA Label',
              qa_widget_question: 'QA Question',
              qa_widget_answer: 'QA Answer',
            },
            htmlRemoveTags: [''],
            // quickInsertButtons: ['image', 'ul', 'ol'],
            imageInsertButtons: ['imageBack', '|', 'imageUpload', 'imageManager'],
            videoInsertButtons: ['videoBack', '|', 'videoByURL', 'videoEmbed'],
            events: {
              initialized: function (this: any) {
                // console.log(`LOG: this ---> `, this)
              },
              'image.beforeUpload': handleBeforeUpload,
              'touchend': (event: any) => {
                if (editorRef.current) {
                  const editorFroala = (editorRef.current as any).editor;
                  if (event.target.nodeName !== 'IMG' && editorFroala?.helpers?.isMobile()) {
                    editorFroala?.image?.exitEdit();
                  }
                }
              }
            },
          }}
        />
      </div>
    </>
  )
}
