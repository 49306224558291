import React, {
  Fragment,
  useContext,
  useState,
  useEffect,
  useMemo,
} from 'react'
import Detail from './Detail/index'
// import { checkPermission } from "@Components/checkPermission"
import { AuthContextType, useAuth } from '@Hooks/useAuth'
import {
  Tabs,
  Tab,
  Breadcrumbs,
  Link,
  Button,
  Divider,
  Avatar,
} from '@material-ui/core'
import {
  createStyles,
  makeStyles,
  Theme,
  withStyles,
} from '@material-ui/core/styles'
import {
  QuoteContext,
  QuoteContextType,
  IUserWriting,
  //StatusSaveType,
} from './context/QuoteContext'
import clsx from 'clsx'
import PublishDialog from './Widget/PublishDialog'
import { AvatarGroup } from '@material-ui/lab'
import { ThemeName } from '../../theme'
import { useGlobal } from '@Hooks/useGlobal'
import isEmpty from 'lodash.isempty'
import { QUOTE } from '@Libs/const'

interface StyledTabProps {
  label?: string
  disabled?: boolean
  chlidren?: React.ReactNode
}

type PageType = {
  label: string
  component: React.ReactNode
  themes?: ThemeName[]
}

/**
 * themes empty is all theme
 */
const pages_all: PageType[] = [
  {
    label: 'เขียนเนื้อหา',
    component: <Detail />,
  },
]

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  demo1: {
    backgroundColor: theme.palette.background.paper,
  },
  padding: {
    padding: theme.spacing(3),
  },
  grow: {
    flexGrow: 1,
  },
  header_container: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  status_wrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  divider: {
    marginInline: theme.spacing(1),
  },
  saveBtn: {
    background: theme.palette.success.main,
    color: '#fff',
    '&:hover': {
      background: '#439c46',
    },
    margin: theme.spacing(1),
    '&:disabled': {
      cursor: 'not-allowed',
      pointerEvents: 'all !important',
    },
  },
  disabled: {
    // position: 'relative',
    // '&::before': {
    //   position: 'absolute',
    //   top: 0,
    //   left: 0,
    //   content: '""',
    //   width: '100%',
    //   height: '100%',
    //   background: 'rgba(255,255,255,0.6)',
    //   zIndex: 9999,
    //   cursor: 'not-allowed',
    // },
  },
}))

const QuoteEdit = () => {
  const {
    statusSave,
    dialog: [, setDialog],
    quote: [quote],
    userWriting,
    isDisabled,
  } = useContext(QuoteContext) as QuoteContextType
  const [tabNow, setTabNow] = useState(0)
  const classes = useStyles()
  const { theme_name } = useGlobal()
  const { checkPermission } = useAuth() as AuthContextType
  const [ts, setTs] = useState(Date.now())

  const pages = useMemo(
    () =>
      pages_all.filter(
        (page) =>
          isEmpty(page.themes) || page.themes?.indexOf(theme_name) !== -1,
      ),
    [theme_name],
  )

  useEffect(() => {
    // const checked = checkPermission('content_view')
    // if (!checked) {
    //   window.location.href = '/'
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabNow(newValue)
  }

  const openDialog = () => {
    setDialog(true)
  }

  const generateUrl = () => {
    const base_url = process.env.REACT_APP_TR_PLUS

    return base_url
  }


  return (
    <Fragment>
      <div className={classes.root}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="none" color="inherit">
              Quote
            </Link>
            <Link underline="none" color="inherit">
              {quote?._id ? 'Edit Quote' : 'Add Quote'}
            </Link>
          </Breadcrumbs>
          <div className={classes.header_container}>
          <>
            {/* <Divider
              className={classes.divider}
              orientation="vertical"
              flexItem
            /> */}
            {checkPermission('content_view') && (
              <Fragment>
               <Button
                   variant="outlined"
                   size="medium"
                   style={{
                     marginLeft: "0px",
                     color: "#FFF",
                     background: "#4caf50",
                   }}
                  className={clsx(
                    quote?.status !== QUOTE.STATUS.PUBLISH &&
                      classes.saveBtn,
                    // statusSave.loading && classes.cursor_not_allowed,
                  )}
                  onClick={openDialog}
                  disabled={statusSave.loading}
                >
                  Publish
                </Button>
                <Divider
                  className={classes.divider}
                  orientation="vertical"
                  flexItem
                />
              </Fragment>
            )}

            {false ? (
              <></>
            ) : (
              <>
                <Button
                  variant="outlined"
                  size="medium"
                >
                  <a
                    href={`${generateUrl()}?ts=${ts}`}
                    target="_blank"
                    style={{
                      textDecoration: 'none',
                      color: 'rgba(0, 0, 0, 0.87)',
                    }}
                    rel="noreferrer"
                    onClick={() => setTs(Date.now())}
                  >
                    Link To Web
                  </a>
                </Button>
              </>
            )}
          </>
          <div className={classes.grow} />
          <AvatarGroup>
            {userWriting &&
              userWriting.map((item: IUserWriting, index: number) => (
                <Avatar
                  key={`${item.user._id}${index}`}
                  alt={item.user.first_name + ' ' + item.user.last_name}
                  title={item.user.first_name + ' ' + item.user.last_name}
                  src={
                    item.user.profile_image != null
                      ? item.user.profile_image + '?width=36'
                      : ''
                  }
                  imgProps={{
                    title: item.user.first_name + ' ' + item.user.last_name,
                  }}
                >
                  {item.user.first_name.charAt(0).toUpperCase()}
                </Avatar>
              ))}
          </AvatarGroup>
        </div>

        <div className={classes.demo1}>
          <AntTabs
            onChange={handleChange}
            aria-label="ant example"
            value={tabNow}
          >
            {pages.map((page, index) => (
              <AntTab key={index} label={page.label} />
            ))}
          </AntTabs>
          <div className={clsx(isDisabled && classes.disabled)}>
            <div className={classes.padding}>{pages[tabNow].component}</div>
          </div>
        </div>
      </div>
      <PublishDialog />
    </Fragment>
  )
}

const AntTabs = withStyles({
  root: {
    borderBottom: '1px solid #e8e8e8',
    position: 'relative',
  },
  indicator: {
    backgroundColor: '#000',
  },
})(Tabs)

const AntTab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      textTransform: 'none',
      minWidth: 72,
      marginRight: theme.spacing(3),
      '&:hover': {
        color: '#40a9ff',
        opacity: 1,
      },
      '&$selected': {
        color: '#000',
        fontWeight: theme.typography.fontWeightMedium,
      },
      '&:focus': {
        color: '#40a9ff',
      },
    },
    selected: {},
  }),
)((props: StyledTabProps) => (
  <Tab disableRipple {...props}>
    {props.chlidren}
  </Tab>
))

export default QuoteEdit
