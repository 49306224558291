import { ThemeName } from './../../../theme/index'
import thairath_plus from './thairath_plus'
import mirror from './mirror'

interface IGroup {
  name: string
  key: string
  type: {
    view: boolean
    edit: boolean
  }
}

export interface IRule {
  name: string
  group: Array<IGroup>
}

export interface IRole {
  getPermissionByRole: (role: string) => Array<string>
  rules: Array<IRule>
}

const roles: { [key in ThemeName]: IRole } = {
  'thairath-plus': thairath_plus,
  mirror,
}
export default roles
