import FroalaEditor from 'froala-editor/js/froala_editor.min.js'
global.FroalaEditor = FroalaEditor
;(function (FroalaEditor) {
  FroalaEditor.DefineIcon('elle_quote', {
    NAME: 'quote-left',
    SVG_KEY: 'blockquote',
  })
  FroalaEditor.RegisterCommand('elle_quote', {
    title: 'Quote',
    focus: false,
    undo: false,
    refreshAfterCallback: false,
    callback: function () {
      this.elle_quote.insert()
    },
  })

  // Add an option for your plugin.
  FroalaEditor.DEFAULTS = Object.assign(FroalaEditor.DEFAULTS, {
    elle_quote: false,
  })

  // Define the plugin.
  // The editor parameter is the current instance.
  FroalaEditor.PLUGINS.elle_quote = function (editor) {
    // Private variable visible only inside the plugin scope.
    var quote = null

    // Private method that is visible only inside plugin scope.
    function _privateMethod() {
      console.log(quote)
    }

    // Public method that is visible in the instance scope.
    function publicMethod() {
      console.log(_privateMethod())
    }

    function insert() {
      const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
        /[xy]/g,
        function (c) {
          var r = (Math.random() * 16) | 0,
            v = c === 'x' ? r : (r & 0x3) | 0x8
          return v.toString(16)
        },
      )
      editor.html
        .insert(`<div data-component="quote" class="elle_quote" data-id="${uuid}">
        <p class="blockquote">
        </p>
      </div>`)
    }

    // The start point for your plugin.
    function _init() {
      // You can access any option from documentation or your custom options.
      console.log('editor.opts.elle_quote', editor.events)

      // Call any method from documentation.
      // editor.methodName(params);

      // You can listen to any event from documentation.
      editor.events.on('html.get', function () {})
      editor.html.get("[data-component='qoute']")
    }

    // Expose public methods. If _init is not public then the plugin won't be initialized.
    // Public method can be accessed through the editor API:
    // editor.myPlugin.publicMethod();
    return {
      _init: _init,
      publicMethod: publicMethod,
      insert,
    }
  }
})(FroalaEditor)
