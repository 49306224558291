import React, { Fragment, useState, useEffect } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  makeStyles,
  Grid,
  Link,
} from '@material-ui/core'
import { useQuery } from '@apollo/client'
import { QUERY_GET_ALL_QUOTES_V2 } from '@Api/quote_api'
import Loading from '@Components/Loading'
import { quoteType } from '@Types/quote_type'
import ButtonLink from '@Components/ButtonLink'
import SearchQuoteForm, { FormSearchQuoteType } from './Widget/searchForm'
import { Pagination } from '@material-ui/lab'
import { Add } from '@material-ui/icons'
import { useAuth } from '@Hooks/useAuth'
import { useGlobal } from '@Hooks/useGlobal'

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
    width: '100%',
  },
  grow: {
    flexGrow: 1,
  },
  paper: {
    width: '100%',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    minHeight: 650,
  },
  pagination: {
    paddingBlock: theme.spacing(1),
    paddingInline: theme.spacing(1),
  },
  box_none: {
    boxShadow: 'none',
  },
  button_add: {
    marginInline: theme.spacing(1.5),
  },
}))

const ListQuote = () => {
  
    const { checkPermission } = useAuth()
  const [addContentBtn, setAddContentBtn] = useState<boolean>(false)
  const { wording } = useGlobal()

  useEffect(() => {
    const checked = checkPermission('content_view')
    const editCheck = checkPermission('content_edit')
    if (!checked) {
      window.location.href = '/'
    }

    if (editCheck) {
      setAddContentBtn(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [pageInfo, setPageInfo] = useState({
    page: 1,
    limit: 10,
  })

  const classes = useStyles()
  const [parameter, setParameter] = useState<FormSearchQuoteType>()
  const { loading, error, data, refetch } = useQuery(QUERY_GET_ALL_QUOTES_V2, {
    variables: {
      quoteParams: {
        ...parameter,
        order_by: [
          {
            field: 'last_update',
            sort: 'desc',
          },
        ],
      },
      limit: pageInfo.limit,
      page: 1,
    },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  })

  const handleSubmit = (form: FormSearchQuoteType) => {
    setPageInfo({ ...pageInfo, page: 1 })
    setParameter(form)
    refetch({
      quoteParams: {
        ...form,
        order_by: [
          {
            field: 'last_update',
            sort: 'desc',
          },
        ],
        // has_video: false // for test has_video
      },
      limit: pageInfo.limit,
      page: 1,
    })
  }
  
  const handleChangePage = (e: React.ChangeEvent<unknown>, page: number) => {
    setPageInfo({ ...pageInfo, page })
    refetch({
      quoteParams: {
        ...parameter,
        order_by: [
          {
            field: 'last_update',
            sort: 'desc',
          },
        ],
      },
      limit: pageInfo.limit,
      page,
    })
  }

  if (error) {
    console.log('LOG: error ---> ', error)
    return <p>Error :(</p>
  }

  return (
    <Fragment>
      <>
        <Paper className={classes.paper}>
          <div className='filter-addcontent'>
            <SearchQuoteForm
              onSubmit={handleSubmit}
              actionButton={
                addContentBtn ? (
                  <ButtonLink
                    className={classes.button_add}
                    to="/quote/edit"
                    startIcon={<Add />}
                    style={{ order: -1 }}
                    variant="contained"
                    color="primary"
                  >
                    เพิ่ม Content
                  </ButtonLink>
                ) : (
                  ''
                )
              }
            />
           </div>
          <TableContainer>
            <Table className='table-style table-quote'>
              <TableHead>
                <TableRow>
                  <TableCell style={{ minWidth: 300 }} align="left">ข้อความ</TableCell>
                  <TableCell style={{ minWidth: 100 }} align="left">URL</TableCell>
                  {/* <TableCell style={{ minWidth: 80 }} align="left">Title</TableCell> */}
                  <TableCell align="left">{wording['topic']}</TableCell>
                  <TableCell style={{ width: 100 }} align="left">สถานะ</TableCell>
                  <TableCell style={{ minWidth: 150 }} align="left">แก้ไขล่าสุด</TableCell>
                  <TableCell style={{ minWidth: 100 }} align="left">ผู้แก้ไขล่าสุด</TableCell>
                  <TableCell style={{ minWidth: 150 }} align="left">เวลาเผยแพร่</TableCell>
                </TableRow>
              </TableHead>
              {loading ? (
                <Loading />
              ) : (
                <TableBody>
                  {data.Quotes?.data &&
                    data.Quotes.data.map((item: quoteType) => (
                      <TableRow hover>
                        <TableCell align="left">
                          <Link
                            style={{ cursor: 'pointer' }}
                            href={`/quote/edit/${item._id}`}
                          >
                            <div dangerouslySetInnerHTML={{ __html: item.description }} />
                          </Link>
                        </TableCell>
                        <TableCell align="left">
                          <Link
                            style={{ cursor: 'pointer' }}
                            href={item.link_url}
                            target='_blank'
                          >
                            {item.link_url}
                          </Link>
                        </TableCell>
                        {/* <TableCell align="center">
                          <Link
                            style={{ cursor: 'pointer' }}
                            href={`/quote/edit/${item._id}`}
                          >
                          {item?.title}
                          </Link>
                        </TableCell> */}
                        <TableCell align="center">
                          {item?.categories}
                        </TableCell>
                        <TableCell align="left">
                          {(item.status === "30") ? 'PUBLIC' : 'NON-PUBLIC'}
                        </TableCell>
                        <TableCell align="left">{`${item.last_update}`}</TableCell>
                        <TableCell align="left">
                          {item.created_by &&
                            `${item.created_by.first_name +
                            ' ' +
                            item.created_by.last_name
                            }`}
                        </TableCell>
                        <TableCell align="left">
                          {`${item.publish_time}`}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              )}
            </Table>
          </TableContainer>
          <Grid container>
            <div className={classes.grow} />
            <Grid item>
              <Pagination
                onChange={handleChangePage}
                page={pageInfo.page}
                className={classes.pagination}
                count={Math.ceil(data?.Quotes?.total / pageInfo.limit)}
                showFirstButton
                showLastButton
              />
            </Grid>
          </Grid>

        </Paper>
      </>
    </Fragment>
  )
}

export default ListQuote
