import { wordingType } from '@Types/wording_type'
const wording: wordingType = {
  app_name: 'Thairath+ CMS',
  favicon: 'thairath-plus-icon-32x32.ico',
  topic: 'Topic',
  subtopic: 'Sub-Topic',
  category: 'Category',
  channel: 'Channel',
  content_page: {
    abstract: 'ข้อความแชร์',
    summary: 'สรุปเนื้อหาข่าว',
  },
}

export default wording
