import { gql } from '@apollo/client'

export const QUERY_GET_ALL_QUOTES = gql`
  query {
    Quotes: CmsQuotes {
      _id
      title
      show_in_page
      categories
      description
      link_url
      status
      publish_time(format: "DD/MM/YYYY HH:mm:ss")
      expire_time(format: "DD/MM/YYYY HH:mm:ss")
      last_update(format: "DD/MM/YYYY HH:mm:ss")
      created_by {
        first_name
        last_name
        nick_name
      }
    }
  }
`

export const QUERY_GET_ALL_QUOTES_V2 = gql`
  query CmsQuoteV2($quoteParams: QuoteParams, $page: Int, $limit: Int) {
    Quotes: CmsQuoteV2(params: $quoteParams, page: $page, limit: $limit) {
      total
      data {
        _id
        title
        show_in_page
        categories
        description
        link_url
        status
        publish_time(format: "DD/MM/YYYY HH:mm:ss")
        expire_time(format: "DD/MM/YYYY HH:mm:ss")
        last_update(format: "DD/MM/YYYY HH:mm:ss")
        created_by {
          first_name
          last_name
          nick_name
        }
      }
    }
  }
`

export const QUERY_GET_ALL_QUOTES_OPTION = gql`
  query {
    Quotes {
      value: title
      id: _id
    }
  }
`

export const QUERY_GET_QUOTE_BY_ID = gql`
  query ($_id: ID!) {
    Quote: CmsQuote(_id: $_id) {
      _id
      title
      show_in_page
      categories
      description
      link_url
      status
      publish_time(format: "DD/MM/YYYY HH:mm:ss")
      expire_time(format: "DD/MM/YYYY HH:mm:ss")
      last_update(format: "DD/MM/YYYY HH:mm:ss")
    }
  }
`

export const MUTATION_ADD_QUOTE = gql`
  mutation (
    $title: String
    $show_in_page: [String]
    $categories: String
    $description: String
    $link_url: String
    $status: String
    $publish_time: String
    $expire_time: String
  ) {
    AddQuote(
      title: $title
      show_in_page: $show_in_page
      categories: $categories
      description: $description
      link_url: $link_url
      status: $status
      publish_time: $publish_time
      expire_time: $expire_time
    ) {
      statusCode
      message
      data
    }
  }
`

export const MUTATION_EDIT_QUOTE = gql`
  mutation (
    $_id: ID
    $title: String
    $show_in_page: [String]
    $categories: String
    $description: String
    $link_url: String
    $status: String
    $publish_time: String
    $expire_time: String
  ) {
    EditQuote(
      _id: $_id
      title: $title
      show_in_page: $show_in_page
      categories: $categories
      description: $description
      link_url: $link_url
      status: $status
      publish_time: $publish_time
      expire_time: $expire_time
    ) {
      statusCode
      message
      data
    }
  }
`

export const MUTATION_PUBLISH_QUOTE = gql`
  mutation PublishQuote(
    $_id: ID!
    $publish_time: Date
    $expire_time: Date
  ) {
    PublishQuote(
      _id: $_id
      publish_time: $publish_time
      expire_time: $expire_time
    ) {
      statusCode
      message
      data
    }
  }
`

export const MUTATION_EDIT_QUOTE_STATUS = gql`
  mutation (
    $_id: ID!
    $status: String!
  ) {
    EditQuoteStatus(
      _id: $_id, 
      status: $status
    ) {
      statusCode
      message
      data
    }
  }
`

export const QUERY_GET_OPTION_SEARCH = gql`
  query {
    Topics {
      id: _id
      value: label
    }
  }
`
