import React, { createContext, useCallback, useEffect, useState } from 'react'
import { useLazyQuery, useMutation } from '@apollo/client'
import {
  QUERY_GET_ALL_QUOTES,
  MUTATION_ADD_QUOTE,
  MUTATION_EDIT_QUOTE,
  MUTATION_PUBLISH_QUOTE,
  MUTATION_EDIT_QUOTE_STATUS,
} from '@Api/quote_api'
import { quoteType } from '@Types/quote_type'
import set from 'lodash.set'
import debounce from 'lodash.debounce'
import { IUserJWT } from '@Types/user_type'
import { useAuth } from '@Hooks/useAuth'
import isEmpty from 'lodash.isempty'
import { QUOTE } from '@Libs/const'
import { ISuggestionTag } from '@Types/suggestion_tag_type'
import { QUERY_GET_SUGGESTION_TAG_BY_SECTION } from '@Api/suggestion_tags_api'


export const QuoteContext = createContext({})

export interface IUserWriting {
  quote_id: string
  user: IUserJWT
}

type PropertyType =
  | keyof quoteType
  | Array<keyof quoteType>
  | string
  | Array<string>

// แก้ any ด้วย
export type QuoteContextType = {
  quote: [quoteType, React.Dispatch<React.SetStateAction<quoteType>>]
  publishNow: [boolean, React.Dispatch<React.SetStateAction<boolean>>]
  dialog: [boolean, React.Dispatch<React.SetStateAction<boolean>>]
  statusSave: StatusSaveType
  userWriting: IUserWriting[]
  isDisabled: boolean
  handleChange: (property: PropertyType, value: any) => quoteType
  handleSelectChange: (property: PropertyType, value: any) => void
  handleChangeStatus: (status: string) => void
  newQuote: () => quoteType
  saveQuote: () => void
  onPublish: (publish_time?: string | Date, expire_time?: string | Date) => void
  onInitQuote: () => void
}

export type StatusSaveType = {
  loading: boolean
  status_code?: number
  error_message?: string
}

const getDefaultQuote = () =>
({
  _id: '',
  title: '',
  show_in_page: [],
  categories: '',
  description: '',
  link_url: '',
  status: "10",
} as quoteType)

export const QuoteContextProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const [quote, setQuote] = useState<quoteType>()
  const [publishNow, setPublishNow] = useState(true)
  const [addQuote] = useMutation(MUTATION_ADD_QUOTE)
  const [editQuote] = useMutation(MUTATION_EDIT_QUOTE)
  const [publishQuote] = useMutation(MUTATION_PUBLISH_QUOTE)
  const [editQuoteStatus] = useMutation(MUTATION_EDIT_QUOTE_STATUS)
  const [statusSave, setStatusSave] = useState<StatusSaveType>({
    loading: false,
    status_code: 200,
  })
  const [dialog, setDialog] = useState(false)
  //const [gallery]                     = useState<string[]>()
  const [userWriting, setUserWriting] = useState<IUserWriting[]>([])
  const { user: userJwt, checkPermission } = useAuth()
  const [isDisabled, setIsDisabled] = useState(false)

  const onInitQuote = () => {
    setQuote(getDefaultQuote())
  }

  const [suggestionTags, setSuggestionTags] = useState<ISuggestionTag>()

  const [fetchSuggestionTags] = useLazyQuery(
    QUERY_GET_SUGGESTION_TAG_BY_SECTION,
    {
      onCompleted: ({ CmsSuggestionTag }) => {
        setSuggestionTags(CmsSuggestionTag)
      },
    },
  )

  const newQuote = async (data: quoteType) => {
    const {
      data: { AddQuote },
      errors,
    } = await addQuote({
      variables: data,
      refetchQueries: [
        {
          query: QUERY_GET_ALL_QUOTES,
          variables: {
            quoteParams: {
              order_by: [
                {
                  field: 'updated_time',
                  sort: 'desc',
                },
              ],
            },
          },
        },
      ],
    })

    if (isEmpty(errors)) {
      setStatusSave({
        loading: false,
        status_code: AddQuote?.statusCode,
      })
    }

    return AddQuote.data
  }

  const handleChange = (property: PropertyType, value: any): quoteType => {
    if (!checkPermission('content_view')) return {} as quoteType
    console.log(`LOG: property ---> `, property, ` \nLOG:  value ---> `, value)
    setStatusSave({
      loading: true,
    })

    let newData = { ...quote, status: QUOTE.STATUS.DRAFT } as quoteType
    if (Array.isArray(property)) {
      for (let i = 0; i < property.length; i++) {
        const key = property[i]
        const val = value[i]
        newData = set<quoteType>(newData, key, val)
      }
    } else {
      newData = set<quoteType>(newData, property, value)
    }

    setQuote(newData)
    saveDraftDelay(newData)
    return newData
  }

  const handleSelectChange = (property: PropertyType, value: any) => {
    if (!checkPermission('content_view')) return
    console.log(`LOG: property ---> `, property, ` \nLOG:  value ---> `, value)
    setStatusSave({
      loading: true,
    })

    let newData = { ...quote } as quoteType
    if (Array.isArray(property)) {
      for (let i = 0; i < property.length; i++) {
        const key = property[i]
        const val = value[i]
        newData = set<quoteType>(newData, key, val)
      }
    } else {
      newData = set<quoteType>(newData, property, value)
    }
    //console.log('value newData ==>', newData)
    setQuote(newData)
    saveDraftDelay(newData)
  }

  const handleChangeStatus = async (status: string) => {
    if (!checkPermission('content_view')) return
    if (!quote?._id) return

    setStatusSave({loading: true,})

    const newData = set<quoteType>({ ...quote }, 'status', status)
    //console.log('new data status ==>', newData)
    setQuote(newData)
    saveDraftDelay(newData)

    const { data } = await editQuoteStatus({
      variables: {
        _id: quote._id,
        status: status,
      },
    })

    if (data?.EditQuoteStatus?.statusCode === 200) {
      setStatusSave({
        loading: false,
        status_code: 200,
      })
    } else {
      console.log(`LOG: error ---> `, data?.EditQuoteStatus?.message)
      setStatusSave({
        loading: true,
        status_code: 400,
      })
    }


  }

  const insertQuote = async (newData: quoteType) => {
    if (!newData) {
      console.log('no data')
      return
    }

    newData = set(newData, 'title', newData.title)
    newData = set(newData, 'categories', newData.categories)
    newData = set(newData, 'description', newData.description)

    if (newData._id) {
      console.log('save', new Date().toLocaleTimeString())
      const { data } = await editQuote({
        variables: newData,
      })
      if (data.EditQuote?.statusCode === 200) {
        setStatusSave({
          loading: false,
          status_code: 200,
        })
        setQuote(data.EditQuote?.data)
        console.log('auto save success', newData._id)
      } else {
        setStatusSave({
          loading: false,
          status_code: 400,
          error_message: 'auto save fail',
        })
        console.log('auto save fail')
      }
    } else {
      const response = await newQuote(newData)
      console.log('new data  response ==>', response)
      if (!response) return
      newData._id = response._id
      setQuote({ ...newData })
      return
    }
  }

  //const saveDraft = useCallback(debounce(insertQuote, 3000), []) // eslint-disable-line react-hooks/exhaustive-deps
  const saveDraftDelay = useCallback(debounce(insertQuote, 3000), []) // eslint-disable-line react-hooks/exhaustive-deps

  const onPublish = async (
    publish_time?: string | Date,
    expire_time?: string | Date,
  ) => {
    //if (!checkPermission('content_view')) return
    const variables = {
      _id: quote?._id,
      publish_time: publishNow ? '' : publish_time,
      expire_time: !expire_time ? '' : expire_time,
    }
    const { data } = await publishQuote({
      variables: variables,
    })
    console.log(`LOG: data ---> `, data)
    if (data.PublishQuote?.statusCode === 200) {
      setQuote(data.PublishQuote.data)
    } else {
    }
  }

  // check user close tab or browser
  const onBeforeUnload = (e: BeforeUnloadEvent) => {
    if (statusSave.loading) {
      var confirmationMessage = 'o/'
        ; (e || window.event).returnValue = confirmationMessage //Gecko + IE
      return confirmationMessage //Webkit, Safari, Chrome
    }
  }

  const [isReconnect, setReconnect] = useState(0)

  useEffect(() => {
    if (quote?._id) {
      let ws = new WebSocket(process.env.REACT_APP_WEB_SOCKET || '')

      ws.onopen = () => {
        const payload = {
          type: 'join',
          quote_id: quote?._id,
          user: userJwt,
        }
        ws.send(JSON.stringify(payload))
        if (isReconnect) {
          setReconnect(0)
        }
      }

      ws.onmessage = (e: any) => {
        const message = JSON.parse(e.data)
        // console.log(`LOG: message ---> `, message)
        const found = message?.users?.findIndex(
          (item: IUserWriting) => item.user._id === userJwt._id,
        )
        setIsDisabled(found !== 0)
        setUserWriting(message?.users)
      }

      ws.onerror = (e: any) => {
        ws.close()
      }

      ws.onclose = (e: any) => {
        // ไม่ได้เกิดจาก client close
        if (e.code === 1006) {
          setIsDisabled(true)
          setTimeout(() => {
            setReconnect(isReconnect + 1)
          }, 1000)
        } else {
          setReconnect(0)
        }
      }

      return () => {
        ws.close()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quote?._id, isReconnect])

  useEffect(() => {
    window.addEventListener('beforeunload', onBeforeUnload)
    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusSave.loading])

  const store = {
    quote: [quote, setQuote],
    publishNow: [publishNow, setPublishNow],
    dialog: [dialog, setDialog],
    statusSave,
    userWriting,
    isDisabled,
    handleChange,
    handleSelectChange,
    handleChangeStatus,
    // newPodcastDraft,
    onPublish,
    onInitQuote,
  }

  return (
    <QuoteContext.Provider value={store}>{children}</QuoteContext.Provider>
  )
}
