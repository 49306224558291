import { Box, CircularProgress } from '@material-ui/core'
import { ReactElement } from 'react'

export default function Loading(): ReactElement {
  return (
    <>
      <Box
        display="flex"
        width="100%"
        height="100%"
        alignItems="center"
        justifyContent="center"
      >
        <CircularProgress />
      </Box>
    </>
  )
}
