import { Snackbar, SnackbarOrigin } from '@material-ui/core'
import { Alert, Color } from '@material-ui/lab'
import React, { useState, createContext, useContext } from 'react'
export const SnackbarContext = createContext({})

export const useSnackbar = () => {
  return useContext(SnackbarContext) as SnackbarContextType
}

export type SnackbarContextType = {
  onClose: () => void
  onToggle: (body: SnackbarType) => void
}
export type SnackbarType = {
  open: boolean
  message?: string
  type?: Color
  position?: SnackbarOrigin
  duration?: number
}
export const SnackbarContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [snackbar, setSnackbar] = useState<SnackbarType>({
    open: false,
    position: { vertical: 'bottom', horizontal: 'left' },
    duration: 6000,
  })
  const onClose = () => setSnackbar((prev) => ({ ...prev, open: false }))
  const onToggle = (body: SnackbarType) =>
    setSnackbar((prev) => ({ ...prev, ...body }))
  const store = {
    onClose,
    onToggle,
  }

  return (
    <SnackbarContext.Provider value={store}>
      {children}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={snackbar.duration}
        onClose={onClose}
        anchorOrigin={snackbar.position}
      >
        <Alert onClose={onClose} severity={snackbar.type}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  )
}
