import { gql } from '@apollo/client'

export const QUERY_GET_VIDEO_FOR_PICK = gql`
  query Video($videoParams: VideoParams, $page: PageInfoInput, $limit: Int) {
    Video(params: $videoParams, page_info: $page, limit: $limit) {
      total
      data {
        id
        title
        widgets {
          layout
          data {
            title
            abstract
            description
            thumbnails
          }
        }
        JWPlayerId
        images
        status
        publish
        author {
          _id
          first_name
          last_name
        }
        premium
        topic {
          _id
          value
          label
        }
        categories {
          _id
          value
          label
        }
        tags
        publish_time
        created_time
        expire_time
        summary
        created_by {
          first_name
          last_name
          nick_name
        }
        updated_time
        thumbnail_1_1
        thumbnail_9_16
        thumbnail_share_9_16
        cover
        count_view
        content_type
        content_type_expire_time
      }
    }
  }
`

export const QUERY_GET_VIDEO_FOR_PICKED = gql`
  query CmsListVideo($params: ListParams!) {
    CmsListVideo(params: $params) {
      type
      section
      items {
        type
        id
        Video {
          id
          title
          widgets {
            layout
            data {
              title
              abstract
              description
              thumbnails
            }
          }
          JWPlayerId
          images
          status
          publish
          author {
            _id
            first_name
            last_name
          }
          premium
          topic {
            _id
            value
            label
          }
          categories {
            _id
            value
            label
          }
          tags
          publish_time
          created_time
          expire_time
          summary
          created_by {
            first_name
            last_name
            nick_name
          }
          updated_time
          thumbnail_1_1
          thumbnail_9_16
          thumbnail_share_9_16
          cover
          content_type
          content_type_expire_time
        }
        tag
      }
    }
  }
`

export const MUTATION_ADD_VIDEO_EDITOR_PICK = gql`
  mutation addVideoList(
    $type: String!
    $section: String!
    $items: [ListVideoItemInput]!
  ) {
    AddListVideo(section: $section, type: $type, items: $items) {
      statusCode
      message
    }
  }
`
