import { gql } from '@apollo/client'

export const QUERY_GET_CONTENT_FOR_PICK = gql`
  query ContentForPick($contentParams: ContentParams, $page: Int, $limit: Int) {
    ContentForPick(params: $contentParams, page: $page, limit: $limit) {
      _id
      id
      title
      abstract
      summary
      categories {
        _id
        value
        label
      }
      topic {
        _id
        value
        label
      }
      subtopic {
        _id
        value
        label
      }
      tags
      status
      created_time
      updated_time(format: "DD/MM/YYYY HH:mm:ss")
      pr_expire_time(format: "DD/MM/YYYY HH:mm:ss")
      publish_time
      count_view
      cover
      widgets {
        layout
        # data {
        #   thumbnails
        # }
      }
      content_type
      content_type_expire_time
    }
  }
`
export const QUERY_GET_CONTENT_PICKED = gql`
  query getContentCmsList($listParams: ListParams!) {
    CmsList(params: $listParams) {
      items {
        extension
        id
        type
        Content {
          _id
          id
          title
          abstract
          summary
          categories {
            _id
            value
            label
          }
          topic {
            _id
            value
            label
          }
          tags
          status
          created_time
          updated_time(format: "DD/MM/YYYY HH:mm:ss")
          expire_time(format: "DD/MM/YYYY HH:mm:ss")
          pr_expire_time(format: "DD/MM/YYYY HH:mm:ss")
          publish_time
          count_view
          widgets {
            layout
            # data {
            #   thumbnails
            # }
          }
          cover
          content_type
          content_type_expire_time
        }
        Quote {
          _id
          title      
          description    
          categories 
          show_in_page
          link_url
          last_update(format: "DD/MM/YYYY HH:mm:ss")
          expire_time 
          publish_time
        }
      }
    }
  }
`

export const QUERY_GET_PANORAMA_PICKED = gql`
  query getContentCmsList($listParams: ListParams!) {
    CmsList(params: $listParams) {
      items {
        extension
        id
        type
        Content {
          _id
          id
          title
          abstract
          summary
          categories {
            _id
            value
            label
          }
          topic {
            _id
            value
            label
          }
          tags
          status
          created_time
          updated_time(format: "DD/MM/YYYY HH:mm:ss")
          publish_time
          count_view
          widgets {
            layout
            # data {
            #   thumbnails
            # }
          }
          cover
          content_type
          content_type_expire_time
        }
      }
    }
  }
`

export const MUTATION_ADD_EDITOR_PICK = gql`
  mutation addList(
    $type: String!
    $section: String!
    $items: [ListItemInput]!
  ) {
    AddList(section: $section, type: $type, items: $items) {
      statusCode
      message
    }
  }
`


export const QUERY_GET_QUOTE_FOR_PICK = gql`
query QuoteForPick($quoteParams: QuoteParams, $page: Int, $limit: Int) {  
  QuoteForPick(params: $quoteParams, page: $page, limit: $limit) {    
      _id     
      title      
      description    
      categories 
      show_in_page
      link_url
      status    
      last_update(format: "DD/MM/YYYY HH:mm:ss")
      expire_time 
      publish_time
  }
}
`
