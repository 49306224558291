import { Fragment, useContext } from 'react'
import { useQuery } from '@apollo/client'
import {
  Typography,
  List,
  ListItemAvatar,
  ListItem,
  ListItemText,
  Avatar,
} from '@material-ui/core'
import ImageIcon from '@material-ui/icons/Image'
import { VideoContentContext, VideoContentContextType } from '../context/VideoContext'
import { QUERY_GET_CONTENT_HISTORY } from '@Api/video_api'
import { IContent } from '@Types/content_type'

export default function History() {
  const {
    content: [content],
  } = useContext(VideoContentContext) as VideoContentContextType
  const { data } = useQuery(QUERY_GET_CONTENT_HISTORY, {
    variables: {
      id: content.id,
    },
    notifyOnNetworkStatusChange: true,
  })

  return (
    <Fragment>
      <Typography className='header-h3'>
          การเผยแพร่
      </Typography>

      {data?.CmsVideoHistory &&
        data.CmsVideoHistory.map((item: IContent) => (
          <List>
            <ListItem>
              {item?.created_by?.profile_image ? (
                <ListItemAvatar>
                  <Avatar
                    alt={item?.created_by.first_name}
                    src={item?.created_by?.profile_image}
                  />
                </ListItemAvatar>
              ) : (
                <ListItemAvatar>
                  <Avatar>
                    <ImageIcon />
                  </Avatar>
                </ListItemAvatar>
              )}

              <ListItemText
                primary={`${item.created_by?.first_name} ${item.created_by?.last_name}`}
                secondary={`${item.publish_time}`}
              />
            </ListItem>
          </List>
        ))}
    </Fragment>
  )
}
