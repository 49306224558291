import { useQuery } from '@apollo/client'
import {
  Collapse,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  TextField,
} from '@material-ui/core'
import { FilterList, Search } from '@material-ui/icons'
import React, { ReactElement, useState } from 'react'
import { QUERY_GET_OPTION_SEARCH } from '@Api/video_api'
import AutoCompleteSearch from '@Components/AutoCompleteSearch'
import Loading from '@Components/Loading'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { CONTENT } from '@Libs/const'
import clsx from 'clsx'
import { useGlobal } from '@Hooks/useGlobal'
import { ThemeName } from 'theme'
interface SearchFormProps {
  onSubmit: (data: FormSearchType) => void
  actionButton?: React.ReactNode
  size?: 'small' | 'normal'
  display?: {
    categories?: boolean
    start?: boolean
    end?: boolean
    status?: boolean
    topic?: boolean
    created_by?: boolean
    author?: boolean
  }
}

export type FormSearchType = {
  // abstract?: string
  categories?: string[]
  // description?: string
  end?: string
  start?: string
  status?: string[]
  // summary?: string
  // tags?: string[]
  // title?: string
  topic?: string[]
  created_by?: string[]
  all?: string
  author?: string[]
}

const useStyles = makeStyles((theme) => ({
  search_box: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  form_search: {
    // height: 300,
    padding: theme.spacing(2),
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  icon_button: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  grow: {
    flexGrow: 1,
  },
  date: {},
  none: {
    display: 'none',
  },
}))
const default_display = (theme: ThemeName) => ({
  categories: theme === 'thairath-plus',
  start: true,
  end: true,
  status: true,
  topic: true,
  created_by: true,
  author: true,
})
export default function SearchForm({
  onSubmit,
  actionButton,
  size = 'normal',
  display: display_props,
}: SearchFormProps): ReactElement {
  const classes = useStyles()
  const { wording, theme_name } = useGlobal()
  const [open, setOpen] = useState(false)
  const onToggle = () => setOpen(!open)
  const [form, setForm] = useState<FormSearchType>()
  const [display] = useState({
    ...default_display(theme_name),
    ...display_props,
  })
  const { data: option, loading } = useQuery(QUERY_GET_OPTION_SEARCH)
  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault()
    if (form) onSubmit(form)
  }

  const handleChangeSelect = (
    e: React.ChangeEvent<{}>,
    data: { value: string; id: string }[],
    name: string | undefined,
  ) => {
    if (name) {
      setForm({
        ...form,
        [name]: data.map((item) => item.id),
      })
    }
  }
  const handleChangeDatePicker = (name: string, date: any) => {
    setForm({
      ...form,
      [name]: date,
    })
  }
  return (
    <>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} md={size === 'normal' ? 12 : 6}>
          <Paper
            onSubmit={handleSubmit}
            component="form"
            className={classes.search_box}
            elevation={0}
          >
            <TextField
              className={classes.input}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const value = e.target.value
                setForm({
                  ...form,
                  // title: value,
                  // abstract: value,
                  // summary: value,
                  // description: value,
                  // tags: [value],
                  all: value,
                })
              }}
              placeholder="ค้นหา รหัส, หัวเรื่อง, รายละเอียดเนื้อหา, Tag"
            />
            <IconButton
              size="small"
              type="submit"
              className={classes.icon_button}
            >
              <Search />
            </IconButton>
            <Divider className={classes.divider} orientation="vertical" />
            <IconButton
              size="small"
              color="primary"
              className={classes.icon_button}
              onClick={onToggle}
            >
              <FilterList />
              {/* {open ?  : <ExpandMore />} */}
            </IconButton>
          </Paper>
        </Grid>
        <div className={classes.grow} />
        {/* <Grid item> */}
        {actionButton && (
          // <React.Fragment>
          <Grid item>{actionButton}</Grid>
          // </React.Fragment>
        )}
        {/* </Grid> */}
      </Grid>
      <Collapse style={{ marginBottom: '8px' }} in={open}>
        <Paper
          onSubmit={handleSubmit}
          elevation={0}
          className={classes.form_search}
        >
          {loading ? (
            <Loading />
          ) : (
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                md={6}
                lg={4}
                className={clsx(!display.status && classes.none)}
              >
                <AutoCompleteSearch
                  options={[
                    {
                      id: CONTENT.STATUS.DRAFT,
                      value: CONTENT.STATUS_LABEL.DRAFT,
                    },
                    {
                      id: CONTENT.STATUS.WAITING_TO_APPROVE,
                      value: CONTENT.STATUS_LABEL.WAITING_TO_APPROVE,
                    },
                    {
                      id: CONTENT.STATUS.APPROVED,
                      value: CONTENT.STATUS_LABEL.APPROVED,
                    },
                    {
                      id: CONTENT.STATUS.PUBLISH,
                      value: CONTENT.STATUS_LABEL.PUBLISH,
                    },
                  ]}
                  label="Status"
                  limitTags={3}
                  onChange={handleChangeSelect}
                  name="status"
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                lg={4}
                className={clsx(!display.author && classes.none)}
              >
                <AutoCompleteSearch
                  limitTags={2}
                  options={option.Authors}
                  label="Author"
                  onChange={handleChangeSelect}
                  name="author"
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                lg={4}
                className={clsx(!display.topic && classes.none)}
              >
                <AutoCompleteSearch
                  limitTags={3}
                  options={option.Topics}
                  label={wording['topic']}
                  onChange={handleChangeSelect}
                  name="topic"
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                lg={4}
                className={clsx(!display.categories && classes.none)}
              >
                <AutoCompleteSearch
                  limitTags={3}
                  options={option.Categories}
                  label={wording['category']}
                  onChange={handleChangeSelect}
                  name="categories"
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                lg={4}
                className={clsx(!display.start && classes.none)}
              >
                <KeyboardDatePicker
                  className={classes.date}
                  autoOk
                  fullWidth
                  variant="inline"
                  label="Start"
                  format="DD/MM/YYYY"
                  value={form?.start || null}
                  size="small"
                  InputAdornmentProps={{ position: 'end' }}
                  onChange={(date) => handleChangeDatePicker('start', date)}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                lg={4}
                className={clsx(!display.end && classes.none)}
              >
                <KeyboardDatePicker
                  className={classes.date}
                  autoOk
                  fullWidth
                  variant="inline"
                  label="End"
                  format="DD/MM/YYYY"
                  size="small"
                  value={form?.end || null}
                  InputAdornmentProps={{ position: 'end' }}
                  onChange={(date) => handleChangeDatePicker('end', date)}
                />
              </Grid>
            </Grid>
          )}
        </Paper>
      </Collapse>
    </>
  )
}
