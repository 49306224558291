import React, { Fragment, useContext, useState } from 'react'
//Autocomplete
import Autocomplete from '@material-ui/lab/Autocomplete'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import {
  Card,
  makeStyles,
  Grid,
  Box,
  Chip,
  Button,
  Typography,
  TextField,
  Checkbox,
  FormControl,
  Select,
  FormControlLabel,
  FormGroup,
  Switch,
} from '@material-ui/core'
import { ContentContext, ContentContextType } from '../context/ContentContext'
import { styles } from './styles'
import { useQuery } from '@apollo/client'
import SettingImage from '../Widget/settingImage'
import { CONTENT } from '@Libs/const'
import moment from 'moment'
import {
  QUERY_GET_ALL_AUTHORS_OPTION,
  QUERY_GET_AUTHOR_BY_ID,
  QUERY_GET_ALL_PHOTOGRAPHER_OPTION,
} from '@Api/author_api'
import { authorType } from '@Types/author_type'
import {
  QUERY_GET_ALL_FOLLOWS_OPTION,
  QUERY_GET_FOLLOW_BY_ID,
} from '@Api/follow_api'
import { followType } from '@Types/follow_type'
import Loading from '@Components/Loading'
import api from '@Api/api'
import SettingTopicCategory from '../Widget/settingTopicCategory'
import clsx from 'clsx'
import { useGlobal } from '@Hooks/useGlobal'
import SuggestionTagWidget from '../Widget/suggestionTagWidget'
import { DateTimePicker } from '@material-ui/pickers'

const useStyles = makeStyles(styles)
export default function Setting() {
  const classes = useStyles()
  const {
    content: [content],
    handleSelectChange,
    dialog: [, setDialog],
    handleChangeStatus,
    handleChange,
  } = useContext(ContentContext) as ContentContextType
  const { theme_name } = useGlobal()
  const [tagInput, setTagInput] = useState<String>('')
  const { data: option, loading: loadingOption } = useQuery(
    QUERY_GET_ALL_AUTHORS_OPTION,
    { variables: { categories: "ผู้เขียน" } }
  )
  const { data: optionPhoto, loading: loadingOptionPhoto } = useQuery(
    QUERY_GET_ALL_PHOTOGRAPHER_OPTION,
    { variables: { categories: "ช่างภาพ" } }
  )
  const { data: optionFollow, loading: loadingOptionFollow } = useQuery(
    QUERY_GET_ALL_FOLLOWS_OPTION,
  )

  const addTag = (e: React.SyntheticEvent): void => {
    e.preventDefault()
    const target = e.target as typeof e.target & {
      tag: { value: string }
    }
    const item = target.tag.value
    const checkDulpicat = content.tags.find((value) => value === item)
    if (!checkDulpicat) {
      if (item.trim() !== '') {
        const tagsNow = [...content.tags, item]
        handleSelectChange('tags', tagsNow)
        setTagInput('')
      }
    }
  }

  const deleteTag = (item: String) => {
    const tagsNow = content.tags.filter((tag) => tag !== item)
    handleSelectChange('tags', tagsNow)
  }

  const openDialog = () => {
    setDialog(true)
  }
  const handleChangeAutocomplete = async (
    event: React.ChangeEvent<{}>,
    value: { id: string; value: string } | null,
  ) => {
    if (!value) return
    const response = await api.graphql.query({
      query: QUERY_GET_AUTHOR_BY_ID,
      variables: {
        _id: value.id,
      },
    })
    const author: authorType = response?.data?.Author
    if (author) {
      handleSelectChange('author', {
        _id: author._id,
        first_name: author.first_name,
        last_name: author.last_name,
        nick_name: author.nick_name,
        profile_image: author.profile_image,
        description: author.description,
        slug_name: author.slug_name,
        categories: author.categories
      })
    }
  }
  const handleChangePhotographerAutocomplete = async (
    event: React.ChangeEvent<{}>,
    value: { id: string; value: string } | null,
  ) => {
    if (!value) return
    const response = await api.graphql.query({
      query: QUERY_GET_AUTHOR_BY_ID,
      variables: {
        _id: value.id,
      },
    })
    const photographer: authorType = response?.data?.Author
    if (photographer) {
      handleSelectChange('photographer', {
        _id: photographer._id,
        first_name: photographer.first_name,
        last_name: photographer.last_name,
        nick_name: photographer.nick_name,
        profile_image: photographer.profile_image,
        description: photographer.description,
        slug_name: photographer.slug_name,
        categories: photographer.categories
      })
    }
  }

  const handleChangeFollowAutocomplete = async (
    event: React.ChangeEvent<{}>,
    value: { id: string; value: string } | null,
  ) => {
    if (!value) {
      handleSelectChange('follow', {
        _id: null,
        title: null,
        description: null,
        cover: null,
      })
      return
    }
    const response = await api.graphql.query({
      query: QUERY_GET_FOLLOW_BY_ID,
      variables: {
        _id: value.id,
      },
    })

    const follow: followType = response?.data?.Follow
    if (follow) {
      handleSelectChange('follow', {
        _id: follow._id,
        title: follow.title,
        description: follow.description,
        cover: follow.cover,
      })
    } else {
      handleSelectChange('follow', {
        _id: null,
        title: null,
        description: null,
        cover: null,
      })
    }
  }

  function remove_width_attribute($html: string) {
    const regx_remove = new RegExp(
      '(width|height)="([a-zA-Z0-9:;.s()-,]*)(")',
      'gi',
    )
    $html = $html.replace(regx_remove, '')
    return $html
  }
  return (
    <>
      <div className='container-editor'>
        <div className='form-setting'>
          <Grid item>
            <Card className={classes.container}>
              <Box className={classes.box}>
                <Typography gutterBottom className='header-h3'>
                  การเผยแพร่
                </Typography>
                <Grid container spacing={1} className={classes.spacingBottom}>
                  <Grid item xs={3} sm={3} lg={2}>
                    <div className={classes.textLabel}>Status:</div>
                  </Grid>
                  <Grid item xs={9} sm={9} lg={10}>
                    <FormControl
                      variant="outlined"
                      size="small"
                      className={classes.select}
                    >
                      <Select
                        native
                        // value={content.status}
                        value={content.status}
                        onChange={(ev) => {
                          // handleSelectChange('status', ev.target.value)
                          const status = `${ev.target.value}`
                          if (!ev.target.value) return
                          if (status === 'unknown') return
                          handleChangeStatus(status)
                        }}
                      >
                        <option value={CONTENT.STATUS.DRAFT}>
                          {CONTENT.STATUS_LABEL.DRAFT}
                        </option>
                        <option value={CONTENT.STATUS.WAITING_TO_APPROVE}>
                          {CONTENT.STATUS_LABEL.WAITING_TO_APPROVE}
                        </option>
                        <option value={CONTENT.STATUS.APPROVED}>
                          {CONTENT.STATUS_LABEL.APPROVED}
                        </option>
                        <option value={CONTENT.STATUS.PUBLISH} disabled>
                          {CONTENT.STATUS_LABEL.PUBLISH}
                        </option>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid container spacing={1} className={classes.spacingBottom}>
                  <Grid item xs={3} sm={3} lg={2}>
                    <div className={classes.textLabel}>Publish: </div>
                  </Grid>
                  <Grid item xs={9} sm={9} lg={10}>
                    <TextField
                      fullWidth
                      id="standard-basic"
                      variant="outlined"
                      size="small"
                      value={
                        content.publish_time
                          ? moment(content.publish_time).format(
                            'DD/MM/YYYY HH:mm:ss',
                          )
                          : '-'
                      }
                      onClick={openDialog}
                      style={{ cursor: 'pointer' }}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={1} className={classes.spacingBottom}>
                  <Grid item xs={3} sm={3} lg={2}>
                    <div className={classes.textLabel}>Expire: </div>
                  </Grid>
                  <Grid item xs={9} sm={9} lg={10}>
                    <TextField
                      fullWidth
                      id="standard-basic"
                      variant="outlined"
                      size="small"
                      value={
                        content.expire_time
                          ? moment(content.expire_time).format(
                            'DD/MM/YYYY HH:mm:ss',
                          )
                          : '-'
                      }
                      // onClick={openDialog}
                      style={{ cursor: 'pointer' }}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={3} sm={3} lg={2}>
                    <div className={classes.textLabel}>Author: </div>
                  </Grid>
                  <Grid item xs={9} sm={9} lg={10}>
                    {loadingOption ? (
                      <Loading />
                    ) : (
                      <Autocomplete
                        fullWidth
                        onChange={handleChangeAutocomplete}
                        defaultValue={
                          content?.author?._id
                            ? {
                              id: content?.author?._id || '',
                              value: `${content?.author?.first_name} ${content?.author?.last_name}`,
                            }
                            : null
                        }
                        options={option.Authors}
                        getOptionSelected={(option, value) =>
                          option.id === value.id
                        }
                        getOptionLabel={(option: { id: string; value: string }) =>
                          option.value
                        }
                        classes={{
                          paper: classes.menu_item,
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            variant="outlined"
                          />
                        )}
                      />
                    )}
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={3} sm={3} lg={2}>
                    <div className={classes.textLabel}>Photographer: </div>
                  </Grid>
                  <Grid item xs={9} sm={9} lg={10}>
                    {loadingOptionPhoto ? (
                      <Loading />
                    ) : (
                      <Autocomplete
                        fullWidth
                        onChange={handleChangePhotographerAutocomplete}
                        defaultValue={
                          content?.photographer?._id
                            ? {
                              id: content?.photographer?._id || '',
                              value: `${content?.photographer?.first_name} ${content?.photographer?.last_name}`,
                            }
                            : null
                        }
                        options={optionPhoto.Photographers}
                        getOptionSelected={(option, value) =>
                          option.id === value.id
                        }
                        getOptionLabel={(option: { id: string; value: string }) =>
                          option.value
                        }
                        classes={{
                          paper: classes.menu_item,
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            variant="outlined"
                          />
                        )}
                      />
                    )}
                  </Grid>
                </Grid>
                <Grid container spacing={1} className={classes.spacingBottom}>
                  <Grid item xs={3} sm={3} lg={2}>
                    <div className={classes.textLabel}>Content Type:</div>
                  </Grid>
                  <Grid item xs={9} sm={9} lg={10}>
                    <FormControl
                      variant="outlined"
                      size="small"
                      className={classes.select}
                    >
                      <Select
                        native
                        value={content.content_type}
                        onChange={(ev) => {
                          if (ev.target.value === 'content') {
                            handleSelectChange(
                              [
                                'content_type',
                                'content_type_expire_time',
                                'premium_type',
                              ],
                              [
                                ev.target.value,
                                null,
                                CONTENT.PREMIUM_TYPE.MEMBERSHIP,
                              ],
                            )
                          } else {
                            handleSelectChange(
                              ['content_type', 'premium_type'],
                              [ev.target.value, CONTENT.PREMIUM_TYPE.FREE],
                            )
                          }
                        }}
                      >
                        <option value={"content"}>Content</option>
                        <option value={'advertorial'}>Advertorial</option>
                        <option value={'pr'}>PR</option>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                {content.content_type === 'pr' && <Grid container spacing={1} className={classes.spacingBottom}>
                  <Grid item xs={3} sm={3} lg={2}>
                    <div className={classes.textLabel}>PR-Expire: </div>
                  </Grid>
                  <Grid item xs={9} sm={9} lg={10}>
                    <DateTimePicker
                      // disabled={publishNow}
                      className={classes.date}
                      defaultValue={content.pr_expire_time}
                      fullWidth
                      // label="Expire Time"
                      format="DD/MM/YYYY HH:mm"
                      value={
                        content.pr_expire_time
                      }
                      size="small"
                      onError={console.log}
                      onChange={(value) => {
                        handleSelectChange(
                          'pr_expire_time',
                          value,
                        )
                      }}
                      inputVariant="outlined"
                    />
                  </Grid>
                </Grid>}              {/* <Grid container spacing={1}>
                    <Grid item xs={3} sm={3} lg={2}>
                      <div className={classes.textLabel}>Follow (Topic): </div>
                    </Grid>
                    <Grid item xs={9} sm={9} lg={10}>
                      {loadingOptionFollow ? (
                        <Loading />
                      ) : (
                        <Autocomplete
                          fullWidth
                          onChange={handleChangeFollowAutocomplete}
                          defaultValue={
                            content?.follow?._id
                              ? {
                                  id: content?.follow?._id || '',
                                  value: content?.follow?.title || '',
                                }
                              : null
                          }
                          options={optionFollow.Follows}
                          getOptionSelected={(optionFollow, value) =>
                            optionFollow.id === value.id
                          }
                          getOptionLabel={(optionFollow: {
                            id: string
                            value: string
                          }) => optionFollow.value}
                          classes={{
                            paper: classes.menu_item,
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              size="small"
                              variant="outlined"
                            />
                          )}
                        />
                      )}
                    </Grid>
                  </Grid> */}

                {theme_name === 'thairath-plus' && (
                  <Grid container spacing={1} className={classes.spacingBottom}>
                    <Grid item xs={3} sm={3} lg={2}>
                      <div className={classes.textLabel}>Premium Content:</div>
                    </Grid>
                    <Grid item xs={9} sm={9} lg={10}>
                      <FormControl
                        variant="outlined"
                        size="small"
                        className={classes.select}
                      >
                        <Select
                          native
                          value={
                            content.premium_type ||
                            CONTENT.PREMIUM_TYPE.MEMBERSHIP
                          }
                          onChange={(ev) => {
                            const premium_type = `${ev.target.value}`
                            if (!ev.target.value) return
                            if (premium_type === 'unknown') return
                            handleSelectChange('premium_type', ev.target.value)
                          }}
                        >
                          <option value={CONTENT.PREMIUM_TYPE.FREE}>
                            {CONTENT.PREMIUM_TYPE_LABEL.FREE}
                          </option>
                          <option value={CONTENT.PREMIUM_TYPE.MEMBERSHIP}>
                            {CONTENT.PREMIUM_TYPE_LABEL.MEMBERSHIP}
                          </option>
                          <option value={CONTENT.PREMIUM_TYPE.SUBSCRIPTION}>
                            {CONTENT.PREMIUM_TYPE_LABEL.SUBSCRIPTION}
                          </option>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                )}

                {theme_name === 'thairath-plus' && (
                  <>
                    <Grid container spacing={1} className={classes.spacingBottom}>
                      <Grid item xs={3} sm={3} lg={2}>
                        <div className={classes.textLabel}>Content Type:</div>
                      </Grid>
                      <Grid item xs={9} sm={9} lg={10}>
                        <FormControl
                          variant="outlined"
                          size="small"
                          className={classes.select}
                        >
                          <Select
                            native
                            value={content.content_type}
                            onChange={(ev) => {
                              if (ev.target.value === 'content') {
                                handleSelectChange(
                                  [
                                    'content_type',
                                    'content_type_expire_time',
                                    'premium_type',
                                  ],
                                  [
                                    ev.target.value,
                                    null,
                                    CONTENT.PREMIUM_TYPE.MEMBERSHIP,
                                  ],
                                )
                              } else {
                                handleSelectChange(
                                  ['content_type', 'premium_type'],
                                  [ev.target.value, CONTENT.PREMIUM_TYPE.FREE],
                                )
                              }
                            }}
                          >
                            <option value="content">Content</option>
                            <option value={'advertorial'}>Advertorial</option>
                            {/* <option value={'pr'}>PR</option> */}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>

                    {content.content_type !== 'content' && (
                      <Grid
                        container
                        spacing={1}
                        className={classes.spacingBottom}
                      >
                        <Grid item xs={3} sm={3} lg={2}>
                          <div className={classes.textLabel}>Expire Time:</div>
                        </Grid>

                        <Grid item xs={9} sm={9} lg={10}>
                          <DateTimePicker
                            // disabled={publishNow}
                            className={classes.date}
                            defaultValue={content?.content_type_expire_time}
                            fullWidth
                            // label="Expire Time"
                            format="DD/MM/YYYY HH:mm"
                            value={content?.content_type_expire_time}
                            size="small"
                            onError={console.log}
                            onChange={(value) => {
                              handleSelectChange(
                                'content_type_expire_time',
                                value,
                              )
                            }}
                            inputVariant="outlined"
                          />
                        </Grid>
                      </Grid>
                    )}
                  </>
                )}
              </Box>
            </Card>
          </Grid>
          <Grid item>
            <Card className={classes.container}>
              <Box className={classes.box}>
                <SettingImage />
              </Box>
            </Card>
          </Grid>

          <Grid item>
            <Card className={clsx(classes.container, classes.has_video_wrapper)}>
              <FormGroup row>
                <FormControlLabel
                  checked={content.has_video}
                  control={
                    <Checkbox
                      icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                      checkedIcon={<CheckBoxIcon fontSize="small" />}
                      name="has_video"
                    />
                  }
                  label="เพิ่มวิดีโอ (Story)"
                  onChange={(e: React.ChangeEvent<{}>, checked: boolean) => {
                    const target = e.target as HTMLInputElement
                    handleSelectChange(target.name, checked)
                  }}
                  className={classes.has_video}
                />
              </FormGroup>
              <br />
              {/* <Box className={classes.box}> */}
              {content.has_video && (
                <Grid className={classes.video_embed}>
                  <Grid item>
                    <TextField
                      variant="outlined"
                      multiline
                      //rows={6}
                      label="ID JWplayer"
                      className='input-text-lg'
                      value={content.video_embed}
                      onChange={(e) => {
                        handleChange(
                          'video_embed',
                          remove_width_attribute(e.target.value),
                        )
                      }}
                    />
                  </Grid>
                  {/* <Grid item>
                      {content.video_embed && (
                        <div className='video-rwd'>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: content?.video_embed,
                          }}
                          className={classes.video_embed}
                          style={{aspectRatio: "16 / 9"}}
                        ></div>
                        </div>
                      )}
                    </Grid> */}
                </Grid>
              )}
              {/* </Box> */}
            </Card>
          </Grid>
          <Grid item>
            <Card className={classes.container}>
              <Box className="box-filed-topic">
                <SettingTopicCategory />
              </Box>
            </Card>
          </Grid>

          <Grid item>
            {/* Tag */}
            <Card className={classes.container}>
              <Box className={classes.box}>
                <Typography
                  color="textSecondary"
                  //className={classes.margin_left}
                  gutterBottom
                  variant="body2"
                >
                  Tags
                </Typography>

                {/* <Grid container className={classes.margin_left}>
                    <Grid item>
                      <Typography gutterBottom variant="body2">
                        Add a Tag:
                      </Typography>
                    </Grid>
                  </Grid> */}
                <form onSubmit={addTag} style={{ width: '100%' }}>
                  <Grid container>
                    <Grid item>
                      <TextField
                        id="content-tag"
                        name="tag"
                        style={{ margin: 0, width: '300px' }}
                        placeholder="Start typing a tag to include"
                        margin="normal"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={tagInput}
                        onChange={(ev) => {
                          setTagInput(ev.target.value)
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <Button
                        type="submit"
                        variant="contained"
                        size="medium"
                        color="primary">
                        Add
                      </Button>
                    </Grid>
                  </Grid>
                </form>
                <Typography
                  //className={classes.margin_left}
                  gutterBottom
                  variant="body2"
                >
                  Current a tags:
                </Typography>
                <Grid container spacing={2}>
                  {content.tags.map((item, index) => (
                    <Fragment key={`${item}_${index}`}>
                      <Chip
                        style={{ backgroundColor: '#63b1bb' }}
                        color="primary"
                        label={item}
                        onDelete={() => {
                          deleteTag(item)
                        }}
                        className={classes.tag}
                      />
                    </Fragment>
                  ))}
                </Grid>
                <SuggestionTagWidget />
              </Box>
            </Card>
          </Grid>

        </div>
      </div>
    </>
  )
}
