export const mirrorProductCategories = [
  {
    value: 'makeup',
    label: 'make up',
  },
  {
    value: 'skincare',
    label: 'skin care',
  },
  {
    value: 'hair',
    label: 'hair',
  },
  {
    label: 'body',
    value: 'body',
  },
]

export const mirrorProductStatusColor: { [key: string]: string } = {
  '10': 'green',
  '20': 'orange',
  '30': 'red',
}

export const mirrorProductStatus: { [key: string]: string } = {
  '10': 'แสดงผล',
  '20': 'รอแก้ไข',
  '30': 'ไม่แสดงผล',
}
