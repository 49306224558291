import { useState } from 'react'
import { Paper, Snackbar } from '@material-ui/core'
import { useMutation, useQuery } from '@apollo/client'
import { Avatar, makeStyles } from '@material-ui/core'
import {
  MUTATION_EDIT_USER_PROFILE,
  QUERY_GET_USER_PROFILE,
} from '@Api/user_api'
import Loading from '@Components/Loading'
// import user_api from '@Api/user_api'
import Form from '@Components/User/Form'
import { userType } from '@Types/user_type'
import { styles } from '@Components/User/form.style'
import useUpload from '@Hooks/useUpload'
import { useAuth } from '@Hooks/useAuth'
import { Alert } from '@material-ui/lab'
const useStyles = makeStyles(styles)

export default function ProfileUser() {
  const classes = useStyles()
  const { user } = useAuth()
  const [imageProfile, setImageProfile] = useState<FileList>()
  const { onUpload } = useUpload()
  const [editUser] = useMutation(MUTATION_EDIT_USER_PROFILE)
  const [openAlert, setOpenAlert] = useState({
    is_success: false,
    open: false,
  })
  const { loading, data, error } = useQuery(QUERY_GET_USER_PROFILE, {
    variables: {
      _id: user._id,
    },
    fetchPolicy: 'network-only',
  })
  const handleSubmit = async (obj: userType) => {
    let current_img = data.profile_image
    if (imageProfile) {
      const res_image = await onUpload(imageProfile, 'image_files', {
        ref_id: user._id,
        type: 'replace',
      })
      current_img = res_image.data.data?.items[0]?.url
    }

    const res = await editUser({
      variables: {
        ...obj,
        profile_image: current_img,
        _id: user._id,
      },
    })
    if (res.data.EditUserProfile.statusCode === 200) {
      setOpenAlert({ is_success: true, open: true })
      // history.push('/user')
    } else {
      setOpenAlert({ is_success: false, open: true })
    }
  }
  const handleChangeFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const imgs = event.target.files
    if (imgs) setImageProfile(imgs)
  }
  const handleCloseAlert = () => {
    setOpenAlert({ ...openAlert, open: false })
  }
  if (loading) return <Loading />
  if (error) {
    console.log('LOG: error ---> ', error)
    return <p>Error :(</p>
  }
  return (
    <Paper>
      {!loading && (
        <Form
          bypass={true}
          defaultData={data?.UserProfile}
          avatar={() => {
            return (
              <>
                <Avatar
                  className={classes.avatar}
                  src={
                    imageProfile
                      ? URL.createObjectURL(imageProfile[0])
                      : data?.UserProfile?.profile_image + '?width=160'
                  }
                />
                <input
                  type="file"
                  onChange={handleChangeFile}
                  className={classes.upload_profile}
                />
              </>
            )
          }}
          onSubmit={handleSubmit}
          title="แก้ไขข้อมูลส่วนตัว"
          display={{
            is_active: false,
            password: false,
            roles: false,
            username: false,
          }}
        />
      )}
      <Snackbar
        open={openAlert.open}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        {openAlert.is_success ? (
          <Alert onClose={handleCloseAlert} severity="success">
            บันทึกข้อมูลสำเร็จ!
          </Alert>
        ) : (
          <Alert onClose={handleCloseAlert} severity="error">
            ไม่สามารถบันทึกข้อมูลได้!
          </Alert>
        )}
      </Snackbar>
    </Paper>
  )
}
