import React, { createContext, useContext, useState } from 'react'
import { QUERY_METATAG } from '@Api/metatag_api'
import { useQuery } from '@apollo/client'
import { 
  //useHistory, 
  useParams 
} from 'react-router-dom'
export const SeoContext = createContext({})

export default function useSeo() {
  return useContext(SeoContext)
}

export const SeoContextProvider: React.FC<{ children?: React.ReactNode }> = (props) => {
  const [data, setData] = useState<{
    path: string
    title: string
    description: string
    cover_url: string
  }>()
  const { id } = useParams<{ id: string }>()
  useQuery(QUERY_METATAG, {
    variables: {
      _id: id,
    },
    onCompleted: ({ MetatagCMS }) => {
      console.log('MetatagCMS', MetatagCMS)
      setData(MetatagCMS)
    },
    fetchPolicy: 'no-cache'
  })

  const store = {
    data,
    setData
  }

  return (
    <SeoContext.Provider value={store}>{props.children}</SeoContext.Provider>
  )
}
