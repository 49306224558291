import { Fragment, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
//import ButtonLink from '@Components/ButtonLink'
import { QUERY_ALL_METATAGS } from '@Api/metatag_api'
import { useQuery } from '@apollo/client'
//import { Add } from '@material-ui/icons'
import {
  Paper,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Link,
  Grid,
  Typography,
} from '@material-ui/core'
import { useAuth } from '@Hooks/useAuth'
const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
    width: '100%',
    // minHeight: 650,
  },
  grow: {
    flexGrow: 1,
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    minHeight: 650,
    paddingTop: theme.spacing(2),
  },
  pagination: {
    paddingBlock: theme.spacing(1),
    paddingInline: theme.spacing(1),
  },
  box_none: {
    boxShadow: 'none',
  },
  button_add: {
    marginRight: theme.spacing(1.5),
  },
  title: {
    marginLeft: theme.spacing(1.5),
  },
}))
const MetaTag = () => {
  const classes = useStyles()
  const history = useHistory()
  const { checkPermission } = useAuth()
  const { data } = useQuery(QUERY_ALL_METATAGS, {
    // fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  })
  useEffect(() => {
    if (!checkPermission('seo_tag_pages_view')) {
      window.location.href = '/'
    }
    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <Fragment>
      <Paper className={classes.paper}>
          <div className='headline'>
            <Typography variant="h6" className="header-h3">
                Meta Tag
            </Typography>
          </div>

          {/* <Grid item>
            {checkPermission('seo_tag_pages_edit') && (
              <ButtonLink
                className={classes.button_add}
                to="/page-setting/create"
                startIcon={<Add />}
                style={{ order: -1 }}
                variant="contained"
                color="primary"
              >
                เพิ่ม Meta Tag
              </ButtonLink>
            )}
          </Grid> */}
       
        <TableContainer>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell align="left">#</TableCell>
                <TableCell align="left">หน้า</TableCell>
                <TableCell align="left">Title</TableCell>
                <TableCell align="left">Description</TableCell>
                {/* <TableCell align="left">ผู้แก้ไขล่าสุด</TableCell> */}
                <TableCell align="left">เวลาแก้ไขล่าสุด</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data &&
                data.MetatagsCMS?.map((item: any, index: number) => (
                  <Fragment>
                    <TableRow hover>
                      <TableCell component="td" scope="row">
                        {index + 1}
                      </TableCell>
                      <TableCell component="td" scope="row">
                        <Link
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            history.push(`/page-setting/edit/${item._id}`)
                          }}
                        >
                          {item.path}
                        </Link>
                      </TableCell>
                      <TableCell component="td" scope="row">
                        {item.title}
                      </TableCell>
                      <TableCell component="td" scope="row">
                        {item.description}
                      </TableCell>
                      <TableCell component="td" scope="row">
                        {item.update_time}
                      </TableCell>
                    </TableRow>
                  </Fragment>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Fragment>
  )
}

export default MetaTag
