import { createTheme } from '@material-ui/core'
import { CustomTheme } from './index'

const default_theme = createTheme()

const theme_mirror: CustomTheme = {
  ...default_theme,
  custom: {
    app_color: '#9c9c9c',
    app_color_light: '#FFF',
    logo_size: {
      width: '100px',
      height: '100px',
    },
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'none',
      },
      containedPrimary: {
        backgroundColor: '#252525',
        '&:hover': {
          backgroundColor: '#000000',
        },
      },
    },
    MuiInput: {
      underline: {
        '&:hover:not($disabled):after': {
          borderBottomColor: '#000000',
        },
        '&:hover:not($disabled):before': {
          borderBottom: '1px solid #000000',
        },
        '&::after': {
          borderBottomColor: '#000000',
        },
        '&::before': {
          borderBottom: '1px solid #000000',
        },
      },
      inputMultiline:{
        paddingTop: '4px'
      }
    },
  },
  // props: {
  //   MuiButton: {
  //     disableElevation: true,
  //   },
  // },
}

export default theme_mirror
