import React, { Fragment, FunctionComponent } from 'react'
import { makeStyles, Typography } from '@material-ui/core'
import { CustomTheme } from '../../theme'
import clsx from 'clsx'

const useStyles = makeStyles((theme: CustomTheme) => ({
  drop_container: {
    backgroundColor: '#fff',
    cursor: 'pointer',
    display: 'flex',
    minHeight: '160px',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0',
    border: '2px dashed #ddd',
  },
  input_image: {
    display: 'none',
  },
  description: {
    fontSize: 14,
    textAlign: 'center',
  },
  description_wrapper: {
    padding: theme.spacing(0.5),
  },
}))

export interface DropAreaProps extends React.HTMLAttributes<HTMLLabelElement> {
  description?: string | React.ReactNode
  multi?: boolean
  accept?: string
  onChangeDropArea?: (files: FileList) => void
  renderImgComponent?: (src: string) => React.ReactNode
  src?: string
}

/**
 * @property {string | React.ReactNode} description  description
 * @property {boolean} multi multiple files
 * @property {string} accept accept allow extention files
 * @property {string} src  src img
 * @function renderImgComponent (src:string) => React.Component , for custom component require props src
 * @function onChangeDropArea return files as type FileList
 */
const DropArea: FunctionComponent<DropAreaProps> = (props: DropAreaProps) => {
  const {
    description = 'drop area',
    multi = false,
    accept = 'image/*',
    renderImgComponent,
    src,
    ...other
  } = props

  const classes = useStyles()

  const onSelectBrowse = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.currentTarget.files
    if (!files) return
    if (!other.onChangeDropArea) return
    other.onChangeDropArea(files)
  }

  const overrideEventDefaults = (
    event: Event | React.DragEvent<HTMLLabelElement>,
  ) => {
    event.preventDefault()
  }

  const onDrop = async (event: React.DragEvent<HTMLLabelElement>) => {
    event.preventDefault()
    const files = event.dataTransfer.files
    if (!other.onChangeDropArea) return
    other.onChangeDropArea(files)
  }

  const renderDescription = () => {
    if (src && !renderImgComponent) {
      return <img src={src} alt="Drop area" width="100%" />
    } else if (renderImgComponent) {
      if (!src) throw new Error('Please set src props for set default')
      return renderImgComponent(src)
    } else if (typeof description === 'string') {
      return (
        <Typography
          className={classes.description}
          color="textSecondary"
          gutterBottom
        >
          {description}
        </Typography>
      )
    } else {
      return description
    }
  }

  return (
    <Fragment>
      <label
        className={clsx(classes.drop_container, other.className)}
        onDragOver={overrideEventDefaults}
        onDragEnter={overrideEventDefaults}
        onDragLeave={overrideEventDefaults}
        onDrop={onDrop}
        {...other}
      >
        <input
          className={classes.input_image}
          accept={accept}
          type="file"
          key={Math.random()}
          name="my_files"
          onChange={onSelectBrowse}
          multiple={multi}
        />
        <div className={classes.description_wrapper}>{renderDescription()}</div>
      </label>
    </Fragment>
  )
}

export default DropArea
