import React from 'react'
import { useGlobal } from '@Hooks/useGlobal'
import SettingThairathPlus from './thairath_plus'
import SettingMirror from './mirror'

function SettingImage() {
  const { theme_name } = useGlobal()
  switch (theme_name) {
    case 'thairath-plus':
      return <SettingThairathPlus />
    case 'mirror':
      return <SettingMirror />
    default:
      return <>No theme</>
  }
}

export default SettingImage
