import { Fragment, useContext } from 'react'
import { useQuery } from '@apollo/client'
import {
  Typography,
  List,
  ListItemAvatar,
  ListItem,
  ListItemText,
  Avatar,
} from '@material-ui/core'
import ImageIcon from '@material-ui/icons/Image'
import { ContentContext, ContentContextType } from '../context/ContentContext'
import { QUERY_GET_CONTENT_HISTORY } from '@Api/content_api'
import { IContent } from '@Types/content_type'
import { CONTENT } from '@Libs/const'

export default function History() {
  const {
    content: [content],
  } = useContext(ContentContext) as ContentContextType
  const { data } = useQuery(QUERY_GET_CONTENT_HISTORY, {
    variables: {
      id: content.id,
    },
    notifyOnNetworkStatusChange: true,
  })

  return (
    <Fragment>
      <div className='container-editor box-shadow container-history'>
        <Typography variant="body1"><strong>ประวัติการเผยแพร่</strong></Typography>

        {data?.ContentHistory &&
          data.ContentHistory.map((item: IContent) => (
            <List>
              <ListItem>
                {item?.created_by?.profile_image ? (
                  <ListItemAvatar>
                    <Avatar
                      alt={item?.created_by.first_name}
                      src={item?.created_by?.profile_image}
                    />
                  </ListItemAvatar>
                ) : (
                  <ListItemAvatar>
                    <Avatar>
                      <ImageIcon />
                    </Avatar>
                  </ListItemAvatar>
                )}

                <ListItemText
                  primary={`${item.created_by?.first_name} ${item.created_by?.last_name}`}
                  secondary={CONTENT.STATUS_MAPPING[item.status] + " " + item.updated_time}
                />
              </ListItem>
            </List>
          ))}
        </div>
    </Fragment>
  )
}
