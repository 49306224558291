import React, { useContext, useState } from 'react'
import {
  makeStyles,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
} from '@material-ui/core'
import { styles } from '../../Detail/styles'
import { UploadImage } from '@Components/Upload'
import PermMediaIcon from '@material-ui/icons/PermMedia'
import {
  VideoContentContext,
  VideoContentContextType,
} from '../../context/VideoContext'
import { get, isEmpty } from 'lodash'
import { Close } from '@material-ui/icons'
const useStyles = makeStyles(styles)
type imageType = 'thumbnail' | 'share' | 'cover'
export default function SettingImage(): React.ReactElement {
  const classes = useStyles()
  const {
    content: [content],
    // gallery,
    handleChange,
    // fetchImageGallery,
  } = useContext(VideoContentContext) as VideoContentContextType
  console.log(content,"หน้า Image")
  const [openNow, setOpenNow] = useState<imageType>('thumbnail')
  const [defaultImage, setDefaultImage] = useState<{
    thumbnail: string[]
    share: string
    cover: string
  }>({
    thumbnail: get(content, 'widgets[0].data[0].thumbnails'),
    share: content.share,
    cover: content.cover,
  })

  console.log(defaultImage,"------image-------")
  const [open, setOpen] = React.useState(false)

  const handleOpen = async (type: imageType) => {
    setOpenNow(type)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const onSelectImage = async (img: string) => {
    // await updateData(openNow, img)
    if (openNow === 'thumbnail') {
      const temp = isEmpty(img) ? [] : [img]
      setDefaultImage({ ...defaultImage, [openNow]: temp })
      handleChange('widgets[0].data[0].thumbnails', temp)
    } else {
      const temp = isEmpty(img) ? '' : img
      setDefaultImage({ ...defaultImage, [openNow]: temp })
      handleChange(openNow, temp)
    }
    handleClose()
  }
  const handleUpload = (type: imageType, images: string | string[]) => {
    if (type === 'thumbnail') {
      if (!Array.isArray(images))
        throw new Error('images thumbnail is not array')
      const temp = isEmpty(images) ? [] : images
      setDefaultImage({ ...defaultImage, [type]: temp })

      const newArr = [...get(content, 'images'), ...temp]
      handleChange(['widgets[0].data[0].thumbnails', 'images'], [temp, newArr])
    } else {
      let image: string = ''
      if (Array.isArray(images)) {
        image = images[0]
      }
      const temp = isEmpty(image) ? '' : image
      setDefaultImage({ ...defaultImage, [type]: temp })
      if (isEmpty(temp)) {
        handleChange([type], [temp])
      } else {
        const newArr = [...get(content, 'images'), temp]
        handleChange([type, 'images'], [temp, newArr])
      }
    }
  }

  return (
    <>
      {content.id && (
        <Grid spacing={3} container>
          <Grid xs={12} sm={12} md={12} lg={4} item>
            <div
              style={{
                position: 'relative',
              }}
            >
              <UploadImage
                title={
                  <>
                    {isEmpty(defaultImage.thumbnail) ? (
                      <div className={classes.title_custom}>
                        <strong>รูป thumbnail</strong>
                        <br />
                        click here to select from images <br />
                        or
                        <br /> drop image here
                      </div>
                    ) : (
                      <>
                        <img
                          alt="img_thumbnail"
                          className={classes.img_exist}
                          src={defaultImage.thumbnail[0]}
                        />
                      </>
                    )}
                  </>
                }
                onUploaded={(images) => handleUpload('thumbnail', images)}
                uploadParams={{
                  ref_id: `video_${content.id}`,
                }}
              />
              {!isEmpty(defaultImage.thumbnail) && (
                <IconButton
                  style={{
                    position: 'absolute',
                    right: -14,
                    top: -14,
                  }}
                  size="small"
                  onClick={() => handleUpload('thumbnail', [])}
                >
                  <Close color="error" />
                </IconButton>
              )}
            </div>
            <Button
              classes={{
                text: classes.button_select,
              }}
              startIcon={<PermMediaIcon />}
              fullWidth
              onClick={() => handleOpen('thumbnail')}
            >
              เลือกรูป Thumbnail จากคลังรูปภาพ
            </Button>
            <Typography variant="caption" align="center" component="p">
              <div>
                <b>Dimension: </b>600 x 600
              </div>
              <div>
                <b>Size Limit: </b>2 MB
              </div>
              <div>
                <b>File: </b>jpg, png
              </div>
            </Typography>
          </Grid>
          <Grid xs={12} sm={12} md={12} lg={4} item>
            <div
              style={{
                position: 'relative',
              }}
            >
              <UploadImage
                title={
                  <>
                    {isEmpty(defaultImage.share) ? (
                      <div className={classes.title_custom}>
                        <strong>รูป share</strong>
                        <br />
                        click here to select from images <br />
                        or
                        <br /> drop image here
                      </div>
                    ) : (
                      <img
                        alt="img_share"
                        className={classes.img_exist}
                        src={defaultImage.share}
                      />
                    )}
                  </>
                }
                onUploaded={(images) => handleUpload('share', images)}
                uploadParams={{
                  ref_id: `video_${content.id}`,
                }}
              />
              {!isEmpty(defaultImage.share) && (
                <IconButton
                  style={{
                    position: 'absolute',
                    right: -14,
                    top: -14,
                  }}
                  size="small"
                  onClick={() => handleUpload('share', '')}
                >
                  <Close color="error" />
                </IconButton>
              )}
            </div>

            <Button
              classes={{
                text: classes.button_select,
              }}
              startIcon={<PermMediaIcon />}
              fullWidth
              onClick={() => handleOpen('share')}
            >
              เลือกรูป Share จากคลังรูปภาพ
            </Button>
            <Typography variant="caption" align="center" component="p">
              <div>
                <b>Dimension: </b>600 x 600
              </div>
              <div>
                <b>Size Limit: </b>2 MB
              </div>
              <div>
                <b>File: </b>jpg, png
              </div>
            </Typography>
          </Grid>
          <Grid xs={12} sm={12} md={12} lg={4} item>
            <div
              style={{
                position: 'relative',
              }}
            >
              <UploadImage
                title={
                  <>
                    {isEmpty(defaultImage.cover) ? (
                      <div className={classes.title_custom}>
                        <strong>รูปปก</strong>
                        <br />
                        click here to select from images <br />
                        or
                        <br /> drop image here
                      </div>
                    ) : (
                      <img
                        alt="img_cover"
                        className={classes.img_exist}
                        src={defaultImage.cover}
                      />
                    )}
                  </>
                }
                onUploaded={(images) => handleUpload('cover', images)}
                uploadParams={{
                  ref_id: `video_${content.id}`,
                }}
              />
              {!isEmpty(defaultImage.cover) && (
                <IconButton
                  style={{
                    position: 'absolute',
                    right: -14,
                    top: -14,
                  }}
                  size="small"
                  onClick={() => handleUpload('cover', '')}
                >
                  <Close color="error" />
                </IconButton>
              )}
            </div>

            <Button
              classes={{
                text: classes.button_select,
              }}
              startIcon={<PermMediaIcon />}
              fullWidth
              onClick={() => handleOpen('cover')}
            >
              เลือกรูปปก จากคลังรูปภาพ
            </Button>
            <Typography variant="caption" align="center" component="p">
              <div>
                <b>Dimension: </b>1280 x 720
              </div>
              <div>
                <b>Size Limit: </b>2 MB
              </div>
              <div>
                <b>File: </b>jpg, png
              </div>
            </Typography>
          </Grid>
        </Grid>
      )}
      {
        <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
          <DialogTitle>คลังรูปภาพ</DialogTitle>
          <DialogContent>
            <Grid container className={classes.image_list} spacing={1}>
              <>
                {content.images &&
                  content.images.map((item: string, index: number) => (
                    <Grid key={`${item}_${index}`} item>
                      <div className={classes.image_wrapper}>
                        <img
                          src={item}
                          className={classes.image}
                          alt={item}
                          key={item}
                          onClick={() => onSelectImage(item)}
                        />
                      </div>
                    </Grid>
                  ))}
              </>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              classes={{
                text: classes.button_select,
              }}
              color="primary"
            >
              ปิด
            </Button>
          </DialogActions>
        </Dialog>
      }
    </>
  )
}
