import React, { Fragment, useState } from 'react'
import { Tabs, Tab } from '@material-ui/core'
import {
  createStyles,
  makeStyles,
  Theme,
  withStyles,
} from '@material-ui/core/styles'
import Detail from './detail'
import { useQuery } from '@apollo/client'
import { QUERY_GET_ALL_TOPICS } from '@Api/topic_api'
import { ITopic } from '@Types/topic_type'
import { v4 as uuidv4 } from 'uuid';
import Loading from '@Components/Loading'

interface StyledTabProps {
  label?: string
  disabled?: boolean
  chlidren?: React.ReactNode
}
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  demo1: {
    backgroundColor: theme.palette.background.paper,
  },
  padding: {
    padding: theme.spacing(3),
  },
  grow: {
    flexGrow: 1,
  },
  header_container: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  status_wrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  divider: {
    marginInline: theme.spacing(1),
  },
  saveBtn: {
    background: theme.palette.success.main,
    color: '#fff',
    '&:hover': {
      background: '#439c46',
    },
    margin: theme.spacing(1),
  },
}))

const topicOthers: ITopic[] = [
  {
    _id: uuidv4(),
    value: 'home',
    label: 'Home',
  },
  {
    _id: uuidv4(),
    value: 'search',
    label: 'Search',
  },
  {
    _id: uuidv4(),
    value: 'tag',
    label: 'Tag',
  },
]

const MirrorProductManage = () => {
  const classes = useStyles()
  const [tabNow, setTabNow] = useState(0)

  const [topics, setTopics] = useState<ITopic[]>([])
  const { loading } = useQuery(QUERY_GET_ALL_TOPICS, {
    onCompleted: ({ Topics }) => {
      if (Topics)
        setTopics(
          [...topicOthers, ...Topics].filter(
            (item: ITopic) => (item.value !== 'article' && ['Home', 'Search', 'Tag'].includes(item.label)),
          ),
        )
      else setTopics(topicOthers)
    },
  })
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabNow(newValue)
  }
  if (loading) return <Loading />
  return (
    <div>
      <Fragment>
        <div className={classes.root}>
          <div className={classes.demo1}>
            <AntTabs onChange={handleChange} value={tabNow}>
              {topics &&
                topics.map((topic: ITopic) => (
                  <AntTab key={topic._id} label={topic.label} />
                ))}
            </AntTabs>
            <div className={classes.padding}>
              {topics[tabNow] && <Detail topic={topics[tabNow]} />}
            </div>
          </div>
        </div>
      </Fragment>
    </div>
  )
}

const AntTabs = withStyles({
  root: {
    borderBottom: '1px solid #e8e8e8',
    position: 'relative',
  },
  indicator: {
    backgroundColor: '#000000',
  },
})(Tabs)

const AntTab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      textTransform: 'none',
      minWidth: 72,
      marginRight: theme.spacing(3),
      '&:hover': {
        color: '#000',
        opacity: 1,
      },
      '&$selected': {
        color: '#000000',
        fontWeight: theme.typography.fontWeightMedium,
      },
      '&:focus': {
        color: '#000',
      },
    },
    selected: {},
  }),
)((props: StyledTabProps) => (
  <Tab disableRipple {...props}>
    {props.chlidren}
  </Tab>
))

export default MirrorProductManage
