import React, { useState } from 'react'
import ListItem, { ListItemProps } from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { useHistory } from 'react-router-dom'
import { Collapse } from '@material-ui/core'
import { ExpandLess, ExpandMore } from '@material-ui/icons'
import isEmpty from 'lodash.isempty'

interface ListItemLinkProps extends ListItemProps {
  to?: string
  label: string
  icon: React.ReactNode
  open?: boolean
  parent?: string
}
const ListItemLink = (props: ListItemLinkProps): React.ReactElement => {
  const history = useHistory()
  const [open, setOpen] = useState<boolean>(!!props.open)
  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (props.to) {
      props.onClick && props.onClick(event as any)
      history.push(props.to)
    } else {
      setOpen(!open)
    }
  }
  return (
    <>
      <ListItem button onClick={handleClick} className={props.className}>
        {props.icon && <ListItemIcon color="inherit" >{props.icon}</ListItemIcon>}
        <ListItemText
          primaryTypographyProps={{
            variant: 'body2',
          }}
          primary={props.label}
        />
        {!isEmpty(props.children) && (open ? <ExpandLess /> : <ExpandMore />)}
      </ListItem>
      {!isEmpty(props.children) && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          {props.children}
        </Collapse>
      )}
    </>
  )
}
export default ListItemLink
