import React, { Fragment, useState, useEffect } from 'react'
import { useAuth } from '@Hooks/useAuth'
import { SeoContextProvider } from './context/SeoContext'
import EditSeo from './Seo/edit'
import TopicImage from './TopicImage/index'
import { Tabs, Tab } from '@material-ui/core'

import {
  createStyles,
  makeStyles,
  Theme,
  withStyles,
} from '@material-ui/core/styles'
interface StyledTabProps {
  label?: string
  disabled?: boolean
  chlidren?: React.ReactNode
}
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  demo1: {
    backgroundColor: theme.palette.background.paper,
  },
}))
const EditTab = () => {
  const classes = useStyles()
  const { checkPermission } = useAuth()
  const [tabNow, setTabNow] = useState(0)

  useEffect(() => {
    if (!checkPermission('seo_tag_pages_edit')) window.location.href = '/'
    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabNow(newValue)
  }

  return (
    <Fragment>
      <div className={classes.root}>
        <div className={classes.demo1}>
          <AntTabs
            value={tabNow}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChange}
            aria-label="disabled tabs example"
          >
            <AntTab label="Seo" />
            <AntTab label="Topic Image" />
          </AntTabs>
          <SeoContextProvider>
            {tabNow === 0 && <EditSeo></EditSeo>}
            {tabNow === 1 && <TopicImage></TopicImage>}
          </SeoContextProvider>
        </div>
      </div>
    </Fragment>
  )
}

const AntTabs = withStyles({
  root: {
    borderBottom: '1px solid #e8e8e8',
    position: 'relative',
  },
  indicator: {
    backgroundColor: '#000000',
  },
})(Tabs)

const AntTab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      textTransform: 'none',
      minWidth: 72,
      marginRight: theme.spacing(3),
      '&:hover': {
        color: '#000',
        opacity: 1,
      },
      '&$selected': {
        color: '#000000',
        fontWeight: theme.typography.fontWeightMedium,
      },
      '&:focus': {
        color: '#000',
      },
    },
    selected: {},
  }),
)((props: StyledTabProps) => (
  <Tab disableRipple {...props}>
    {props.chlidren}
  </Tab>
))

export default EditTab
