import { Fragment, useContext } from 'react'
import {
  Typography,
  FormControlLabel,
  Button,
  Switch,
  Tooltip,
} from '@material-ui/core'
import { ContentContext, ContentContextType } from '../context/ContentContext'

import { useMutation } from '@apollo/client'
import { MUTATION_GEN_USER_TOKEN_STORYTELLING } from '@Api/user_api'
import { AuthContextType, useAuth, getTokenUser } from '@Hooks/useAuth'

export default function Storytelling() {
  const {
    handleChange,
    content: [content],
  } = useContext(ContentContext) as ContentContextType
  const { user } = useAuth() as AuthContextType
  const user_token = getTokenUser()
  const [genUserTokenStorytelling] = useMutation(
    MUTATION_GEN_USER_TOKEN_STORYTELLING,
  )

  const generateUrl = () => {
    const base_url = process.env.REACT_APP_TR_PLUS
    const url = `${base_url}/article/${content?.id}`
    return url
  }

  const editStoryTelling = async () => {
    const url = `${generateUrl()}?token=${content?.token}`
    const response = await genUserTokenStorytelling({
      variables: {
        _id: user._id,
        user_id: user._id,
        username: user.first_name ? user.first_name : user.nick_name,
        user_token: user_token,
        content_id: content.id,
        preview_url: url,
      },
    })
    const token = response.data?.GenUserTokenStorytelling?.token

    if (response.data?.GenUserTokenStorytelling?.statusCode === 200 && token) {
      window.open(
        `${process.env.REACT_APP_STORYTELLING_URL}/layout/mirror/${content?.id}?token=${token}`,
        '_blank',
      )
    }
  }

  return (
    <Fragment>
      <div className="container-editor box-shadow container-history">
        <Typography variant="body1">
          <strong>Storytelling</strong>
        </Typography>
        <div className="storytelling-editor" style={{ marginTop: '1rem' }}>
          <FormControlLabel
            control={
              <Switch
                checked={content?.is_storytelling || false}
                onChange={(ev) =>
                  handleChange('is_storytelling', ev.target.checked)
                }
                color="primary"
                name="is_storytelling"
              />
            }
            label={`${content?.is_storytelling ? 'on' : 'off'}`}
          />
          <Tooltip
            title={
              content.id
                ? 'จัดการเนื้อหาด้วย storytelling'
                : 'กรุณาตั้งค่าเนื้อหาก่อน'
            }
          >
            <div style={{ display: 'inline-block' }}>
              <Button
                onClick={() => editStoryTelling()}
                variant="outlined"
                disabled={content.id ? false : true}
              >
                Edit Storytelling
              </Button>
            </div>
          </Tooltip>
        </div>
      </div>
    </Fragment>
  )
}
