import { Fragment, useContext, useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { QUERY_GET_QUOTE_BY_ID } from '@Api/quote_api'
import Main from './main'
import Loading from '@Components/Loading'
import SettingEditor from '../Widget/editor2'
import { Grid } from '@material-ui/core'
import { IQuote } from '@Types/quote_type'
import { QuoteContext, QuoteContextType } from '../context/QuoteContext'
import isEmpty from 'lodash.isempty'

interface ParamType {
  id: string
}

export default function Detail() {
  const {
    quote: [, setQuote],
    onInitQuote,
    publishNow: [, setPublishNow],
  } = useContext(QuoteContext) as QuoteContextType

  const { id } = useParams<ParamType>()
  const [fetch, { loading, error }] = useLazyQuery(QUERY_GET_QUOTE_BY_ID, {
    variables: {
      _id: Object(id),
    },
    onCompleted: async (data) => {
      if (data?.Quote) {
        setQuote(data.Quote as IQuote)
        if (data.Quote.publish_time) {
          setPublishNow(false)
        }
      }
    },
    fetchPolicy: 'no-cache',
  })

  useEffect(() => {
    isEmpty(id) ? onInitQuote() : fetch()
  }, []) //eslint-disable-line react-hooks/exhaustive-deps
  

  if (loading) return <Loading />
  
  if (error) return <p>Error :(</p>
  

  return (
    <Fragment>
      <Grid justify="center">
        <Grid item className='container-editor'>
          <Main />
        </Grid>
        <Grid item className='container-editor'>
          <SettingEditor index={2} />
        </Grid>
      </Grid>
    </Fragment>
  )
}
