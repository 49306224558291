import { useEffect, useState } from 'react'
import FormMirrorProduct from '@Components/Mirror/Product/Form'
import DropArea from '@Components/DropArea'
import { IMirrorProduct } from '@Types/mirror/product_type'
import { useAuth } from '@Hooks/useAuth'
import { useMutation } from '@apollo/client'
import {
  MUTATION_ADD_MIRROR_PRODUCT,
  MUTATION_EDIT_MIRROR_PRODUCT,
  QUERY_GET_ALL_MIRROR_PRODUCTS,
} from '@Api/mirror/product_api'
import useUpload from '@Hooks/useUpload'
import { useHistory } from 'react-router-dom'
import { Typography } from '@material-ui/core'

function CreateMirrorProduct() {
  const [imgSrc, setImgSrc] = useState<FileList>()
  const [errorMessages, setErrorMessages] = useState({
    image_url: '',
  })
  const { checkPermission } = useAuth()
  const { onUpload } = useUpload()
  const [add] = useMutation(MUTATION_ADD_MIRROR_PRODUCT)
  const [edit] = useMutation(MUTATION_EDIT_MIRROR_PRODUCT)
  const history = useHistory()

  const handleSubmit = async (data: IMirrorProduct) => {
    if (!imgSrc) {
      return setErrorMessages({ image_url: 'กรุณาเลือก รูปภาพสินค้า' })
    } else setErrorMessages({ image_url: '' })
    const {
      data: { AddMirrorProduct },
    } = await add({
      variables: data,
      refetchQueries: [
        {
          query: QUERY_GET_ALL_MIRROR_PRODUCTS,
          variables: {
            page: 1,
            limit: 100,
          },
        },
      ],
    })
    if (AddMirrorProduct.statusCode === 200) {
      const res_upload_img = await onUpload(imgSrc, 'image_files', {
        ref_id: AddMirrorProduct.data._id,
      })
      if (res_upload_img.data?.status === 200) {
        await edit({
          variables: {
            ...data,
            image_url: res_upload_img.data.data?.items[0]?.url,
            _id: AddMirrorProduct.data._id,
          },
        })
      }
      history.push('/product')
    }
  }

  useEffect(() => {
    if (!checkPermission('mirror_product_edit')) {
      window.location.href = '/'
    }
    return () => {}
  }, []) //eslint-disable-line react-hooks/exhaustive-deps
  return (
    <>
      <FormMirrorProduct
        title="เพิ่มสินค้า"
        onSubmit={handleSubmit}
        renderImage={() => (
          <>
            <DropArea
              // description="Drag and Drop image(s) here"
              description={
                <>
                  <div style={{ marginBottom: 4 }}>
                    <strong>
                      Drag and Drop image(s) here
                    </strong>
                  </div>
                  <Typography variant="caption" align="center" component="p">
                    <div>
                      <b>Dimension: </b>300 x 300
                    </div>
                    <div>
                      <b>Size Limit: </b>2 MB
                    </div>
                    <div>
                      <b>File: </b>jpg, png
                    </div>
                  </Typography>
                </>
              }
              src={imgSrc && URL.createObjectURL(imgSrc[0])}
              onChangeDropArea={(src) => setImgSrc(src)}
              style={{ height: imgSrc ? 'auto' : '100%' }}
            />
            <span style={{ color: 'red' }}>{errorMessages.image_url}</span>
          </>
        )}
      />
    </>
  )
}

export default CreateMirrorProduct
