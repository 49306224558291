import { Fragment, useContext, useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { QUERY_GET_CONTENT_BY_ID } from '@Api/video_api'
import Main from './main'
import Loading from '@Components/Loading'
import Widget from '../Widget/index'
import { Grid } from '@material-ui/core'

import { IVideo } from '@Types/video_type'
import { VideoContentContext, VideoContentContextType } from '../context/VideoContext'
import isEmpty from 'lodash.isempty'

interface ParamType {
  id: string
}

export default function Detail() {
  const {
    content: [, setContent],
    onInitContent,
    publishNow: [, setPublishNow],
    onFetchSuggestionTags,
  } = useContext(VideoContentContext) as VideoContentContextType

  const { id } = useParams<ParamType>()
  const [fetch, { loading, error }] = useLazyQuery(QUERY_GET_CONTENT_BY_ID, {
    variables: {
      id: id,
    },
    onCompleted: async (data) => {
      console.log("----> ",data,)
      if (data?.CmsVideoDraft) {
        setContent(data.CmsVideoDraft as IVideo)
        if (data.CmsVideoDraft.publish_time) {
          setPublishNow(false)
        }
        onFetchSuggestionTags(data.CmsVideoDraft.topic[0]?.value)
      }
    },
    fetchPolicy: 'no-cache',
  })
  console.log(fetch)
  useEffect(() => {
    isEmpty(id) ? onInitContent() : fetch()
  }, []) //eslint-disable-line react-hooks/exhaustive-deps
  if (loading) return <Loading />
  if (error) {
    return <p>Error :(</p>
  }

  return (
    <Fragment>
      <Grid container justify="center">
        <Grid item xs={12} sm={12} md={10} lg={10}>
          <Main />
          <Widget />
        </Grid>
      </Grid>
    </Fragment>
  )
}
