import { gql } from '@apollo/client'
export const QUERY_GET_SUGGESTION_TAG_BY_SECTION = gql`
  query getSuggestionTag($section: String) {
    CmsSuggestionTag(section: $section) {
      section
      data {
        _id
        description
        tags
      }
    }
  }
`

export const MUTATION_ADD_SUGGESTION_TAG = gql`
  mutation addSuggestionTag($section: String, $data: [DataSuggestionTagInput]) {
    AddSuggestionTag(section: $section, data: $data) {
      statusCode
      message
      data
    }
  }
`
