import React from 'react'
import {
  DragDropContext,
  DropResult,
  ResponderProvided,
} from 'react-beautiful-dnd'

interface Props<T> {
  data: T[]
  onChange: (data: T[]) => void
  children: React.ReactNode
}

export function reOrder<T>(list: T[], startIndex: number, endIndex: number) {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  return result
}

function DragAndDrop<T>({ data, onChange, children }: Props<T>) {
  const onDragEnd = (
    { source, destination }: DropResult,
    provided: ResponderProvided,
  ) => {
    // Make sure we have a valid destination
    if (destination === undefined || destination === null) return null

    // Make sure we're actually moving the item
    if (
      source.droppableId === destination.droppableId &&
      destination.index === source.index
    )
      return null
    const new_data = reOrder(data, source.index, destination.index)
    onChange(new_data)
  }
  return <DragDropContext onDragEnd={onDragEnd}>{children}</DragDropContext>
}

export default DragAndDrop
