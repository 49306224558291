import { ThemeName } from '../theme'
import React from 'react'
import SettingsIcon from '@material-ui/icons/Settings'
import HomeIcon from '@material-ui/icons/Home'
import PeopleIcon from '@material-ui/icons/People'
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck'
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks'
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd'
import LoyaltyIcon from '@material-ui/icons/Loyalty'
import StoreIcon from '@material-ui/icons/Store'
import CollectionsBookmarkIcon from '@material-ui/icons/CollectionsBookmark'
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary'
import PodcastLibraryIcon from '@material-ui/icons/Cast'
import Home from '@Pages/Home'
import ContentEdit from '@Pages/Content/edit'
import ListContent from '@Pages/Content/list'
import ListVideo from '@Pages/Video/list'
import VideoEdit from '@Pages/Video/edit'
// import ListPodcast from '@Pages/Podcast/list'
// import PodcastEdit from '@Pages/Podcast/edit'
// import EditorPickPodcast from '@Pages/EditorPickPodcast'
import User from '@Pages/User'
import CreateUser from '@Pages/User/create'
import EditUser from '@Pages/User/edit'
import EditorPickVideo from '@Pages/EditorPickVideo'
import EditorPick from '@Pages/EditorPick'
// import LatestPick from '@Pages/LatestPick'
import ListQuote from '@Pages/Quote/list'
import EditQuote from '@Pages/Quote/edit'
import Shooting from '@Pages/Mirror/Shooting'
import SubLatestPick from '@Pages/SubLatestPick'
import Pagesettings from '@Pages/Pagesetting'
import SeoTag from '@Pages/SeoTag'
import EditMetaTag from '@Pages/Pagesetting/edit'
import CreateMetaTag from '@Pages/Pagesetting/create'
import Author from '@Pages/Author'
import CreateAuthor from '@Pages/Author/create'
import EditAuthor from '@Pages/Author/edit'
import ProfileUser from '@Pages/User/profile'
import ChangePasswordUser from '@Pages/User/changePassword'
import MirrorProduct from '@Pages/Mirror/Product'
import CreateMirrorProduct from '@Pages/Mirror/Product/create'
import EditMirrorProduct from '@Pages/Mirror/Product/edit'
import Panorama from '@Pages/Mirror/Panorama'
import PrPick from '@Pages/Mirror/PrPick'
// import Follow from '@Pages/Follow'
// import CreateFollow from '@Pages/Follow/create'
// import EditFollow from '@Pages/Follow/edit'
import SuggestionTag from '@Pages/SuggestionTag'
import Category from '@Pages/Category'
import EditCategory from '@Pages/Category/edit'
import MirrorProductManage from '@Pages/Mirror/ProductManage'
import AdvertorialPick from '@Pages/AdvertorialPick'
import AdvertorialPickVideo from '@Pages/AdvertorialPickVideo'
import Logout from '@Pages/Logout'

/**
 * @property {string | undifined} path - path url & undifined for root menu
 * @property {string} label - wording in sidebar
 * @property {boolean} exact - set url matching
 * @property {React.FC | undifined} component - react component
 * @property {React.ReactNode | undifined} icon - icon material-ui component
 * @property {ThemeName[] | undifined} themes - required some theme, undifined for allow all theme
 * @property {RouteType[]} childrens - childrens in sidebar format same root
 * @property {boolean} in_sidebar - flag show in sidebar
 * @property {string[] | undifined} permissions - permissions for display menu only!!! & undifined for dont require permission
 */
export type RouteType = {
  path?: string
  label: string
  exact: boolean
  component?: React.FC
  icon?: React.ReactNode
  themes?: Array<ThemeName>
  childrens: Array<RouteType>
  in_sidebar: boolean
  permissions: Array<string>
}

export const initial_route_data = {
  exact: true,
  in_sidebar: false,
  permissions: [],
  childrens: [],
  icon: undefined,
}

export const routes: Array<RouteType> = [
  {
    path: `/`,
    label: `หน้าแรก`,
    exact: true,
    in_sidebar: false,
    permissions: [],
    childrens: [],
  },
  // {
  //   path: `/home`,
  //   label: `หน้าแรก`,
  //   exact: true,
  //   component: Home,
  //   icon: <HomeIcon htmlColor="white" />,
  //   in_sidebar: true,
  //   permissions: [],
  //   childrens: [],
  // },
  {
    label: `บทความ`,
    exact: true,
    icon: <LibraryBooksIcon htmlColor="white" />,
    in_sidebar: true,
    permissions: [],
    childrens: [
      {
        path: `/content`,
        label: `บทความทั้งหมด`,
        exact: true,
        component: ListContent,
        icon: <LibraryBooksIcon htmlColor="white" />,
        in_sidebar: true,
        permissions: [`content_view`],
        childrens: [],
      },
      {
        ...initial_route_data,
        path: '/content/edit/:id?',
        label: `แก้ไขบทความ`,
        exact: false,
        component: ContentEdit,
      },

      {
        path: `/editor-pick`,
        label: `Editor's Pick`,
        exact: true,
        component: EditorPick,
        icon: <PlaylistAddCheckIcon htmlColor="white" />,
        in_sidebar: true,
        permissions: [`editor_view`],
        childrens: [],
        themes: ['thairath-plus'],
      },
      // {
      //   path: `/latest-pick`,
      //   label: `Latest Pick`,
      //   exact: true,
      //   component: LatestPick,
      //   icon: <PlaylistAddCheckIcon htmlColor="white" />,
      //   in_sidebar: true,
      //   permissions: [`latest_view`],
      //   childrens: [],
      //   themes: ['thairath-plus', 'mirror'],
      // },
      {
        path: `/sub-latest-pick`,
        label: `Sub-Latest Pick`,
        exact: true,
        component: SubLatestPick,
        icon: <PlaylistAddCheckIcon htmlColor="white" />,
        in_sidebar: true,
        permissions: [`latest_view`],
        childrens: [],
        themes: ['thairath-plus'],
      },
      {
        path: `/advertorial-pick`,
        label: `Advertorial Pick`,
        exact: true,
        component: AdvertorialPick,
        icon: <PlaylistAddCheckIcon htmlColor="white" />,
        in_sidebar: true,
        permissions: [`latest_view`],
        childrens: [],
        themes: ['thairath-plus'],
      },
      {
        path: `/panorama`,
        label: `Panorama`,
        exact: true,
        component: Panorama,
        icon: <PlaylistAddCheckIcon htmlColor="white" />,
        in_sidebar: true,
        permissions: [`mirror_panorama_view`],
        childrens: [],
        themes: ['mirror'],
      },
      {
        path: `/pr-pick`,
        label: `Pr Pick`,
        exact: true,
        component: PrPick,
        icon: <PlaylistAddCheckIcon htmlColor="white" />,
        in_sidebar: true,
        permissions: [`mirror_pr_view`, 'mirror_pr_edit'],
        childrens: [],
        themes: ['mirror'],
      },
    ],
  },
  {
    label: `QUOTE`,
    exact: true,
    icon: <LibraryBooksIcon htmlColor="white" />,
    in_sidebar: true,
    permissions: [],
    themes: ['mirror'],
    childrens: [
      {
        path: `/quote`,
        label: `เขียนข้อความ`,
        exact: true,
        component: ListQuote,
        icon: <LibraryBooksIcon htmlColor="white" />,
        in_sidebar: true,
        permissions: [`content_view`],
        childrens: [],
      },
      {
        ...initial_route_data,
        path: '/quote/edit/:id?',
        label: `แก้ไข Quote`,
        exact: false,
        component: EditQuote,
      },
      {
        path: `/shooting`,
        label: `เลือกบทความ`,
        exact: true,
        component: Shooting,
        icon: <PlaylistAddCheckIcon htmlColor="white" />,
        in_sidebar: true,
        permissions: [`content_view`],
        childrens: [],
        themes: ['mirror'],
      },
    ],
  },
  {
    label: `วีดีโอ`,
    exact: true,
    icon: <VideoLibraryIcon htmlColor="white" />,
    in_sidebar: true,
    permissions: [],
    themes: ['thairath-plus'],
    childrens: [
      {
        path: `/video`,
        label: `วีดีโอ`,
        exact: true,
        component: ListVideo,
        icon: <VideoLibraryIcon htmlColor="white" />,
        in_sidebar: true,
        permissions: [`content_view`],
        childrens: [],
        themes: ['thairath-plus']
      },
      {
        path: `/editor-pick-video`,
        label: `Video's Pick`,
        exact: true,
        component: EditorPickVideo,
        icon: <VideoLibraryIcon htmlColor="white" />,
        in_sidebar: true,
        permissions: [`editor_view`],
        childrens: [],
        themes: ['thairath-plus'],
      },
      {
        path: `/advertorial-pick-video`,
        label: `Advertorial Pick`,
        exact: true,
        component: AdvertorialPickVideo,
        icon: <VideoLibraryIcon htmlColor="white" />,
        in_sidebar: true,
        permissions: [`editor_view`],
        childrens: [],
        themes: ['thairath-plus'],
      },
      {
        ...initial_route_data,
        path: '/video/edit/:id?',
        label: `แก้ไขบทความ`,
        exact: false,
        component: VideoEdit,
      },
    ],
  },
  // {
  //   label: `พอดแคสต์`,`
  //   exact: true,
  //   icon: <PodcastLibraryIcon htmlColor="white" />,
  //   in_sidebar: true,
  //   permissions: [],
  //   childrens: [
  //     {
  //       path: `/podcast`,
  //       label: `พอดแคสต์`,
  //       exact: true,
  //       component: ListPodcast,
  //       icon: <PodcastLibraryIcon htmlColor="white" />,
  //       in_sidebar: true,
  //       permissions: [`content_view`],
  //       childrens: [],
  //     },
  //     {
  //       path: `/editor-pick-poscast`,
  //       label: `Editor's Pick`,
  //       exact: true,
  //       component: EditorPickPodcast,
  //       icon: <PlaylistAddCheckIcon htmlColor="white" />,
  //       in_sidebar: true,
  //       permissions: [`editor_view`],
  //       childrens: [],
  //       themes: ['thairath-plus'],
  //     },
  //     {
  //       ...initial_route_data,
  //       path: '/Podcast/edit/:id?',
  //       label: `แก้ไขพอดแคสต์`,
  //       exact: false,
  //       component: PodcastEdit,
  //     },
  //   ],
  // },
  // {
  //   path: `/category`,
  //   label: `TOPIC/SUBTOPIC`,
  //   exact: true,
  //   component: Category,
  //   icon: <CollectionsBookmarkIcon htmlColor="white" />,
  //   in_sidebar: true,
  //   permissions: [`category_view`],
  //   themes: ['thairath-plus', 'mirror'],
  //   childrens: [
  //     {
  //       ...initial_route_data,
  //       path: '/category/edit',
  //       label: `เพิ่มประเภท`,
  //       component: EditCategory,
  //     },
  //     {
  //       ...initial_route_data,
  //       path: '/category/edit/:id',
  //       label: `แก้ไขประเภท`,
  //       component: EditCategory,
  //     },
  //   ],
  // },
  // {
  //   path: `/follow`,
  //   label: `FOLLOW`,
  //   exact: true,
  //   component: Follow,
  //   icon: <LibraryBooksIcon htmlColor="white" />,
  //   in_sidebar: true,
  //   permissions: ['author_view'],
  //   childrens: [
  //     {
  //       ...initial_route_data,
  //       path: '/follow/create',
  //       label: `เพิ่มผู้เขียน`,
  //       component: CreateFollow,
  //     },
  //     {
  //       ...initial_route_data,
  //       path: '/follow/edit/:id',
  //       label: `แก้ไขผู้เขียน`,
  //       component: EditFollow,
  //     },
  //   ],
  // },
  {
    path: `/author`,
    label: `นักเขียน/ช่างภาพ`,
    exact: true,
    component: Author,
    icon: <AssignmentIndIcon htmlColor="white" />,
    in_sidebar: true,
    permissions: ['author_view'],
    childrens: [
      {
        ...initial_route_data,
        path: '/author/create',
        label: `เพิ่มผู้เขียน`,
        component: CreateAuthor,
      },
      {
        ...initial_route_data,
        path: '/author/edit/:id',
        label: `แก้ไขผู้เขียน`,
        component: EditAuthor,
      },
    ],
  },
  {
    label: `Mirror Guide`,
    icon: <StoreIcon htmlColor="white" />,
    in_sidebar: true,
    themes: ['mirror'],
    exact: true,
    permissions: [],
    childrens: [
      {
        label: `สินค้าทั้งหมด`,
        exact: true,
        path: `/product`,
        component: MirrorProduct,
        icon: <StoreIcon htmlColor="white" />,
        in_sidebar: true,
        permissions: ['mirror_product_view'],
        themes: ['mirror'],
        childrens: [],
      },
      {
        ...initial_route_data,
        path: '/product/create',
        label: `เพิ่มสินค้า`,
        component: CreateMirrorProduct,
      },
      {
        ...initial_route_data,
        path: '/product/edit/:id',
        label: `แก้ไขสินค้า`,
        component: EditMirrorProduct,
      },
      {
        label: `จัดการสินค้า`,
        exact: true,
        path: `/product-manage`,
        component: MirrorProductManage,
        icon: <PlaylistAddCheckIcon htmlColor="white" />,
        in_sidebar: true,
        permissions: ['mirror_product_view'],
        themes: ['mirror'],
        childrens: [],
      },
    ],
  },
  {
    label: `SEO`,
    exact: true,
    icon: <LoyaltyIcon htmlColor="white" />,
    in_sidebar: true,
    permissions: [],
    childrens: [
      {
        path: `/page-setting`,
        label: `Title/Description`,
        exact: true,
        component: Pagesettings,
        icon: <LoyaltyIcon htmlColor="white" />,
        in_sidebar: true,
        permissions: [`seo_tag_pages_view`],
        childrens: [],
      },
      {
        ...initial_route_data,
        path: '/page-setting/create',
        label: `เพิ่ม meta-tag`,
        component: CreateMetaTag,
      },
      {
        ...initial_route_data,
        path: '/page-setting/edit/:id',
        label: `แก้ไข meta-tag`,
        component: EditMetaTag,
      },
      // {
      //   path: `/seo-tag`,
      //   label: `Seo Tag`,
      //   exact: true,
      //   component: SeoTag,
      //   icon: <LoyaltyIcon htmlColor="white" />,
      //   in_sidebar: true,
      //   permissions: [`seo_tag_pages_view`],
      //   childrens: [],
      // },
      {
        path: `/suggestion-tag`,
        label: `Suggestion Tag`,
        exact: true,
        component: SuggestionTag,
        icon: <LoyaltyIcon htmlColor="white" />,
        in_sidebar: true,
        permissions: [`seo_tag_suggestion_view`],
        childrens: [],
      },
    ],
  },

  {
    label: `ดูแลระบบ`,
    exact: true,
    icon: <SettingsIcon htmlColor="white" />,
    in_sidebar: true,
    permissions: [],
    childrens: [
      {
        path: `/user`,
        label: `ผู้ใช้ทั้งหมด`,
        exact: true,
        component: User,
        icon: <PeopleIcon htmlColor="white" />,
        in_sidebar: true,
        permissions: [`admin_view`],
        childrens: [],
      },
      {
        ...initial_route_data,
        path: `/user/create`,
        label: `เพิ่มผู้ใช้`,
        component: CreateUser,
      },
      {
        ...initial_route_data,
        path: `/user/edit/:id`,
        label: `แก้ไขผู้ใช้`,
        component: EditUser,
      },
      {
        ...initial_route_data,
        path: `/user/profile`,
        label: `แก้ไขข้อมูลส่วนตัว`,
        component: ProfileUser,
      },
      {
        ...initial_route_data,
        path: `/user/profile/change-password`,
        label: `เปลี่ยนรหัสผ่าน`,
        component: ChangePasswordUser,
      },
    ],
  },
  {
    path: `/logout`,
    label: `ออกจากระบบ`,
    exact: true,
    component: Logout,
    in_sidebar: false,
    permissions: [],
    childrens: [],
  },
]
