import { useMutation } from '@apollo/client'
import { Avatar, makeStyles } from '@material-ui/core'
import React, { ReactElement, useState } from 'react'
import { useHistory } from 'react-router'
import {
  MUTATION_ADD_USER,
  QUERY_GET_ALL_USERS,
  MUTATION_EDIT_USER,
} from '@Api/user_api'
import Form from '@Components/User/Form'
import { styles } from '@Components/User/form.style'
import useUpload from '@Hooks/useUpload'
import { userType } from '@Types/user_type'
const useStyles = makeStyles(styles)
export default function CreateUser(): ReactElement {
  const classes = useStyles()
  const history = useHistory()
  const [imageProfile, setImageProfile] = useState<FileList>()
  const { onUpload } = useUpload()
  const [addUser] = useMutation(MUTATION_ADD_USER)
  const [editUser] = useMutation(MUTATION_EDIT_USER)
  const handleSubmit = async (obj: userType) => {
    const { data } = await addUser({
      variables: obj,
      refetchQueries: [
        {
          query: QUERY_GET_ALL_USERS,
        },
      ],
    })
    if (data.AddUser.statusCode === 200) {
      const _id = data.AddUser.data._id
      if (imageProfile) {
        const res_image = await onUpload(imageProfile, 'image_files', {
          ref_id: _id,
        })
        if (res_image.data?.status === 200) {
          editUser({
            variables: {
              ...obj,
              profile_image: res_image.data.data?.items[0]?.url,
              _id: _id,
            },
          })
        }
      }
      history.push('/user')
    }
  }
  const handleChangeFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const imgs = event.target.files
    if (imgs) setImageProfile(imgs)
  }
  return (
    <>
      <Form
        bypass={false}
        onSubmit={handleSubmit}
        avatar={() => {
          return (
            <>
              <Avatar
                className={classes.avatar}
                src={
                  imageProfile
                    ? URL.createObjectURL(imageProfile[0])
                    : undefined
                }
              />
              <input
                type="file"
                onChange={handleChangeFile}
                className={classes.upload_profile}
              />
            </>
          )
        }}
        title="เพิ่มข้อมูลผู้ใช้งาน"
      />
    </>
  )
}
