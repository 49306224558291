import { useMutation } from '@apollo/client'
import { useState, createContext, useContext, useEffect } from 'react'
import cookie from 'react-cookies'
import { MUTATION_LOGIN_USER } from '../api/user_api'
import { ActiveUser, loginType, userType } from '@Types/user_type'
import jwt_decode from 'jwt-decode'
export const CMS_TR_PLUS_COOKIE = 'cms-token'

export const AuthContext = createContext({})

export const useAuth = () => {
  return useContext(AuthContext) as AuthContextType
}

export type AuthContextType = {
  user: userType
  login: ({ username, password }: loginType) => boolean
  logout: () => boolean
  errors: { message: string }
  checkPermission: (
    permission: string | string[],
    operation?: 'or' | 'and',
  ) => boolean
}
export const removeTokenUser = () =>
  cookie.remove(CMS_TR_PLUS_COOKIE, { path: '/' })
export const getTokenUser = () => cookie.load(CMS_TR_PLUS_COOKIE)
export const getDataPayloadJWT = () => {
  const token = getTokenUser()
  if (token) return jwt_decode(token) as userType
  else return undefined
}
export const AuthContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [user] = useState<userType | undefined>(getDataPayloadJWT())
  const [errors, setErrors] = useState<{ message: string }>()

  const [loginUser] = useMutation(MUTATION_LOGIN_USER)

  const login = async ({ username, password }: loginType): Promise<boolean> => {
    try {
      const {
        data: { LoginUser },
      } = await loginUser({
        variables: {
          username,
          password,
        },
      })

      if (LoginUser.statusCode !== 200) {
        setErrors({
          message: LoginUser.message,
        })
        return false
      }

      let ws = new WebSocket(process.env.REACT_APP_WEB_SOCKET || '')

      const wsPromise = new Promise<boolean>((resolve) => {
        ws.onopen = () => {
          console.log('connected')
          const payload = {
            type: 'login',
            userToken: LoginUser.token,
            user: LoginUser.user,
          }
          ws.send(JSON.stringify(payload))
        }

        ws.onmessage = (event) => {
          const message = JSON.parse(event.data)
          // console.log('Received in Login:', message)
          const { type, isSuccess, userActive } = message
          console.log('message => ', message)
    

          if (type === 'login') {
            resolve(false)
            // if (!isSuccess) {
            //   console.log('Login failed in ws')
            //   setErrors({
            //     message: 'Oops! This account already logged in',
            //   })
            //   resolve(true)
            // } else {

            //   // resolve(false)
            // }
          }
        }
      })

      const wsResult = await wsPromise
      console.log('wsResult Login Failed', wsResult)
      ws.close()
      if (wsResult) {
        return false
      }

      setErrors({ message: '' })

      const expires = new Date()
      expires.setDate(Date.now() + 1000 * 60 * 5)
      cookie.save(CMS_TR_PLUS_COOKIE, LoginUser.token, {
        path: '/',
        expires: expires,
        maxAge: 60 * 60 * 24,
      })

      return true
    } catch (error) {
      console.log('useAuth -> login', error)
      return false
    }
  }
  const logout = async (): Promise<boolean> => {
    try {
      const token = getTokenUser()
      let ws = new WebSocket(process.env.REACT_APP_WEB_SOCKET || '')
      const wsPromise = new Promise<boolean>((resolve) => {
        ws.onopen = () => {
          console.log('connected')
          const payload = {
            type: 'logout',
            userToken: token,
            user: user,
          }
          ws.send(JSON.stringify(payload))
          resolve(false)
        }
      })
      const wsResult = await wsPromise
      console.log('wsResult Logout Failed', wsResult)
      if (wsResult) {
        return false
      }
      ws.close()
      removeTokenUser()
      return true
    } catch (error) {
      console.log('useAuth -> logout', error)
      return false
    }
  }

  const checkPermission = (
    permission: string | string[],
    operation?: 'or' | 'and',
  ) => {
    let checked: boolean = false
    let arrCheck: any = []
    const myPermission = user?.roles
    if (typeof permission === 'string') {
      checked = myPermission?.includes(permission) as boolean
    } else if (Array.isArray(permission)) {
      permission.forEach((per) => {
        arrCheck.push(myPermission?.includes(per) as boolean)
        switch (operation) {
          case 'or':
            checked = arrCheck.includes(true)
            break
          case 'and':
            let result = arrCheck.includes(false)
            checked = !result
            break
          default:
            checked = myPermission?.includes(per) as boolean
            break
        }
      })
    } else {
      checked = false
    }
    return checked
  }

  useEffect(() => {
    const token = getTokenUser()
    let ws = new WebSocket(process.env.REACT_APP_WEB_SOCKET || '')
    ws.onopen = () => {
      console.log('connected')
      const payload = {
        type: 'checkActive',
        userToken: token,
        user: user,
      }
      ws.send(JSON.stringify(payload))
    }
    ws.onmessage = (event) => async () => {
      const message = JSON.parse(event.data)
      const { type, isSuccess, userActive } = message
      console.log('UserActive', userActive?.length)
      if (type === 'checkActive') {

        const tokenFound = await userActive.some(
          (userData: { userToken: any }) => userData.userToken === token,
        )
        if (!tokenFound) {
          removeTokenUser()
          window.location.reload()
        }
      }
    }
    ws.onclose = () => {}
    return () => {
      ws.close()
    }
  }, [])

  const store = {
    user,
    errors,
    login,
    logout,
    checkPermission,
  }

  return <AuthContext.Provider value={store}>{children}</AuthContext.Provider>
}
