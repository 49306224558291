import { gql } from '@apollo/client'
export const MUTATION_PUBLISH_CONTENT = gql`
  mutation PublishContent(
    $id: String!
    $publish_time: Date
    $expire_time: Date
  ) {
    PublishContent(
      id: $id
      publish_time: $publish_time
      expire_time: $expire_time
    ) {
      statusCode
      message
      data
    }
  }
`
export const QUERY_GET_ALL_CONTENTS = gql`
  query ContentDrafts($contentParams: ContentParams, $page: Int, $limit: Int) {
    ContentDrafts(params: $contentParams, page: $page, limit: $limit) {
      total
      data {
        id
        title
        abstract
        summary
        categories {
          _id
          value
          label
        }
        topic {
          _id
          value
          label
        }
        subtopic {
          _id
          value
          label
        }
        status
        created_time
        updated_time(format: "DD/MM/YYYY HH:mm:ss")
        created_by {
          _id
          first_name
          last_name
          nick_name
        }
        publish_time(format: "DD/MM/YYYY HH:mm:ss")
        pr_expire_time(format: "DD/MM/YYYY HH:mm:ss")
        expire_time
        premium_type
        author {
          _id
          first_name
          last_name
          nick_name
        }
        photographer {
          _id
          first_name
          last_name
          nick_name
        }
        follow {
          _id
          title
        }
        is_storytelling
        storytelling
      }
    }
  }
`

export const QUERY_GET_CONTENT_BY_ID = gql`
  query ContentDraft($id: String) {
    ContentDraft(id: $id) {
      id
      title
      abstract
      summary
      widgets {
        layout
        data {
          description
          thumbnails
        }
      }
      categories {
        _id
        value
        label
      }
      topic {
        _id
        value
        label
      }
      subtopic {
        _id
        value
        label
      }
      tags
      status
      created_time
      updated_time
      created_by {
        _id
        first_name
        last_name
        nick_name
      }
      assign_to
      publish_time
      expire_time
      unlist
      premium_type
      allow_comment
      gallery
      cover
      share
      token
      images
      author {
        _id
        first_name
        last_name
        nick_name
      }
      photographer {
        _id
        first_name
        last_name
        nick_name
      }
      follow {
        _id
        title
      }
      has_video
      video_embed
      thumbnail_1_1
      thumbnail_9_16
      content_type
      content_type_expire_time
      is_storytelling
      storytelling
      pr_expire_time
    }
  }
`

export const MUTATION_ADD_CONTENT = gql`
  mutation AddContentDraft(
    $title: String
    $abstract: String
    $summary: String
    $widgets: [WidgetInput]
    $categories: [ID]
    $topic: [ID]
    $subtopic: [ID]
    $tags: [String]
    $status: String
    $assign_to: String
    $publish_time: String
    $expire_time: String
    $unlist: Boolean
    $premium_type: String
    $allow_comment: Boolean
    $gallery: [String]
    $cover: String
    $share: String
    $images: [String]
    $author: AuthorInput
    $photographer: AuthorInput
    $follow: FollowInput
    $has_video: Boolean
    $related: [String]
    $video_embed: String
    $thumbnail_1_1: String
    $thumbnail_9_16: String
    $content_type: String
    $content_type_expire_time: Date
    $is_storytelling: Boolean
    $storytelling: JSON
    $pr_expire_time: Date
  ) {
    AddContentDraft(
      title: $title
      abstract: $abstract
      summary: $summary
      widgets: $widgets
      categories: $categories
      topic: $topic
      subtopic: $subtopic
      tags: $tags
      status: $status
      assign_to: $assign_to
      publish_time: $publish_time
      expire_time: $expire_time
      unlist: $unlist
      premium_type: $premium_type
      allow_comment: $allow_comment
      gallery: $gallery
      cover: $cover
      share: $share
      images: $images
      author: $author
      photographer: $photographer
      follow: $follow
      has_video: $has_video
      related: $related
      video_embed: $video_embed
      thumbnail_1_1: $thumbnail_1_1
      thumbnail_9_16: $thumbnail_9_16
      content_type: $content_type
      content_type_expire_time: $content_type_expire_time
      is_storytelling: $is_storytelling
      storytelling: $storytelling
      pr_expire_time: $pr_expire_time
    ) {
      statusCode
      message
      data
    }
  }
`

export const MUTATION_EDIT_CONTENT = gql`
  mutation EditContentDraft(
    $id: String!
    $title: String
    $abstract: String
    $summary: String
    $widgets: [WidgetInput]
    $categories: [ID]
    $topic: [ID]
    $subtopic: [ID]
    $tags: [String]
    $status: String
    $assign_to: String
    $publish_time: String
    $expire_time: String
    $unlist: Boolean
    $premium_type: String
    $allow_comment: Boolean
    $gallery: [String]
    $cover: String
    $share: String
    $created_by: IUserCreateByInput
    $images: [String]
    $author: AuthorInput
    $photographer: AuthorInput
    $follow: FollowInput
    $has_video: Boolean
    $related: [String]
    $video_embed: String
    $thumbnail_1_1: String
    $thumbnail_9_16: String
    $content_type: String
    $content_type_expire_time: Date
    $is_storytelling: Boolean
    $storytelling: JSON
    $pr_expire_time: Date
  ) {
    EditContentDraft(
      id: $id
      title: $title
      abstract: $abstract
      summary: $summary
      widgets: $widgets
      categories: $categories
      topic: $topic
      subtopic: $subtopic
      tags: $tags
      status: $status
      assign_to: $assign_to
      publish_time: $publish_time
      expire_time: $expire_time
      unlist: $unlist
      premium_type: $premium_type
      allow_comment: $allow_comment
      gallery: $gallery
      cover: $cover
      share: $share
      created_by: $created_by
      images: $images
      author: $author
      photographer: $photographer
      follow: $follow
      has_video: $has_video
      related: $related
      video_embed: $video_embed
      thumbnail_1_1: $thumbnail_1_1
      thumbnail_9_16: $thumbnail_9_16
      content_type: $content_type
      content_type_expire_time: $content_type_expire_time
      is_storytelling: $is_storytelling
      storytelling: $storytelling
      pr_expire_time: $pr_expire_time
    ) {
      statusCode
      message
      data
    }
  }
`

export const QUERY_GET_TOPIC_AND_CATEGORY = gql`
  query TopicsAndCategories($categoryParams: CategoryParams) {
    Topics {
      id: _id
      value: label
      topic_value: value
    }
    Categories(params: $categoryParams, order_by: { field: updated_at, sort: desc }) {
      id: _id
      value: label
      parent_id: parent_id
    }
  }
`

export const QUERY_GET_TOPIC_AND_CATEGORY_AND_CHANNEL = gql`
  query TopicsAndCategories($categoryParams: CategoryParams) {
    Topics {
      id: _id
      value: label
      topic_value: value
    }
    Categories(params: $categoryParams, order_by: { field: label, sort: asc }) {
      id: _id
      value: label
      parent_id: parent_id
    }
    Channels {
      id: _id
      value: label
    }
  }
`

export const QUERY_GET_SUBTOPICS = gql`
  query SubTopics {
    SubTopics {
      id: _id
      label: label
      value: value
      topicRef: topicRef
    }
  }
`

export const QUERY_GET_SUBTOPICS_BY_TOPIC = gql`
  query SubTopicsByTopic($params: SubTopicParams) {
    SubTopicsByTopic(params: $params) {
      id: _id
      label: label
      value: value
      topicRef: topicRef
    }
  }
`

export const QUERY_GET_OPTION_SEARCH = gql`
  query {
    Topics {
      id: _id
      value: label
    }
    Categories {
      id: _id
      value: label
    }
    Authors: CmsOnlyAuthors {
      first_name
      last_name
      value: full_name
      id: _id
    }
    Photographer: CmsPhotographers {
      first_name
      last_name
      value: full_name
      id: _id
    }
    Follows: CmsFollows {
      id: _id
      value: title
    }
  }
`
export const QUERY_GET_CONTENT_HISTORY = gql`
  query getContentHistory($id: String) {
    ContentHistory(id: $id) {
      created_by {
        first_name
        last_name
        profile_image
        description
      }
      publish_time(format: "DD/MM/YYYY HH:mm:ssZ")
      updated_time(format: "DD/MM/YYYY HH:mm:ssZ")
      status
    }
  }
`

export const MUTATION_EDIT_CONTENT_STATUS = gql`
  mutation ($id: String!, $status: String!) {
    EditContentStatus(id: $id, status: $status) {
      statusCode
      message
      data
    }
  }
`

export const QUERY_GET_RELATED_CONTENT = gql`
  query getRelated($id: String) {
    ContentDraft(id: $id) {
      related(is_cms: true) {
        id
        title
        abstract
        summary
        updated_time
        publish_time
        cover
        count_view
      }
    }
  }
`
