import { Checkbox, makeStyles, TextField } from '@material-ui/core'
import { CheckBox, CheckBoxOutlineBlank } from '@material-ui/icons'
import Autocomplete from '@material-ui/lab/Autocomplete'
import React from 'react'

export interface AutoCompleteSearchProps {
  label: string
  options: Array<{
    id: string
    value: string
  }>
  limitTags?: number
  name?: string
  onChange: (
    event: React.ChangeEvent<{}>,
    value: { id: string; value: string }[],
    name?: string,
  ) => void
}

const useStyles = makeStyles((theme) => ({
  menu_item: {
    boxShadow:
      '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
  },
}))
export default function AutoCompleteSearch({
  label,
  options,
  limitTags,
  name,
  onChange,
}: AutoCompleteSearchProps) {
  const classes = useStyles()
  const handleSelectChange = (
    event: React.ChangeEvent<{}>,
    value: { id: string; value: string }[],
  ) => {
    onChange(event, value, name)
  }
  return (
    <>
      <Autocomplete
        onChange={handleSelectChange}
        multiple
        options={options}
        disableCloseOnSelect
        getOptionSelected={(option, value) => value.id === option.id}
        getOptionLabel={(option: { id: string; value: string }) => option.value}
        classes={{
          paper: classes.menu_item,
        }}
        limitTags={limitTags}
        renderOption={(option: { id: string; value: string }, { selected }) => (
          <React.Fragment>
            <Checkbox
              icon={<CheckBoxOutlineBlank />}
              checkedIcon={<CheckBox />}
              checked={selected}
              size="small"
              style={{
                height: 28,
                width: 28,
                marginRight: 8,
              }}
            />
            {option.value}
          </React.Fragment>
        )}
        renderInput={(params) => (
          <TextField {...params} size="small" label={label} />
        )}
      />
    </>
  )
}
