import React, { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { ThemeName } from '../theme'
import { wordingType } from '@Types/wording_type'
import thairath_plus_wording from '@Libs/wording/thairath_plus'
import mirror_wording from '@Libs/wording/mirror'
export const GlobalContext = createContext({})
export interface GlobalContextType {
  theme_name: ThemeName
  onChangeThemeName: (theme: ThemeName) => void
  wording: wordingType
  onToggleMenu: (open: boolean) => void
  openMenu: boolean
}

export const useGlobal = () => {
  return useContext(GlobalContext) as GlobalContextType
}

export const GlobalContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [openMenu, setOpenMenu] = useState<boolean>(true)
  const [themeName, setThemeName] = useState<ThemeName>(
    process.env.REACT_APP_THEME as ThemeName,
  )
  const wording: wordingType = useMemo(() => {
    switch (themeName) {
      case 'thairath-plus':
        return thairath_plus_wording
      case 'mirror':
        return mirror_wording
      default:
        return thairath_plus_wording
    }
  }, [themeName])
  const onChangeThemeName = (theme: ThemeName) => setThemeName(theme)
  useEffect(() => {
    document.title = wording['app_name']
    const favicon = document.getElementById('favicon') as HTMLLinkElement
    if (favicon) favicon.href = wording['favicon']
  }, [themeName]) //eslint-disable-line react-hooks/exhaustive-deps

  const onToggleMenu = (open: boolean) => setOpenMenu(open)
  const store = {
    theme_name: themeName,
    onChangeThemeName,
    wording,
    onToggleMenu,
    openMenu,
  }

  return (
    <GlobalContext.Provider value={store}>{children}</GlobalContext.Provider>
  )
}
