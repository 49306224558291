import { gql } from '@apollo/client'

export const QUERY_GET_ALL_AUTHORS = gql`
  query {
    Authors: CmsAuthors {
      _id
      first_name
      last_name
      full_name
      nick_name
      slug_name
      profile_image
      telephone
      email
      categories
      description
      is_active
      last_update(format: "DD/MM/YYYY HH:mm:ss")
    }
  }
`

export const QUERY_GET_ALL_AUTHORS_OPTION = gql`
  query getAuthors($categories: String){
    Authors: AuthorsV2(categories: $categories) {
      first_name
      last_name
      value: full_name
      id: _id
    }
  }
`

export const QUERY_GET_ALL_PHOTOGRAPHER_OPTION = gql`
  query getPhotographers($categories: String) {
    Photographers: PhotographersV2(categories: $categories)  {
      first_name
      last_name
      value: full_name
      id: _id
    }
  }
`

export const QUERY_GET_AUTHOR_BY_ID = gql`
  query ($_id: ID!) {
    Author: CmsAuthor(_id: $_id) {
      _id
      first_name
      last_name
      full_name
      nick_name
      slug_name
      profile_image
      telephone
      email
      categories
      description
      is_active
      last_update(format: "DD/MM/YYYY HH:mm:ss")
    }
  }
`

export const MUTATION_ADD_AUTHOR = gql`
  mutation (
    $first_name: String
    $last_name: String
    $nick_name: String
    $slug_name: String
    $profile_image: String
    $telephone: String
    $email: String
    $categories: String
    $description: String
    $is_active: String
  ) {
    AddAuthor(
      first_name: $first_name
      last_name: $last_name
      nick_name: $nick_name
      slug_name: $slug_name
      profile_image: $profile_image
      telephone: $telephone
      email: $email
      categories: $categories
      description: $description
      is_active: $is_active
    ) {
      statusCode
      message
      data
    }
  }
`

export const MUTATION_EDIT_AUTHOR = gql`
  mutation (
    $_id: ID
    $first_name: String
    $last_name: String
    $nick_name: String
    $slug_name: String
    $profile_image: String
    $telephone: String
    $email: String
    $categories: String
    $description: String
    $is_active: String
  ) {
    EditAuthor(
      _id: $_id
      first_name: $first_name
      last_name: $last_name
      nick_name: $nick_name
      slug_name: $slug_name
      profile_image: $profile_image
      telephone: $telephone
      email: $email
      categories: $categories
      description: $description
      is_active: $is_active
    ) {
      statusCode
      message
      data
    }
  }
`
