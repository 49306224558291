import { isEmpty } from 'lodash'
import NotFound from '../pages/NotFound'
import { Route, Redirect, Switch } from 'react-router-dom'
import { useMemo } from 'react'
import { routes, RouteType } from './index'
import { useGlobal } from '@Hooks/useGlobal'
export default function Routes() {
  const { theme_name } = useGlobal()

  const render_routes = useMemo(() => {
    const checkRoute = (route: RouteType) => {
      if (
        route.path &&
        (isEmpty(route.themes) || route.themes?.some((v) => v === theme_name))
      )
        return [route]
      else return []
    }
    return routes.map((route) =>
      isEmpty(route.childrens)
        ? !isEmpty(checkRoute(route)) && (
            <Route
              exact={route.exact}
              path={route.path}
              component={route.component}
              key={route.path}
            />
          )
        : [...checkRoute(route), ...route.childrens].map(
            (item) =>
              item.path && (
                <Route
                  exact={item.exact}
                  path={item.path}
                  component={item.component}
                  key={route.path}
                />
              ),
          ),
    )
  }, [theme_name])
  return (
    <Switch>
      {render_routes}
      <Route exact path="/404" component={NotFound} />
      <Redirect to="/404" />
    </Switch>
  )
}
