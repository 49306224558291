import React, { useContext, useEffect, useState } from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { makeStyles, TextField } from '@material-ui/core'
import {
  ContentContext,
  ContentContextType,
} from '../../context/ContentContext'
import { styles } from '../styles'
import { ICategory } from '@Types/category_type'
import { ITopic } from '@Types/topic_type'
import { useGlobal } from '@Hooks/useGlobal'
import { useLazyQuery } from '@apollo/client'
import { QUERY_GET_TOPIC_AND_CATEGORY } from '@Api/content_api'
import Loading from '@Components/Loading'

const useStyles = makeStyles(styles)

function SettingMirror() {
  const classes = useStyles()
  const { wording } = useGlobal()
  const {
    content: [content],
    handleSelectChange,
  } = useContext(ContentContext) as ContentContextType

  const [data, setData] = useState<any>()
  const [fetch, { loading }] = useLazyQuery(QUERY_GET_TOPIC_AND_CATEGORY, {
    variables: {
      categoryParams: {
        status: 10,
      },
      order_by: {
        field: 'updated_at',   // Sorting by the 'updated_at' field
        sort: 'desc',          // Use 'asc' for ascending, 'desc' for descending
      },
    },
    onCompleted: async (data) => {
      setData(data)
    },
    fetchPolicy: 'no-cache',
  })

  useEffect(() => {
    fetch()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const setFormatData = (data: ITopic[] | ICategory[]) => {
    return data.map((item: any) => ({
      id: item?._id,
      value: item?.label,
    }))
  }

  const [topicSelected, setTopicSelected] = useState(
    setFormatData(content.topic),
  )
  const [categorySelected, setCategorySelected] = useState(
    setFormatData(content.categories),
  )

  if (loading) return <Loading />

  return (
    <>
      {data && (
        <>
          <Autocomplete
            onChange={(_, value) => {
              if (!value) {
                handleSelectChange('topic', [])
                setTopicSelected([])
                // setCategorySelected([])
                return
              }

              if (value.id !== topicSelected[0]?.id) {
                handleSelectChange('topic', [value.id])
                setTopicSelected([value])
                // handleSelectChange('categories', [])
                setCategorySelected([])
                return
              } else {
                handleSelectChange('topic', [value.id])
                setTopicSelected([value])
                // setCategorySelected([])
              }
            }}
            getOptionSelected={(option, value) => option?.id === value?.id}
            size="small"
            options={data.Topics}
            value={topicSelected[0]}
            getOptionLabel={(option) => option.value}
            classes={{
              paper: classes.menu_item,
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label={wording['topic']}
              />
            )}
          />
          <Autocomplete
            onChange={(_, values) => {
              if (values) {
                handleSelectChange('categories', [values.id])
                setCategorySelected([values])
              } else {
                handleSelectChange('categories', [])
                setCategorySelected([])
              }
            }}
            size="small"
            options={(data.Categories || []).filter((list: { parent_id: any }) => { return list.parent_id === topicSelected[0]?.id })}
            value={categorySelected[0] || ''}
            getOptionSelected={(option, value) => option?.id === value?.id}
            getOptionLabel={(option: { id: string; value: string }) =>
              option.value
            }
            classes={{
              paper: classes.menu_item,
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label={wording['category']}
              />
            )}
          />
        </>
      )}
    </>
  )
}

export default SettingMirror
