import { gql } from '@apollo/client'

export const QUERY_GET_TOPIC = gql`
  query ($params: TopicParams!) {
    Topic(params: $params) {
      _id
      value
      label
    }
  }
`

export const QUERY_GET_ALL_TOPICS = gql`
  query {
    Topics {
      _id
      value
      label
    }
  }
`

// export const QUERY_GET_ALL_TOPICS_FOR_AUTOCOMPLETE = gql`
//   query {
//     Topics {
//       id: _id
//       value: label
//     }
//   }
// `
