import React, { Fragment, useContext, useEffect, useState } from 'react'
import { 
  Grid, 
  Card, 
  Box, 
  makeStyles, 
  TextField, 
  Typography,
  FormControl,
  Select,
  Checkbox,
} from '@material-ui/core'
import { CheckBox, CheckBoxOutlineBlank } from '@material-ui/icons'
import { DateTimePicker } from '@material-ui/pickers'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { QuoteContext, QuoteContextType } from '../context/QuoteContext'
import { styles } from './styles'
import { useGlobal } from '@Hooks/useGlobal'
import Loading from '@Components/Loading'
import { useQuery } from '@apollo/client'
import { QUOTE } from '@Libs/const'
import moment from 'moment'

const useStyles = makeStyles(styles)

export default function Main() {
  const {
    handleChange,
    handleSelectChange,
    handleChangeStatus,
    dialog: [, setDialog],
    quote: [quote],
  } = useContext(QuoteContext) as QuoteContextType

  const { wording } = useGlobal()
  const classes = useStyles()

  const openDialog = () => {
    setDialog(true)
  }


  const listTopic: Array<string> = ["movinon", "self", "culture", "style", "conversation", "vdo"]
  //const listPages: Array<string> = ["home", "movinon", "self", "culture", "style", "conversation", "vdo"]
  // const listTopic: Array<{label: string, value: string}> = [
  //   { label: "MOVIN'ON", value: "movinon",},
  //   { label: "SELF", value: "self",},
  //   { label: "CULTURE", value: "culture",},
  //   { label: "STYLE", value: "style",},
  //   { label: "CONVERSATION", value: "conversation",},
  //   { label: "VOD", value: "vdo",},
  // ]
  const listPages: Array<{label: string, value: string}> = [
    { label: "HOME", value: "home",},
    { label: "MOVIN'ON", value: "movinon",},
    { label: "SELF", value: "self",},
    { label: "CULTURE", value: "culture",},
    { label: "STYLE", value: "style",},
    { label: "CONVERSATION", value: "conversation",},
    { label: "VOD", value: "vdo",},
  ]

  const [loading, setLoading] = useState<boolean>(true)
  const [statusSelect, setStatusSelected] = useState<String>("")
  const [topicSelected, setTopicSelected] = useState<String>("")
  const [pageSelected, setPageSelected] = useState<{label: string; value: string;}[]>([])

  const defaultChkbox = (chkValue: string) => {
    if(pageSelected.length > 0) {
      let resultObject = pageSelected.find((element) => element.value === chkValue)
      if(resultObject)  
            return true
      else  return false  
    }
    else return false
  } 

  useEffect(() => {
    console.log('load..')
    if(quote) {
      setLoading(false)
    
      setTopicSelected(quote.status)
      setTopicSelected(quote.categories)
      if(quote.show_in_page.length > 0) {
        let defaultPage = listPages.filter((ele) => quote.show_in_page.includes(ele.value))
        // console.log('defaultPage value ==>', defaultPage)
        setPageSelected(defaultPage)
      }
    }
  }, [quote]) //eslint-disable-line react-hooks/exhaustive-deps

  if(loading) return null

  return (
    <Fragment>
      <Box className={classes.mainContainer}>
        <div className={classes.mainItem}>
        <div className='input-text'>
          <TextField
            id="outlined-textarea"
            placeholder="Title"
            multiline
            value={quote?.title || ''}
            style={{
              fontSize: '30px',
              border: '0',
              outline: 'none',
              width: '100%',
            }}
            onChange={(ev) => {
              handleChange('title', ev.target.value)
            }}
          />
          </div>
          <p style={{ textAlign: 'right' }}>{`${quote?.title.length}/150`}</p>
        </div>
        <div className={classes.mainItem}>
          <TextField
            id="outlined-textarea"
            placeholder="Link URL"
            value={quote?.link_url || ''}
            fullWidth
            onChange={(ev) => {
              handleChange('link_url', ev.target.value)
            }}
          />
           <p style={{ textAlign: 'right' }}>{quote?.link_url ? `${quote.link_url.length}/350` : `0/350`}</p>
        </div>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Card className={classes.container}>
            <Box className={classes.box}>
              <Typography gutterBottom className='header-h3'>
                การเผยแพร่
              </Typography>

              <Grid container spacing={1} className={classes.spacingBottom}>
                <Grid item xs={3} sm={3} lg={2}>
                  <div className={classes.textLabel}>Status:</div>
                </Grid>
                <Grid item xs={9} sm={9} lg={10}>
                  <FormControl
                    variant="outlined"
                    size="small"
                    className={classes.select}
                  >
                    <Select
                      native
                      defaultValue={statusSelect}
                      value={quote.status}
                      onChange={(ev) => {
                        const status = `${ev.target.value}`
                        if (!ev.target.value) return
                        if (status === 'unknown') return
                        handleChangeStatus(status)
                        setStatusSelected(status)
                      }}
                    >
                      <option value={QUOTE.STATUS.DRAFT}>
                        {QUOTE.STATUS_LABEL.DRAFT}
                      </option>
                      <option value={QUOTE.STATUS.WAITING_TO_APPROVE}>
                        {QUOTE.STATUS_LABEL.WAITING_TO_APPROVE}
                      </option>
                      <option value={QUOTE.STATUS.APPROVED}>
                        {QUOTE.STATUS_LABEL.APPROVED}
                      </option>
                      <option value={QUOTE.STATUS.PUBLISH}>
                        {QUOTE.STATUS_LABEL.PUBLISH}
                      </option>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              <Grid container spacing={1} className={classes.spacingBottom}>
                <Grid item xs={3} sm={3} lg={2}>
                  <div className={classes.textLabel}>Publish: </div>
                </Grid>
                <Grid item xs={9} sm={9} lg={10}>
                  <TextField
                    fullWidth
                    id="standard-basic"
                    variant="outlined"
                    size="small"
                    value={
                      (quote.publish_time)
                        ? moment(quote.publish_time).format(
                            'DD/MM/YYYY HH:mm:ss',
                          )
                        : '-'
                    }
                    onClick={openDialog}
                    style={{ cursor: 'pointer' }}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={1} className={classes.spacingBottom}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Box className={classes.box}>
                    <Autocomplete
                      onChange={(_, values) => {
                        if (values) {
                          handleSelectChange(['categories'], [values])
                          setTopicSelected(values)
                        } else {
                          handleSelectChange(['categories'], [""])
                          setTopicSelected(listTopic[0])
                        }
                      }}
                      size="small"
                      options={listTopic}
                      defaultValue={quote?.categories ? quote.categories : null}
                      //getOptionLabel={(option) => option}
                      classes={{
                        paper: classes.menu_item,
                      }}
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" label={"TOPIC"} />
                      )}
                  />
                  </Box>
                </Grid>
              </Grid>
              
              <Grid container spacing={1} className={classes.spacingBottom}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Box className={classes.box}>
                    <Autocomplete
                      onChange={(_, values) => {
                        if (values) {
                          let arrayValue = Array.from(values.map((ele) => ele.value));
                          //console.log('arrayValue is ==>', arrayValue)
                          handleSelectChange(['show_in_page'], [arrayValue])
                          setPageSelected(values)
                        } else {
                          handleSelectChange(['show_in_page'], [])
                          setPageSelected([])
                        }
                      }}
                      multiple
                      options={listPages}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option.value}
                      getOptionSelected={(option, value) => option.value === value.value}
                      classes={{
                        paper: classes.menu_item,
                      }}
                      limitTags={5}
                      defaultValue={pageSelected}
                      renderOption={(option: { label: string; value: string }, { selected }) => (
                        <React.Fragment>
                          <Checkbox
                            icon={<CheckBoxOutlineBlank />}
                            checkedIcon={<CheckBox />}
                            checked={defaultChkbox(option.value)}
                            size="small"
                            style={{
                              height: 28,
                              width: 28,
                              marginRight: 8,
                            }}
                          />
                          {option.value}
                        </React.Fragment>
                      )}
                      renderInput={(params) => (
                        <TextField {...params} size="small" label={"PAGE"} />
                      )}
                    />
                  </Box>
                </Grid>
              </Grid>

            </Box>
          </Card>
        </Grid>

      </Box>
    </Fragment>
  )
}
