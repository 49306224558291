import { Box, SvgIcon, SvgIconProps } from '@material-ui/core'
import React, { ReactElement } from 'react'
interface Props extends SvgIconProps {}
export default function MirrorIcon(props: Props): ReactElement {
  return (
    <>
      <Box className='logo-mirror'>
          <svg viewBox="0 0 304.036 60">
            <g data-name="Group 41950" transform="translate(-111.001 -120)">
              <path data-name="Path 8070" d="M592.889,178.273V120H600.8l26.139,51.2.833,3.579h1l.915-3.579L655.907,120h7.909v58.273h-4.745V123.5h-1l-1.415,5.577-25.308,49.2h-6.077l-25.307-49.2L598.55,123.5h-.916v54.777Z" transform="translate(-481.889)" fill="#fff"/>
              <rect data-name="Rectangle 7220" width="4.745" height="58.273" transform="translate(199.989 120)" fill="#fff"/>
              <path data-name="Path 8071" d="M726.165,151.967l2,1.166,22.227,25.14h-6.576l-23.559-27.055H709.515v27.055H704.77V120h26.972c8.491,0,14.069,2.664,16.733,7.409a15.316,15.316,0,0,1,2,8.074,16.406,16.406,0,0,1-1.915,8.158c-2.747,4.83-8.741,7.327-17.4,7.327h-4.994Zm-16.65-27.555v22.56h21.728c6.576,0,10.738-1.748,12.82-5.244a12.506,12.506,0,0,0,1.5-6.161,11.712,11.712,0,0,0-1.415-5.91c-2.081-3.5-6.16-5.245-12.737-5.245Z" transform="translate(-481.889)" fill="#fff"/>
              <path data-name="Path 8072" d="M801.262,120.943l-3.454,10.13h-1.036a6.055,6.055,0,0,0-4.029-1.5c-5.871,0-8.058,2.417-24.4,35.112l-3.569,13.584h-7.253l12.664-47.66c1.151-4.489-.576-6.217-5.756-5.411l-2.3.345.23-1.151,17.614-4.258-10.246,39.371c15.656-33.615,21.528-39.371,27.859-39.371a9.3,9.3,0,0,1,3.684.805" transform="translate(-481.889)" fill="#fff"/>
              <path data-name="Path 8073" d="M796.887,163.192c0-15.771,13.354-43.054,33.73-43.054,7.828,0,16.462,4.028,16.462,16.807,0,15.771-13.354,43.055-33.73,43.055-7.828,0-16.462-4.03-16.462-16.808m41.558-27.283c0-11.628-4.029-14.045-8.519-14.045-13.929,0-24.405,23.485-24.405,42.364,0,11.627,4.029,14.045,8.519,14.045,13.929,0,24.405-23.485,24.405-42.364" transform="translate(-481.889)" fill="#fff"/>
              <path data-name="Path 8074" d="M896.925,120.943l-3.454,10.13h-1.036a6.055,6.055,0,0,0-4.029-1.5c-5.871,0-8.058,2.417-24.405,35.112l-3.569,13.584h-7.253l12.664-47.66c1.151-4.489-.576-6.217-5.756-5.411l-2.3.345.231-1.151,17.613-4.258-10.246,39.371c15.656-33.615,21.528-39.371,27.859-39.371a9.3,9.3,0,0,1,3.684.805" transform="translate(-481.889)" fill="#fff"/>
            </g>
          </svg>
      </Box>
    </>
  )
}
