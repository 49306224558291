import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  makeStyles,
} from '@material-ui/core'
import { ReactElement, useContext, useState } from 'react'
import { ContentContext, ContentContextType } from '../context/ContentContext'
import { styles } from '@Components/Upload/styles'
import { styles as dialog_styles } from '../Detail/styles'
import { Add, Delete, PermMedia } from '@material-ui/icons'
import clsx from 'clsx'

const useStyles = makeStyles(styles)
const useDialogStyles = makeStyles(dialog_styles)
export default function Gallery(): ReactElement {
  const classes = useStyles()
  const dialogClasses = useDialogStyles()
  const {
    handleChange,
    content: [content],
  } = useContext(ContentContext) as ContentContextType
  const [uploadedMulti, setUploadedMulti] = useState<string[]>(
    content.gallery || [],
  )
  const [selected, setSelected] = useState<string[]>([])
  const [selectedDelete, setSelectedDelete] = useState<string[]>([])
  const [open, setOpen] = useState(false)
  const [openDelete, setOpenDelete] = useState(false)
  // const onUploadedGalleryMulti = (images: string[]) => {
  //   const newArr = [...uploadedMulti, ...images]
  //   const newImages = [...content.images, ...images]
  //   setUploadedMulti(newArr)
  //   handleChange(['gallery', 'images'], [newArr, newImages])
  // }

  const onConfirmDelete = () => {
    if (selectedDelete.length === 0) {
      setOpenDelete(false)
      return
    }
    if (uploadedMulti.length > 0) {
      const newUploaded = uploadedMulti.filter((item: any, i: number) => {
        return selectedDelete.findIndex((item2) => item2 === item) === -1
      })
      handleChange('gallery', newUploaded)
      setUploadedMulti(newUploaded)
      setSelectedDelete([])
      setOpenDelete(false)
    }
  }
  const handleSelected = (i: string) => {
    const is_exists = selected.findIndex((item) => item === i) !== -1
    if (is_exists) {
      setSelected((prev) => prev.filter((item) => item !== i))
    } else {
      setSelected((prev) => [...prev, i])
    }
  }
  const handleSelectedDelete = (i: string) => {
    const is_exists = selectedDelete.findIndex((item) => item === i) !== -1
    if (is_exists) {
      setSelectedDelete((prev) => prev.filter((item) => item !== i))
    } else {
      setSelectedDelete((prev) => [...prev, i])
    }
  }

  const handleConfirmSelected = () => {
    if (selected.length === 0) {
      setOpen(false)
      return
    }
    const imageSelected = content.images.filter((item: any, i: number) => {
      return selected.findIndex((item2) => item2 === item) !== -1
    })

    const newArr = [...uploadedMulti, ...imageSelected]

    setUploadedMulti(newArr)
    handleChange('gallery', newArr)
    setSelected([])
    setOpen(false)
  }

  return (
    <>
      {content?.id && (
        <>
          <div>
            <Button
              onClick={() => setOpen(true)}
              color="primary"
              size="small"
              startIcon={<PermMedia />}
            >
              เลือกจากคลังรูปภาพ
            </Button>
            <Button
              onClick={() => setOpenDelete(true)}
              size="small"
              startIcon={
                <Delete
                  color={selectedDelete.length <= 0 ? 'disabled' : 'error'}
                />
              }
              color="secondary"
              disabled={selectedDelete.length <= 0}
            >
              ลบรูปภาพ
            </Button>
          </div>
        </>
      )}
      {/* --- */}
      <Grid container className={classes.image_list} spacing={1}>
        <>
          {uploadedMulti &&
            uploadedMulti.map((item: string, index: number) => (
              <>
                <Grid key={`${item}_${index}`} item>
                  <div className={classes.image_wrapper}>
                    <img
                      src={item}
                      className={clsx(
                        classes.image,
                        selectedDelete.findIndex((item2) => item2 === item) !==
                          -1
                          ? classes.image_selected
                          : classes.image_before_selected,
                      )}
                      onClick={() => handleSelectedDelete(item)}
                      alt={item}
                      key={item}
                    />
                  </div>
                </Grid>
              </>
            ))}
        </>
      </Grid>
      {/* start ---- dialog gallery */}
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>คลังรูปภาพ</DialogTitle>
        <DialogContent>
          <Grid container className={dialogClasses.image_list} spacing={1}>
            <>
              {content.images &&
                content.images
                  .filter((item) => uploadedMulti.indexOf(item) === -1)
                  .map((item: string, index: number) => (
                    <>
                      <Grid key={`${item}_${index}`} item>
                        <div className={dialogClasses.image_wrapper}>
                          <img
                            src={item}
                            draggable={false}
                            className={clsx(
                              dialogClasses.image,
                              selected.findIndex((item2) => item2 === item) !==
                                -1
                                ? dialogClasses.image_selected
                                : dialogClasses.image_before_selected,
                            )}
                            alt={item}
                            key={item}
                            onClick={() => handleSelected(item)}
                          />
                        </div>
                      </Grid>
                    </>
                  ))}
            </>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleConfirmSelected}
            classes={{
              text: dialogClasses.button_select,
            }}
            color="primary"
            startIcon={<Add color="primary" />}
          >
            เพิ่ม
          </Button>
          <Button
            onClick={() => setOpen(false)}
            classes={{
              text: dialogClasses.button_select,
            }}
          >
            ปิด
          </Button>
        </DialogActions>
      </Dialog>
      {/* end ---- dialog gallery */}

      {/* start ---- dialog delete */}
      <Dialog open={openDelete} onClose={() => setOpenDelete(false)}>
        <DialogTitle>ต้องการลบรูปภาพที่เลือกไว้ ?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            จำนวน {selectedDelete.length} รูปภาพ
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={onConfirmDelete}
            autoFocus
            startIcon={<Delete color="error" />}
            color="secondary"
            // variant="outlined"
          >
            ยืนยัน
          </Button>
          <Button onClick={() => setOpenDelete(false)} color="primary">
            ยกเลิก
          </Button>
        </DialogActions>
      </Dialog>
      {/* end ---- dialog delete */}
    </>
  )
}
