export enum STATUS {
    DRAFT               = '00',
    WAITING_TO_APPROVE  = '10',
    APPROVED            = '20',
    PUBLISH             = '30',
}
  
export enum STATUS_LABEL {
    DRAFT               = 'Draft',
    WAITING_TO_APPROVE  = 'Waiting To Approve',
    APPROVED            = 'Approved',
    PUBLISH             = 'Publish',
}

export const STATUS_MAPPING = {
    [STATUS.DRAFT.toString()]: STATUS_LABEL.DRAFT,
    [STATUS.WAITING_TO_APPROVE.toString()]: STATUS_LABEL.WAITING_TO_APPROVE,
    [STATUS.APPROVED.toString()]: STATUS_LABEL.APPROVED,
    [STATUS.PUBLISH.toString()]: STATUS_LABEL.PUBLISH,
}
  
/* premium_type */
export enum PREMIUM_TYPE {
    FREE            = '00',
    MEMBERSHIP      = '10',
    SUBSCRIPTION    = '20',
}
export enum PREMIUM_TYPE_LABEL {
    FREE            = 'Free',
    MEMBERSHIP      = 'Membership',
    SUBSCRIPTION    = 'Subscription',
}
export const PREMIUM_TYPE_MAPPING = {
    [PREMIUM_TYPE.FREE.toString()]: PREMIUM_TYPE_LABEL.FREE,
    [PREMIUM_TYPE.MEMBERSHIP.toString()]: PREMIUM_TYPE_LABEL.MEMBERSHIP,
    [PREMIUM_TYPE.SUBSCRIPTION.toString()]: PREMIUM_TYPE_LABEL.SUBSCRIPTION,
}
/* end premium_type */
  