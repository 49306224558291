import React, {
  Fragment,
  useContext,
  useState,
  useEffect,
  useMemo,
} from 'react'
import Detail from './Detail/index'
import Images from './Images'
import Setting from './Detail/setting'
import History from './History'
// import { checkPermission } from "@Components/checkPermission"
import { AuthContextType, useAuth } from '@Hooks/useAuth'

import {
  Tabs,
  Tab,
  Breadcrumbs,
  Link,
  Button,
  //CircularProgress,
  Divider,
  Avatar,
} from '@material-ui/core'
import {
  createStyles,
  makeStyles,
  Theme,
  withStyles,
} from '@material-ui/core/styles'
import {
  VideoContentContext,
  VideoContentContextType,
  IUserWriting,
  //StatusSaveType,
} from './context/VideoContext'
//import CheckIcon from '@material-ui/icons/Check'
import clsx from 'clsx'
//import { Close } from '@material-ui/icons'
import PublishDialog from './Widget/PublishDialog'

import { CONTENT } from '@Libs/const'
import { AvatarGroup } from '@material-ui/lab'
import Related from './Related'
import { ThemeName } from '../../theme'
import { useGlobal } from '@Hooks/useGlobal'
import isEmpty from 'lodash.isempty'
// import { useQuery } from '@apollo/client'
// import { QUERY_GET_TOPIC } from '@Api/topic_api'
interface StyledTabProps {
  label?: string
  disabled?: boolean
  chlidren?: React.ReactNode
}
type PageType = {
  label: string
  component: React.ReactNode
  themes?: ThemeName[]
}
/**
 * themes empty is all theme
 */
const pages_all: PageType[] = [
  {
    label: 'เขียนเนื้อหา',
    component: <Detail />,
  },
  {
    label: 'ตั้งค่า',
    component: <Setting />,
  },
  {
    label: 'รูปภาพ',
    component: <Images />,
  },
  {
    label: 'ประวัติการเผยแพร่',
    component: <History />,
  },
  {
    label: 'เนื้อหาที่เกี่ยวข้อง',
    component: <Related />,
    themes: ['mirror'],
  },
]

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  demo1: {
    backgroundColor: theme.palette.background.paper,
  },
  padding: {
    padding: theme.spacing(3),
  },
  grow: {
    flexGrow: 1,
  },
  header_container: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  status_wrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  divider: {
    marginInline: theme.spacing(1),
  },
  saveBtn: {
    background: theme.palette.success.main,
    color: '#fff',
    '&:hover': {
      background: '#439c46',
    },
    margin: theme.spacing(1),
    '&:disabled': {
      cursor: 'not-allowed',
      pointerEvents: 'all !important',
    },
  },
  disabled: {
    // position: 'relative',
    // '&::before': {
    //   position: 'absolute',
    //   top: 0,
    //   left: 0,
    //   content: '""',
    //   width: '100%',
    //   height: '100%',
    //   background: 'rgba(255,255,255,0.6)',
    //   zIndex: 9999,
    //   cursor: 'not-allowed',
    // },
  },
}))
const ContentEdit = () => {
  const {
    statusSave,
    dialog: [, setDialog],
    content: [content],
    userWriting,
    isDisabled,
  } = useContext(VideoContentContext) as VideoContentContextType
  const [tabNow, setTabNow] = useState(0)
  const classes = useStyles()
  const { theme_name } = useGlobal()
  const { checkPermission } = useAuth() as AuthContextType
  const [ts, setTs] = useState(Date.now())
  const pages = useMemo(
    () =>
      pages_all.filter(
        (page) =>
          isEmpty(page.themes) || page.themes?.indexOf(theme_name) !== -1,
      ),
    [theme_name],
  )
  useEffect(() => {
    const checked = checkPermission('content_edit')
    if (!checked) {
      window.location.href = '/'
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabNow(newValue)
  }

  const openDialog = () => {
    setDialog(true)
  }
  const generateUrl = () => {
    const base_url = process.env.REACT_APP_TR_PLUS
    const topic = content?.topic[0]?.value || content?.topic[0]
    if (theme_name === 'thairath-plus') {
      return `${base_url}/video/${content?.id}`
    } else if (theme_name === 'mirror') {
      return `${base_url}/${topic}/${content?.id}`
    } else return base_url
  }
  // const getStatus = (status: StatusSaveType): React.ReactNode => {
  //   if (status.loading) {
  //     return <CircularProgress size="24px" style={{ color: '#ff9a00' }} />
  //   }
  //   if (status.status_code === 200) {
  //     return <CheckIcon style={{ color: 'green', fontSize: '28px' }} />
  //   } else {
  //     return <Close fontSize="default" style={{ color: 'red' }} />
  //   }
  // }

  return (
    <Fragment>
      <div className={classes.root}>
        <div className={classes.header_container}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="none" color="inherit">
              Video
            </Link>  
            <Link underline="none" color="inherit">
              {content?.id ? 'Edit VideoContent' : 'Add VideoContent'}
            </Link>
          </Breadcrumbs>
          {/* <Divider
            className={classes.divider}
            orientation="vertical"
            flexItem
          />
          <div className={classes.status_wrapper}>{getStatus(statusSave)}</div> */}

          <>
            <Divider
              className={classes.divider}
              orientation="vertical"
              flexItem
            />
            {checkPermission('content_publish_edit') && (
              <Fragment>
                <Button
                  variant="outlined"
                  size="medium"
                  className={clsx(
                    content?.status !== CONTENT.STATUS.PUBLISH &&
                      classes.saveBtn,
                    // statusSave.loading && classes.cursor_not_allowed,
                  )}
                  onClick={openDialog}
                  disabled={statusSave.loading}
                >
                  Publish
                </Button>
                <Divider
                  className={classes.divider}
                  orientation="vertical"
                  flexItem
                />
              </Fragment>
            )}

            {false ? (
              <></>
            ) : (
              <>
                <Button
                  variant="outlined"
                  size="medium"
                  // onClick={openDialog}
                >
                  <a
                    href={`${generateUrl()}?token=${content?.token}`}
                    target="_blank"
                    style={{
                      textDecoration: 'none',
                      color: 'rgba(0, 0, 0, 0.87)',
                    }}
                    rel="noreferrer"
                  >
                    Draft Preview
                  </a>
                </Button>

                <Button
                  variant="outlined"
                  size="medium"
                  // onClick={openDialog}
                  style={{ margin: ' 0px 5px' }}
                >
                  <a
                    href={`${generateUrl()}?ts=${ts}`}
                    target="_blank"
                    style={{
                      textDecoration: 'none',
                      color: 'rgba(0, 0, 0, 0.87)',
                    }}
                    rel="noreferrer"
                    onClick={() => setTs(Date.now())}
                  >
                    Link To Web
                  </a>
                </Button>
              </>
            )}
          </>
          <div className={classes.grow} />
          <AvatarGroup>
            {userWriting &&
              userWriting.map((item: IUserWriting, index: number) => (
                <Avatar
                  key={`${item.user._id}${index}`}
                  alt={item.user.first_name + ' ' + item.user.last_name}
                  title={item.user.first_name + ' ' + item.user.last_name}
                  src={
                    item.user.profile_image != null
                      ? item.user.profile_image + '?width=36'
                      : ''
                  }
                  imgProps={{
                    title: item.user.first_name + ' ' + item.user.last_name,
                  }}
                >
                  {item.user.first_name.charAt(0).toUpperCase()}
                </Avatar>
              ))}
          </AvatarGroup>
        </div>
        <div className={classes.demo1}>
          <AntTabs
            onChange={handleChange}
            aria-label="ant example"
            value={tabNow}
          >
            {pages.map((page, index) => (
              <AntTab key={index} label={page.label} />
            ))}
          </AntTabs>
          <div className={clsx(isDisabled && classes.disabled)}>
            <div className={classes.padding}>{pages[tabNow].component}</div>
          </div>
        </div>
      </div>
      <PublishDialog />
    </Fragment>
  )
}

const AntTabs = withStyles({
  root: {
    borderBottom: '1px solid #e8e8e8',
    position: 'relative',
  },
  indicator: {
    backgroundColor: '#000000',
  },
})(Tabs)

const AntTab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      textTransform: 'none',
      minWidth: 72,
      marginRight: theme.spacing(3),
      '&:hover': {
        color: '#000',
        opacity: 1,
      },
      '&$selected': {
        color: '#000000',
        fontWeight: theme.typography.fontWeightMedium,
      },
      '&:focus': {
        color: '#000',
      },
    },
    selected: {},
  }),
)((props: StyledTabProps) => (
  <Tab disableRipple {...props}>
    {props.chlidren}
  </Tab>
))

export default ContentEdit
