import { createTheme, CssBaseline, ThemeProvider } from '@material-ui/core'
import { AuthContextProvider } from './hooks/useAuth'
import { useGlobal } from './hooks/useGlobal'
import { ApolloProvider } from '@apollo/client'
import api from './api/api'
import Pages from './pages'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import getTheme from './theme'
import { SnackbarContextProvider } from '@Hooks/useSnackbar'

function App() {
  const { theme_name } = useGlobal()
  return (
    <ThemeProvider theme={createTheme(getTheme(theme_name))}>
      <CssBaseline />
      <ApolloProvider client={api.graphql}>
        <AuthContextProvider>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <SnackbarContextProvider>
              <Pages />
            </SnackbarContextProvider>
          </MuiPickersUtilsProvider>
        </AuthContextProvider>
      </ApolloProvider>
    </ThemeProvider>
  )
}
export default App
