import { gql } from '@apollo/client'
export const QUERY_GET_WEB_CONFIG = gql`
  query getCmsWebConfig {
    CmsWebConfig {
      hide_product_bar
    }
  }
`

export const MUTATION_EDIT_WEB_CONFIG = gql`
  mutation editWebConfig($hide_product_bar: Boolean) {
    EditWebConfig(hide_product_bar: $hide_product_bar) {
      statusCode
      message
    }
  }
`
