import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  Paper,
  Select,
  TextField,
  Typography,
  List,
  Checkbox,
  Table,
  TableBody,
  TableRow,
  TableHead,
  TableCell,
} from '@material-ui/core'
import SaveIcon from '@material-ui/icons/Save'
import React, { ReactElement, useState, useEffect, Fragment } from 'react'
// import { roleType } from '@Types/role_type'
import { userType } from '@Types/user_type'
import { styles } from './form.style'
// import { QUERY_GET_ALL_ROLES } from '@Api/role_api'
// import { useQuery } from '@apollo/client'
// import Loading from '../Loading'
import clsx from 'clsx'
// import { checkPermission } from '../checkPermission'
import { AuthContextType, useAuth } from '@Hooks/useAuth'
import { useGlobal } from '@Hooks/useGlobal'
import default_roles from '@Libs/const/role'
// import {
//   permission_rules as rules,
//   getPermissionByRole,
// } from '@Libs/const/role'

interface FormProps {
  title: string
  bypass: boolean
  onSubmit: (data: userType) => void
  defaultData?: userType
  avatar?: () => React.ReactNode
  display?: {
    roles?: boolean
    username?: boolean
    password?: boolean
    is_active?: boolean
  }
}
const useStyles = makeStyles(styles)

const default_display = {
  roles: true,
  username: true,
  password: true,
  is_active: true,
  role_name: true,
}
export default function Form({
  title,
  bypass,
  onSubmit,
  defaultData,
  avatar,
  display: display_props,
}: FormProps): ReactElement {
  const classes = useStyles()
  const [errorMessage, setErrorMessage] = useState<{
    [key: string]: string
  }>({})
  const { theme_name } = useGlobal()
  // const { data, loading } = useQuery(QUERY_GET_ALL_ROLES)
  //Permission

  const [roles, setRoles] = useState<string | undefined>(defaultData?.role_name)
  const { checkPermission } = useAuth() as AuthContextType

  // const [permission, setPermission] = useState<string[] | undefined>(
  //   defaultData?.roles,
  // )
  const [permissionData, setPermissionData] = useState<string[] | undefined>(
    defaultData?.roles,
  )

  const [display] = useState({
    ...default_display,
    ...display_props,
  })

  const handleChangeRoles = (event: any) => {
    const value = event.target.value as string
    if (!value) return
    setRoles(value)
    setPermissionData(default_roles[theme_name].getPermissionByRole(value))
  }

  const setPermissionBox = (permission_name: string) => {
    if (!permissionData) return false
    if (permissionData.length === 0) return false
    const even = (element: string) => element === permission_name
    return permissionData.some(even)
  }

  const setStatePermission = (event: any) => {
    const { name, checked } = event.target
    if (!permissionData) return
    const has_data = permissionData.indexOf(name) !== -1
    if (checked) {
      if (!has_data) {
        const newArr = [...permissionData, name]
        // setPermission(newArr)
        setPermissionData(newArr)
      }
    } else {
      if (has_data) {
        const newArr = permissionData.filter((f) => f !== name)
        // setPermission(newArr)
        setPermissionData(newArr)
      }
    }
  }

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault()
    const target = e.target as typeof e.target & {
      first_name: { value: string }
      last_name: { value: string }
      nick_name: { value: string }
      email: { value: string }
      bank_account: { value: string }
      telephone: { value: string }
      username: { value: string }
      password: { value: string }
      team: { value: string }
      is_active: { value: string }
      address: { value: string }
      description: { value: string }
    }
    const obj = {
      first_name: target.first_name.value,
      last_name: target.last_name.value,
      nick_name: target.nick_name.value,
      email: target.email.value,
      bank_account: target.bank_account.value,
      telephone: target.telephone.value,
      username: target.username.value,
      password: target.password.value,
      team: target.team.value,
      is_active: target.is_active.value,
      address: target.address.value,
      roles: permissionData,
      role_name: roles,
      description: target.description.value,
    } as userType

    // console.log('obj', obj.roles)

    if (!display.roles) delete obj.roles
    if (!display.is_active) delete obj.is_active
    if (!display.username) delete obj.username
    if (!display.password) delete obj.password

    if (display.password && !obj.password) {
      setErrorMessage({
        password: 'กรุณากรอก password',
      })
      return
    } else {
      onSubmit(obj)
    }
  }
  useEffect(() => {
    if (!bypass) {
      const checked = checkPermission('admin_edit')
      if (!checked) {
        window.location.href = '/'
      }
    }
  }, []) //eslint-disable-line react-hooks/exhaustive-deps
  return (
    <>
      <Paper className={classes.root}>
        <form onSubmit={handleSubmit}>
          <Box display="flex" alignItems="center">
            <Typography variant="h6">{title}</Typography>
            <div className={classes.grow} />
            <Button
              // className={classes.button_save}
              className='btn-save'
              startIcon={<SaveIcon />}
              variant="contained"
              type="submit"
              color="primary"
            >
              บันทึกข้อมูล
            </Button>
          </Box>
          <Divider className={classes.divider} />
          {avatar && (
            <Grid container justify="center" spacing={2}>
              <Grid item>{avatar()}</Grid>
            </Grid>
          )}
          <Grid container spacing={2}>
            {/* <Grid item xs={6} className={classes.section}> */}
            <Grid item lg={4} md={6} xs={12}>
              <TextField
                name="first_name"
                label="First Name"
                size="small"
                // variant="outlined"
                fullWidth
                defaultValue={defaultData?.first_name}
              />
            </Grid>
            <Grid item lg={4} md={6} xs={12}>
              <TextField
                name="last_name"
                label="Last Name"
                size="small"
                // variant="outlined"
                fullWidth
                defaultValue={defaultData?.last_name}
              />
            </Grid>
            <Grid item lg={4} md={6} xs={12}>
              <TextField
                name="nick_name"
                label="Nick Name"
                size="small"
                // variant="outlined"
                fullWidth
                defaultValue={defaultData?.nick_name}
              />
            </Grid>
            <Grid
              item
              lg={4}
              md={6}
              xs={12}
              className={clsx(!display.username && classes.none)}
            >
              <TextField
                name="username"
                label="Username"
                size="small"
                // variant="outlined"
                fullWidth
                defaultValue={defaultData?.username}
              />
            </Grid>
            <Grid
              item
              lg={4}
              md={6}
              xs={12}
              className={clsx(!display.password && classes.none)}
            >
              <TextField
                name="password"
                label="Password"
                type="password"
                size="small"
                // variant="outlined"
                fullWidth
                defaultValue={defaultData?.password}
                helperText={errorMessage?.password}
                error={!!errorMessage?.password}
              />
            </Grid>
            <Grid item lg={4} md={6} xs={12}>
              <TextField
                name="email"
                label="Email"
                size="small"
                // variant="outlined"
                fullWidth
                defaultValue={defaultData?.email}
              />
            </Grid>
            <Grid item lg={4} md={6} xs={12}>
              <TextField
                name="telephone"
                label="Telephone"
                size="small"
                // variant="outlined"
                fullWidth
                defaultValue={defaultData?.telephone}
              />
            </Grid>
            <Grid item lg={4} md={6} xs={12}>
              <TextField
                name="bank_account"
                label="Bank Account"
                size="small"
                // variant="outlined"
                fullWidth
                defaultValue={defaultData?.bank_account}
              />
            </Grid>

            <Grid item lg={4} md={6} xs={12}>
              <FormControl
                // variant="outlined"
                className={classes.formControl}
                size="small"
              >
                <InputLabel>โต๊ะข่าว</InputLabel>
                <Select
                  // value={age}
                  // onChange={handleChange}
                  defaultValue={defaultData?.team || 0}
                  label="team"
                  name="team"
                  className={classes.formControl}
                  native
                >
                  <option value={1}>SEO</option>
                  <option value={2}>การตลาด</option>
                  <option value={3}>ไทยรัฐพลัส</option>
                  <option value={0}>อื่นๆ</option>
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              lg={4}
              md={6}
              xs={12}
              className={clsx(!display.is_active && classes.none)}
            >
              <FormControl
                // variant="outlined"
                className={classes.formControl}
                size="small"
              >
                <InputLabel>สถานะผู้ใช้งาน</InputLabel>
                <Select
                  // value={age}
                  // onChange={handleChange}
                  defaultValue={defaultData?.is_active || 1}
                  label="สถานะผู้ใช้งาน"
                  name="is_active"
                  native
                >
                  <option value={1}>แสดงผล</option>
                  <option value={0}>ไม่แสดง</option>
                </Select>
              </FormControl>
            </Grid>
            <Grid item lg={4} md={6} xs={12}>
              <TextField
                name="address"
                label="Address"
                size="small"
                // variant="outlined"
                fullWidth
                defaultValue={defaultData?.address}
                multiline
                // rows={4}
              />
            </Grid>
            <Grid item lg={4} md={6} xs={12}>
              <TextField
                name="description"
                label="Description"
                size="small"
                // variant="outlined"
                fullWidth
                defaultValue={defaultData?.description}
                multiline
                // rows={4}
              />
            </Grid>
          </Grid>
          <br />
          <Grid
            container
            justify="center"
            spacing={2}
            className={clsx(!display.roles && classes.none)}
          >
            <Grid item xs={12} md={8} lg={12}>
              <List dense={true} className={classes.list}>
                <Typography align="center" variant="h6">
                  กำหนดสิทธิ์การเข้าใช้งาน
                </Typography>
                <Divider className={classes.divider} />
                {/* {loading ? (
                  <Loading />
                ) : ( */}
                <>
                  <FormControl
                    // variant="outlined"
                    className={classes.formControl}
                    size="small"
                  >
                    {/* <InputLabel>โต๊ะข่าว</InputLabel> */}
                    <Select
                      // value={age}
                      // onChange={handleChange}
                      autoWidth
                      placeholder=""
                      value={roles ?? ''}
                      // defaultValue={defaultData?.role_name ?? ''}
                      className={classes.select_role}
                      // className={classes.formControl}
                      onChange={handleChangeRoles}
                      native
                    >
                      <option value="Editor">Editor</option>
                      <option value="Editor Manager">Editor Manager</option>
                      <option value="Product">Product</option>
                      <option value="Admin">Admin</option>
                      <option value="SEO">SEO</option>
                      <option value="Custom">Custom</option>
                    </Select>
                  </FormControl>
                  <Table size="small">
                    {default_roles[theme_name].rules.map((rule, index) => (
                      <Fragment key={`${rule.name}_${index}`}>
                        <TableHead className={classes.table_head}>
                          <TableRow>
                            <TableCell
                              style={{ fontWeight: 'bold' }}
                              align="left"
                            >
                              {rule.name}
                            </TableCell>
                            <TableCell
                              style={{ fontWeight: 'bold' }}
                              align="center"
                            >
                              View
                            </TableCell>
                            <TableCell
                              style={{ fontWeight: 'bold' }}
                              align="center"
                            >
                              Edit
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {rule.group.map((sub) => (
                            <TableRow key={sub.name} hover>
                              <TableCell className={classes.cell}>
                                {sub.name}
                              </TableCell>
                              <TableCell size="small" align="center">
                                {sub.type.view && (
                                  <Checkbox
                                    id={`${sub.key}_view`}
                                    key={`${sub.key}_view`}
                                    name={`${sub.key}_view`}
                                    size="small"
                                    onClick={setStatePermission}
                                    checked={setPermissionBox(
                                      `${sub.key}_view`,
                                    )}
                                  />
                                )}
                              </TableCell>
                              <TableCell align="center">
                                {sub.type.edit && (
                                  <Checkbox
                                    id={`${sub.key}_edit`}
                                    key={`${sub.key}_edit`}
                                    name={`${sub.key}_edit`}
                                    size="small"
                                    onClick={setStatePermission}
                                    checked={setPermissionBox(
                                      `${sub.key}_edit`,
                                    )}
                                  />
                                )}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Fragment>
                    ))}
                  </Table>
                </>
                {/* )} */}
              </List>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </>
  )
}
