import { Theme } from '@material-ui/core'
import { Styles } from '@material-ui/styles'

export const styles: Styles<Theme, {}, string> = (theme: Theme) => ({
  box:{
    padding:'20px',
  },
  element: {
    border: '1px dashed #ddd',
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  editor: {
    paddingBottom: '10px',
  },
  popover_container: {
    border: '1px solid #ddd',
    padding: '15px',
  },
  popover_icon: {
    padding: theme.spacing(1),
  },
  image_list: {
    backgroundColor: '#fff',
    height: '130px',
    width: '130px',
    border: '1px solid #ddd',
    borderRadius: '4px',
    padding: '5px',
  },
  image_list_select: {
    backgroundColor: '#fff',
    height: '130px',
    width: '130px',
    border: '2px solid #00b0ff',
    borderRadius: '4px',
    padding: '5px',
  },
  popover_items: {
    border: '1px solid #fff',
    display: 'flex',
    padding: '8px 13px',
    // border: "1px solid #ddd",
    color: '#1e1e1e',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    // margin:theme.spacing(1),
    cursor: 'pointer',
    width: '100%',
    height: '80px',
    justifyCntent: 'center',
    '&:hover': {
      border: '1px solid #ddd',
      borderRadius: '4px',
    },
    bagde: {
      cursor: 'pointer',
      position: 'relative',
      bottom: '124px',
      right: '-128px',
    },
  },
})
