import { Box, Chip, ListItemSecondaryAction } from '@material-ui/core'
import React from 'react'
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'
import VisibilityIcon from '@material-ui/icons/Visibility'
import { IVideo } from '@Types/video_type'
import isEmpty from 'lodash.isempty'
import moment from 'moment'
import clsx from 'clsx'
//import { IVideoLists } from '@Types/video_list_type'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    img: {
      width: theme.spacing(13),
      height: theme.spacing(7),
    },
    content_img: {
      width: theme.spacing(13),
      height: theme.spacing(7),
    },
    title: {
      paddingLeft: theme.spacing(1),
    },
    sub_title: {
      textIndent: theme.spacing(2),
      display: '-webkit-box',
      boxOrient: 'vertical',
      lineClamp: 2,
      wordBreak: 'break-all',
      overflow: 'hidden',
    },
    content_sub_title: {
      textIndent: theme.spacing(2),
      display: '-webkit-box',
      boxOrient: 'vertical',
      lineClamp: 1,
      wordBreak: 'break-all',
      overflow: 'hidden',
    },
    count_view: {
      fontSize: theme.spacing(1.8),
      display: 'flex',
      alignItems: 'center',
    },
    icon_view: {
      fontSize: theme.spacing(2),
      marginRight: theme.spacing(1),
    },
    list_item: {
      border: '1px solid #dfdfdf',
      borderRadius: '3px',
      marginBottom: theme.spacing(2),
      boxShadow: '2px 2px 4px #e6e6e6',
      background: 'white',
    },
    link: {
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    content_type_expire_time: {
      fontSize: theme.spacing(1.8),
      display: 'flex',
      alignItems: 'center',
    },
    chip: {
      textTransform: 'capitalize',
    },
    is_content_type_expire_time: {
      content: '',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: theme.palette.grey[100],
      opacity: 0.85,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& span': {
        fontWeight: 'bold',
        marginRight: theme.spacing(0.5),
      },
      '& a': {
        fontWeight: 'bold',
        // textDecoration: 'none',
        fontSize: theme.typography.subtitle1,
        color: '#0000ff',
      },
    },
    none: {
      display: 'none',
    },
  }),
)
interface Props {
  video: IVideo
  action?: () => React.ReactNode
}

const CardVideo: React.FC<Props> = ({ video, action }: Props) => {
  const classes = useStyles()
  const isContentTypeExpire = video.content_type_expire_time
    ? Date.now() > +new Date(video.content_type_expire_time)
    : false
  return (
    <ListItem className={classes.list_item} alignItems="flex-start">
      <ListItemAvatar>
        <Avatar
          variant="rounded"
          src={!isEmpty(video?.cover) ? video?.cover : undefined}
          className={classes.img}
          imgProps={{
            draggable: false,
          }}
        />
      </ListItemAvatar>
      <ListItemText
        className={classes.title}
        primary={
          <React.Fragment>
            <a
              href={`/video/edit/${video?.id}`}
              target="_blank"
              rel="noreferrer"
              className={classes.link}
            >
              {video?.id} - {video?.title}
            </a>
          </React.Fragment>
        }
        primaryTypographyProps={{
          noWrap: true,
        }}
        secondary={
          <React.Fragment>
            <Typography
              variant="body2"
              className={classes.sub_title}
              color="textSecondary"
              component="div"
            >
              {video?.summary}
            </Typography>
            <Box paddingTop={0.5} display={'flex'} gridGap={'0.5rem'}>
              <Typography
                variant="subtitle2"
                className={classes.count_view}
                color="primary"
                component="div"
                noWrap
              >
                <VisibilityIcon className={classes.icon_view} color="primary" />
                {video?.count_view?.toLocaleString()}
              </Typography>
              <Chip
                className={classes.chip}
                size="small"
                label={video.content_type}
              />
              {video?.content_type_expire_time && (
                <Typography
                  variant="subtitle2"
                  className={classes.content_type_expire_time}
                  color="secondary"
                  component="span"
                  noWrap
                >
                  {`สิ้นสุด ${moment(video?.content_type_expire_time).format(
                    'DD/MM/YYYY HH:mm',
                  )}`}
                </Typography>
              )}
            </Box>
          </React.Fragment>
        }
      />
      <div
        className={clsx(
          isContentTypeExpire
            ? classes.is_content_type_expire_time
            : classes.none,
        )}
      >
        <Typography component={'span'} variant="subtitle1">
          หมดเวลาการแสดงผลแบบล็อกตำแหน่ง
        </Typography>
        <a target={'_blank'} href={`/video/edit/${video.id}`}>
          แก้ไข
        </a>
      </div>
      {action && <ListItemSecondaryAction>{action()}</ListItemSecondaryAction>}
    </ListItem>
  )
}

export default CardVideo
