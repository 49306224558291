import { Chip, Divider, Grid, makeStyles, Typography } from '@material-ui/core'
import React, { ReactElement, useContext } from 'react'
import { VideoContentContext, VideoContentContextType } from '../context/VideoContext'

const useStyles = makeStyles((theme) => ({
  divider: {
    marginBlock: theme.spacing(2),
  },
}))
function SuggestionTagWidget(): ReactElement {
  const classes = useStyles()
  const {
    suggestionTags,
    handleChange,
    content: [content],
  } = useContext(VideoContentContext) as VideoContentContextType

  return (
    <div>
      <Divider className={classes.divider} />
      <Typography variant="body1" gutterBottom>
        Tag แนะนำ
      </Typography>
      <Grid container spacing={2}>
        {suggestionTags &&
          suggestionTags.data.map((item, index) => (
            <Grid item xs={12} key={`${item._id}`}>
              <Typography
                variant="subtitle1"
                color="textSecondary"
                gutterBottom
              >
                {index + 1}.{item.description}
              </Typography>
              <Grid container spacing={1}>
                {item.tags &&
                  item.tags.map((tag) => (
                    <Grid key={tag} item>
                      <Chip
                        onClick={() => {
                          if (content.tags.indexOf(tag) === -1) {
                            handleChange('tags', [...content.tags, tag])
                          }
                        }}
                        label={tag}
                      />
                    </Grid>
                  ))}
              </Grid>
            </Grid>
          ))}
      </Grid>
    </div>
  )
}

export default SuggestionTagWidget
