import { useEffect, useState } from 'react'
import FormMirrorProduct from '@Components/Mirror/Product/Form'
import DropArea from '@Components/DropArea'
import { IMirrorProduct } from '@Types/mirror/product_type'
import { useAuth } from '@Hooks/useAuth'
import { useMutation, useQuery } from '@apollo/client'
import {
  MUTATION_EDIT_MIRROR_PRODUCT,
  QUERY_GET_ALL_MIRROR_PRODUCTS,
  QUERY_GET_MIRROR_PRODUCT_BY_ID,
} from '@Api/mirror/product_api'
import useUpload from '@Hooks/useUpload'
import { useHistory, useParams } from 'react-router-dom'
import Loading from '@Components/Loading'

function EditMirrorProduct() {
  const [imgSrc, setImgSrc] = useState<FileList>()
  const { id } = useParams<{ id: string }>()
  const [errorMessages, setErrorMessages] = useState({
    image_url: '',
  })
  const { checkPermission } = useAuth()
  const { onUpload } = useUpload()
  const { loading, data, error } = useQuery(QUERY_GET_MIRROR_PRODUCT_BY_ID, {
    variables: {
      _id: id,
    },
    fetchPolicy: 'network-only',
  })
  const [edit] = useMutation(MUTATION_EDIT_MIRROR_PRODUCT)
  const history = useHistory()

  const handleSubmit = async (obj: IMirrorProduct) => {
    let current_img = data.CmsMirrorProduct.image_url
    if (!current_img && !imgSrc) {
      return setErrorMessages({ image_url: 'กรุณาเลือก รูปภาพสินค้า' })
    } else setErrorMessages({ image_url: '' })

    if (imgSrc) {
      const res_image = await onUpload(imgSrc, 'image_files', {
        ref_id: id,
        type: 'replace',
      })
      current_img = res_image.data.data?.items[0]?.url
    }

    const {
      data: { EditMirrorProduct },
    } = await edit({
      variables: { ...obj, image_url: current_img, _id: id },
      refetchQueries: [
        {
          query: QUERY_GET_ALL_MIRROR_PRODUCTS,
          variables: {
            page: 1,
            limit: 100,
          },
        },
      ],
    })

    if (EditMirrorProduct.statusCode === 200) {
      history.push('/product')
    }
  }

  useEffect(() => {
    if (!checkPermission('mirror_product_edit')) {
      window.location.href = '/'
    }
    return () => {}
  }, []) //eslint-disable-line react-hooks/exhaustive-deps

  if (loading) return <Loading />
  if (error) {
    console.log('LOG: error ---> ', error)
    return <p>Error :(</p>
  }
  return (
    <>
      <FormMirrorProduct
        title="แก้ไขสินค้า"
        onSubmit={handleSubmit}
        defaultData={data.CmsMirrorProduct}
        renderImage={() => (
          <>
            <DropArea
              description="Drag and Drop image(s) here"
              src={
                imgSrc
                  ? URL.createObjectURL(imgSrc[0])
                  : data.CmsMirrorProduct.image_url
              }
              onChangeDropArea={(src) => setImgSrc(src)}
              style={{
                height:
                  imgSrc || data.CmsMirrorProduct.image_url ? 'auto' : '100%',
              }}
            />
            <span style={{ color: 'red' }}>{errorMessages.image_url}</span>
          </>
        )}
      />
    </>
  )
}

export default EditMirrorProduct
