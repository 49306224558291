import { ContentContextProvider } from './context/ContentContext'
import ContentTab from './tab'

const EditContent = () => {
  return (
    <ContentContextProvider>
      <ContentTab />
    </ContentContextProvider>
  )
}

export default EditContent
