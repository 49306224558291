import axios, { AxiosResponse } from 'axios'

export interface IUseUpload {
  convertFileListToFile: (fileList: FileList) => File[]
  onUpload: (
    file_list: FileList,
    type: 'files' | 'image_files',
    params: { [key: string]: string },
  ) => Promise<AxiosResponse>
  getImageByRefId: (
    ref_id: string,
    params: { [key: string]: string },
  ) => Promise<AxiosResponse>
  setWatermarks: (
    type: number | unknown,
    urls: string[],
  ) => Promise<AxiosResponse>
}

export default function useUpload(): IUseUpload {
  const convertFileListToFile = (fileList: FileList | null): File[] => {
    let result = [] as File[]
    if (fileList) {
      for (let i = 0; i < fileList.length; i++) {
        result.push(fileList[i])
      }
    }
    return result
  }

  const onUpload = async (
    file_list: FileList,
    type: 'files' | 'image_files',
    params: { [key: string]: string },
  ) => {
    const files = convertFileListToFile(file_list)
    const p = params || {}
    const formData = new FormData()
    for (const file of files) {
      formData.append(type, file)
    }
    if (Object.keys(p).length > 0) {
      for (const key in p) {
        formData.append(key, p[key] as string)
      }
    }

    const res = await axios.post(
      `${process.env.REACT_APP_MEDIA_URL}/image/${process.env.REACT_APP_THEME}/upload`,
      formData,
      {
        headers: {
          'content-type': 'application/x-www-form-urlencoded',
        },
      },
    )
    return res
  }

  const getImageByRefId = async (
    ref_id: string,
    params: { [key: string]: string },
  ) => {
    const res = await axios.get(
      `${process.env.REACT_APP_MEDIA_URL}/image/${process.env.REACT_APP_THEME}/list/${ref_id}`,
      {
        params,
      },
    )
    return res
  }

  const setWatermarks = async (type: number | unknown, urls: string[]) => {
    const res = await axios.post(
      `${process.env.REACT_APP_MEDIA_URL}/image/watermarks`,
      { type, urls },
    )
    return res?.data
  }

  return {
    convertFileListToFile,
    onUpload,
    getImageByRefId,
    setWatermarks,
  }
}
