import { VideoContentContextProvider } from './context/VideoContext'
import ContentTab from './tab'

const EditContent = () => {
  return (
    <VideoContentContextProvider>
      <ContentTab />
    </VideoContentContextProvider>
  )
}

export default EditContent
