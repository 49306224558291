import { createTheme } from '@material-ui/core'
import { CustomTheme } from './index'

const default_theme = createTheme()

const theme_thairath_plus: CustomTheme = {
  ...default_theme,
  custom: {
    app_color: '#03b404',
    app_color_light: '#04d205',
    logo_size: {
      width: '62px',
      height: '62px',
    },
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'none',
      },
    },
    MuiInput: {
      underline: {
        '&:hover:not($disabled):after': {
          borderBottomColor: '#03b404',
        },
        '&:hover:not($disabled):before': {
          borderBottom: '1px solid #03b404',
        },
        '&::after': {
          borderBottomColor: '#03b404',
        },
        '&::before': {
          borderBottom: '1px solid #03b404',
        },
      },
      inputMultiline:{
        paddingTop: '4px'
      }
    },
  },
  // props: {
  //   MuiButton: {
  //     disableElevation: true,
  //   },
  // },
}

export default theme_thairath_plus
