import React from 'react'
import { Droppable } from 'react-beautiful-dnd'

interface Props {
  droppableId: string
  children?: React.ReactNode
}

const DropItem: React.FC<Props> = ({ droppableId, children }) => {
  return (
    <Droppable droppableId={droppableId}>
      {(provided, snapshot) => (
        <div {...provided.droppableProps} ref={provided.innerRef}>
          {children}
        </div>
      )}
    </Droppable>
  )
}

export default DropItem
