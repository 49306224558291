import { Theme } from '@material-ui/core'
// import { yellow } from '@material-ui/core/colors'
import { Styles } from '@material-ui/styles'
export const styles: Styles<Theme, {}, string> = (theme: Theme) => ({
  mainContainer: {
    minWidth: '450px',
    // margin: theme.spacing(2),
  },
  mainItem: {
    marginBottom: theme.spacing(6),
  },
  spacingBottom: {
    marginBottom: theme.spacing(1),
  },
  container: {
    // backgroundColor: yellow[10 0],
    border: '1px solid',
    borderColor: theme.palette.divider,
    borderRadius: '3px',
    boxShadow: '0 0 4px 2px rgba(230, 230, 230, 0.5)',
    // maxWidth: '350px',
    // minWidth: '250px',
    marginBottom: '20px',
  },
  box: {
    padding: theme.spacing(2),
    '& > *': {
      marginTop: theme.spacing(1),
    },
  },
  title: {
    fontSize: 14,
  },
  select: {
    backgroundColor: '#fff',
    width: '100%',
  },
  form_control_select: {
    backgroundColor: '#fff',
    width: '100%',
  },
  textLabel: {
    margin: theme.spacing(1),
    textAlign: 'right',
  },
  tagsBox: {
    backgroundColor: '#fff',
    minHeight: '70px',
    border: '1px solid ',
    borderColor: theme.palette.divider,
    borderRadius: '8px',
    boxShadow: 'none',
    padding: '10px',
    margin: '5px 0px 5px 0px',
  },
  textWarning: {
    fontSize: 12,
  },
  saveBtn: {
    background: theme.palette.success.main,
    color: '#fff',
    '&:hover': {
      background: '#439c46',
    },
    margin: theme.spacing(1),
  },
  tag: {
    margin: theme.spacing(1),
  },
  dialogContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  img_exist: {
    maxHeight: '150px',
    padding: theme.spacing(0.5),
    height: '100%',
  },
  title_custom: {
    width: '100%',
    textAlign: 'center',
    float: 'left',
  },
  button_select: {
    marginTop: theme.spacing(1),
  },
  image_list: {
    // border: '1px solid #ddd',
    minHeight: '150px',
  },
  image_wrapper: {
    position: 'relative',
    maxHeight: '180px',
    '&:hover': {
      cursor: 'pointer',
      opacity: 0.7,
    },
  },
  image: {
    maxHeight: '130px',
    padding: theme.spacing(0.2),
  },
  margin_left: {
    marginLeft: theme.spacing(1),
  },
  image_selected: {
    border: '2.5px solid',
    borderColor: theme.palette.info.main,
  },
  image_before_selected: {
    border: '2.5px solid',
    borderColor: '#fff',
  },
  has_video_wrapper: {
    paddingTop: theme.spacing(0.5),
    paddingInline: theme.spacing(2),
  },
  video_embed: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(1),
  },
})
