import FroalaEditor from 'froala-editor/js/froala_editor.min.js'

global.FroalaEditor = FroalaEditor
;(function (FroalaEditor) {
  FroalaEditor.POPUP_TEMPLATES['customEmbeded.popup'] =
    '[_BUTTONS_][_CUSTOM_LAYER_]'

  // Define popup buttons.
  Object.assign(FroalaEditor.DEFAULTS, {
    popupButtons: ['popupClose', '|'],
  })

  // The custom popup is defined inside a plugin (new or existing).
  FroalaEditor.PLUGINS.customEmbeded = function (editor) {
    // Create custom popup.
    function initPopup() {
      // Popup buttons.
      var popup_buttons = ''

      // Create the list of buttons.
      if (editor.opts.popupButtons.length > 1) {
        popup_buttons += '<div class="fr-buttons">'
        popup_buttons += editor.button.buildList(editor.opts.popupButtons)
        popup_buttons += '</div>'
      }

      var tab_idx = 0
      var input_layer =
        '<div class="fr-link-insert-layer fr-layer fr-active" id="fr-link-insert-layer-' +
        editor.id +
        '">'
      input_layer +=
        '<div class="fr-input-line"><textarea id="embedHtmlCode" name="embedHtmlCode" value="" tabIndex="' +
        ++tab_idx +
        '" placeholder="insert embed code" style="height: 150px"></textarea></div>'
      input_layer +=
        '<div class="fr-action-buttons"><button class="fr-command fr-submit" data-cmd="insertEmbed" href="#" tabIndex="' +
        ++tab_idx +
        '" type="button">' +
        editor.language.translate('Insert') +
        '</button></div></div>'

      // Load popup template.
      var template = {
        buttons: popup_buttons,
        custom_layer: input_layer,
      }

      // Create popup.
      var $popup = editor.popups.create('customEmbeded.popup', template)

      return $popup
    }

    function insert(html) {
      editor.html.insert(`${html}`)
    }

    // Show the popup
    function showPopup() {
      // Get the popup object defined above.
      var $popup = editor.popups.get('customEmbeded.popup')

      // If popup doesn't exist then create it.
      // To improve performance it is best to create the popup when it is first needed
      // and not when the editor is initialized.
      if (!$popup) $popup = initPopup()

      // Set the editor toolbar as the popup's container.
      editor.popups.setContainer('customEmbeded.popup', editor.$tb)

      // This will trigger the refresh event assigned to the popup.
      // editor.popups.refresh('customEmbeded.popup');

      // This custom popup is opened by pressing a button from the editor's toolbar.
      // Get the button's object in order to place the popup relative to it.
      var $btn = editor.$tb.find('.fr-command[data-cmd="customEmbededBtn"]')

      // Set the popup's position.
      var left = $btn.offset().left + $btn.outerWidth() / 2
      var top =
        $btn.offset().top +
        (editor.opts.toolbarBottom ? 10 : $btn.outerHeight() - 10)

      // Show the custom popup.
      // The button's outerHeight is required in case the popup needs to be displayed above it.
      editor.popups.show('customEmbeded.popup', left, top, $btn.outerHeight())
    }

    // Hide the custom popup.
    function hidePopup() {
      editor.popups.hide('customEmbeded.popup')
    }

    // Methods visible outside the plugin.
    return {
      showPopup: showPopup,
      hidePopup: hidePopup,
      insert,
    }
  }

  // Define an icon and command for the button that opens the custom popup.
  FroalaEditor.DefineIcon('buttonIcon', {
    NAME: 'customEmbeded',
    SVG_KEY: 'insertEmbed',
  })
  FroalaEditor.RegisterCommand('customEmbededBtn', {
    title: 'Insert Embed Code',
    icon: 'buttonIcon',
    undo: false,
    focus: false,
    plugin: 'customEmbeded',
    callback: function () {
      this.customEmbeded.showPopup()
    },
  })

  // Define custom popup close button icon and command.
  FroalaEditor.DefineIcon('popupClose', { NAME: 'times', SVG_KEY: 'back' })
  FroalaEditor.RegisterCommand('popupClose', {
    title: 'Close',
    undo: false,
    focus: false,
    callback: function () {
      this.customEmbeded.hidePopup()
    },
  })

  // Define custom popup 1.
  FroalaEditor.RegisterCommand('insertEmbed', {
    title: 'Submit',
    undo: false,
    focus: false,
    callback: function () {
      var htmldata = document.getElementById('embedHtmlCode').value
      if (htmldata !== '') {
        if (htmldata.indexOf('http') === 0) {
          htmldata = `<iframe src="${htmldata}" width="100%" frameborder="0" scrolling="no"></iframe>`
        }
        this.customEmbeded.insert(htmldata)
        this.customEmbeded.hidePopup()
        document.getElementById('embedHtmlCode').value = ''
      } else {
        console.log('empty')
        this.customEmbeded.hidePopup()
      }
    },
  })
})(FroalaEditor)
