import { gql } from '@apollo/client'
export const MUTATION_ADD_METATAG = gql`
  mutation AddMetatag($path: String, $title: String, $description: String) {
    AddMetatag(path: $path, title: $title, description: $description) {
      statusCode
      message
      data
    }
  }
`

export const QUERY_ALL_METATAGS = gql`
  query {
    MetatagsCMS {
      _id
      path
      title
      description
      update_time(format: "DD/MM/YYYY HH:mm:ss")
    }
  }
`

export const QUERY_METATAG = gql`
  query ($_id: ID) {
    MetatagCMS(_id: $_id) {
      _id
      path
      title
      description
      update_time
      cover_url
    }
  }
`

export const MUTATION_EDIT_METATAG = gql`
mutation EditMetatag(
    $_id: ID
    $path: String
    $title: String
    $description: String
    $cover_url:String
  ) {
    EditMetatag(
      _id: $_id
      path: $path
      title: $title
      description: $description
      cover_url:$cover_url
    ) {
      statusCode
      message
      data
    }
  }
`
