import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  FormControl,
  FormControlLabel,
  makeStyles,
  Typography,
} from '@material-ui/core'
import { KeyboardDateTimePicker } from '@material-ui/pickers'
import { ReactElement, useContext, useEffect, useState } from 'react'
import { ContentContext, ContentContextType } from '../context/ContentContext'
import { styles } from '../Detail/styles'
import moment from 'moment'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { get, isEmpty } from 'lodash'
import { useGlobal } from '@Hooks/useGlobal'

const useStyles = makeStyles(styles)
export default function PublishDialog(): ReactElement {
  const classes = useStyles()
  const {
    publishNow: [publishNow, setPublishNow],
    content: [content],
    onPublish,
    dialog: [dialog, setDialog],
  } = useContext(ContentContext) as ContentContextType
  const [errorMessage, setErrorMessage] = useState('')
  const { wording } = useGlobal()
  const [publishTime, setPublishTime] = useState<MaterialUiPickersDate>()

  const [expireTime, setExpireTime] = useState<MaterialUiPickersDate>()

  const handleTogle = () => {
    if (!dialog) setErrorMessage('')
    setDialog(!dialog)
  }
  const handleOk = () => {
    // console.log(moment(content?.expire_time))
    if (isEmpty(content.topic)) {
      setErrorMessage(`กรุณาเลือก ${wording['topic']}`)
    } else if (isEmpty(content.categories)) {
      setErrorMessage(`กรุณาเลือก ${wording['category']}`)
    } else if (isEmpty(content.author?._id)) {
      setErrorMessage('กรุณาเลือก author')
    } else if (isEmpty(content.cover)) {
      setErrorMessage('กรุณาเลือกรูปปก')
    } else if (moment(publishTime).valueOf() > moment(expireTime).valueOf()) {
      setErrorMessage('กรุณาเลือกเวลา ExpireTime ใหม่')
    }
    // else if (isEmpty(content.share)) {
    //   setErrorMessage('กรุณาเลือกรูป share')
    // }
    // else if (isEmpty(get(content, 'widgets[0].data[0].thumbnails'))) {
    //   setErrorMessage('กรุณาเลือกรูป thumbnails')
    // }
    else {
      setErrorMessage('')
      handleTogle()
      onPublish(publishTime?.toDate(), expireTime?.toDate())
    }
  }
  useEffect(() => {
    setPublishTime(
      content?.publish_time ? moment(content?.publish_time) : undefined,
    )
    setExpireTime(
      content?.expire_time ? moment(content?.expire_time) : undefined,
    )
    return () => {}
  }, [content?.publish_time, content?.expire_time])

  useEffect(() => {
    return () => {
      setErrorMessage('')
    }
  }, [dialog])

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      open={dialog}
      onClose={handleTogle}
      maxWidth="xs"
    >
      <Box className={classes.box}>
        <form noValidate>
          <KeyboardDateTimePicker
            disabled={publishNow}
            className={classes.date}
            ampm={false}
            defaultValue={content?.publish_time}
            fullWidth
            label="Publish datetime"
            format="DD/MM/YYYY HH:mm:ss"
            value={publishTime || null}
            size="small"
            onError={console.log}
            onChange={setPublishTime}
          />
        </form>
        <form noValidate>
          <KeyboardDateTimePicker
            className={classes.date}
            ampm={false}
            fullWidth
            defaultValue={content?.expire_time}
            label="Expire datetime"
            format="DD/MM/YYYY HH:mm:ss"
            value={expireTime || null}
            size="small"
            onError={console.log}
            onChange={setExpireTime}
          />
        </form>
        <FormControl size="small" variant="outlined" className={classes.select}>
          <FormControlLabel
            control={
              <Checkbox
                checked={publishNow}
                onChange={() => {
                  setPublishNow(!publishNow)
                }}
                name="publishNow"
                color="primary"
              />
            }
            className={classes.form_control_select}
            label="publish now"
          />
        </FormControl>
        <Typography color="error" align="left" variant="body2">
          {errorMessage}
        </Typography>
        <DialogActions>
          <Button onClick={handleTogle} color="primary">
            Cancel
          </Button>
          <Button onClick={handleOk} color="primary">
            OK
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  )
}
