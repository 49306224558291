import { QuoteContextProvider } from './context/QuoteContext'
import QuoteTab from './tab'

const EditQuote = () => {
  return (
    <QuoteContextProvider>
      <QuoteTab />
    </QuoteContextProvider>
  )
}

export default EditQuote
