import {
  Box,
  Button,
  Divider,
  makeStyles,
  Paper,
  Typography,
  Grid,
  TextField,
  Select,
  FormControl,
  InputLabel,
  FormControlLabel,
} from '@material-ui/core'
import React, { useState } from 'react'
import { CustomTheme } from '../../../theme'
import { IMirrorProduct } from '@Types/mirror/product_type'
import SaveIcon from '@material-ui/icons/Save'
import isEmpty from 'lodash.isempty'
import {
  mirrorProductCategories,
  mirrorProductStatus,
} from '@Libs/const/mirror/product'
import Switch from '@material-ui/core/Switch'
import { Autocomplete } from '@material-ui/lab'
const useStyles = makeStyles((theme: CustomTheme) => ({
  root: {
    minHeight: '100%',
    padding: theme.spacing(2),
  },
  grow: {
    flexGrow: 1,
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  input_group: {
    '& > *': {
      marginBottom: theme.spacing(1),
    },
  },
  form_control: {
    minWidth: '100%',
  },
  is_hide_price: {
    // marginBottom: -theme.spacing(1),
  },
}))
interface FormMirrorProductProps {
  title: string
  onSubmit: (data: IMirrorProduct) => void
  defaultData?: IMirrorProduct
  renderImage: () => React.ReactNode
}
function FormMirrorProduct({
  title,
  onSubmit,
  renderImage,
  defaultData,
}: FormMirrorProductProps) {
  const classes = useStyles()
  const [errorMessages, setErrorMessages] = useState<{
    [key: string]: string
  }>({})
  const [isHidePrice, setIsHidePrice] = useState(defaultData?.is_hide_price)
  const validation = (obj: IMirrorProduct) => {
    let errors: {
      [key: string]: string
    } = {}
    if (!obj.name) {
      errors.name = 'กรุณากรอก ชื่อสินค้า'
    }
    if (!obj.price && !isHidePrice) {
      errors.price = 'กรุณากรอก ราคา'
    }
    if (!obj.product_url) {
      errors.product_url = 'กรุณากรอก ลิงก์สินค้า'
    }
    setErrorMessages(errors)
    return isEmpty(errors)
  }
  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault()
    const target = e.target as typeof e.target & {
      name: { value: string }
      price: { value: string }
      discount_price: { value: string }
      product_url: { value: string }
      // category: { value: string }
      status: { value: string }
      type: { value: string }
      description: { value: string }
    }
    const obj = {
      name: target.name.value,
      price: Number.parseFloat(target.price.value),
      discount_price: Number.parseFloat(target.discount_price.value),
      product_url: target.product_url.value,
      // category: target.category.value,
      status: target.status.value,
      type: target.type.value,
      description: target.description.value,
      is_hide_price: isHidePrice,
    } as IMirrorProduct
    // console.log(`LOG: obj ---> `, obj)
    if (!validation(obj)) return
    onSubmit(obj)
  }
  return (
    <>
      <Paper className={classes.root}>
        <form onSubmit={handleSubmit}>
          <Box display="flex" alignItems="center">
            <Typography variant="h6">{title}</Typography>
            <div className={classes.grow} />
            <Button startIcon={<SaveIcon />} className='btn-save' variant="contained" type="submit" color="primary">
              บันทึกข้อมูล
            </Button>
          </Box>
          <Divider className={classes.divider} />
          <Grid container spacing={2}>
            <Grid item xs={12} md={5}>
              {renderImage()}
            </Grid>
            <Grid item xs={12} md={7} className={classes.input_group}>
              <TextField
                name="name"
                label="ชื่อสินค้า"
                size="small"
                fullWidth
                defaultValue={defaultData?.name}
                error={!!errorMessages.name}
                helperText={errorMessages.name}
                rowsMax={2}
                multiline
              />
              <TextField
                name="description"
                label="รายละเอียดสินค้า (อย่างย่อ)"
                size="small"
                fullWidth
                defaultValue={defaultData?.description}
                error={!!errorMessages.description}
                helperText={errorMessages.description}
                rowsMax={3}
                multiline
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={isHidePrice}
                    color="primary"
                    onChange={(e) => {
                      setIsHidePrice(e.target.checked)
                    }}
                  />
                }
                label="ซ่อนราคา"
                className={classes.is_hide_price}
                name="is_hide_price"
              />
              <TextField
                name="price"
                label="ราคา"
                size="small"
                fullWidth
                defaultValue={defaultData?.price}
                type="number"
                error={!!errorMessages.price}
                helperText={errorMessages.price}
              />
              <TextField
                name="discount_price"
                label="ราคาลด"
                size="small"
                fullWidth
                defaultValue={defaultData?.discount_price}
                type="number"
              />
              <TextField
                name="product_url"
                label="ลิงก์สินค้า"
                size="small"
                fullWidth
                defaultValue={defaultData?.product_url}
                rowsMax={3}
                multiline
                error={!!errorMessages.product_url}
                helperText={errorMessages.product_url}
              />
              {/* <FormControl className={classes.form_control} size="small">
                <InputLabel>หมวดหมู่</InputLabel>
                <Select
                  name="category"
                  defaultValue={defaultData?.category || 'makeup'}
                  native
                  fullWidth
                >
                  {mirrorProductCategories.map((category) => (
                    <option key={category.value} value={category.value}>
                      {category.label}
                    </option>
                  ))}
                </Select>
              </FormControl> */}
              <Autocomplete
                freeSolo
                options={['mirror']}
                size="small"
                defaultValue={defaultData?.type}
                renderInput={(params) => (
                  <TextField {...params} label="Partner" name="type" />
                )}
              />
              <FormControl className={classes.form_control} size="small">
                <InputLabel>สถานะ</InputLabel>
                <Select
                  name="status"
                  defaultValue={defaultData?.status}
                  native
                  fullWidth
                >
                  {Object.entries(mirrorProductStatus).map(([value, label]) => (
                    <option key={value} value={value}>
                      {label}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </>
  )
}

export default FormMirrorProduct
