import { SvgIcon, SvgIconProps } from '@material-ui/core'
import React, { ReactElement } from 'react'
interface Props extends SvgIconProps {}
export default function ThairathPlusIcon(props: Props): ReactElement {
  return (
    <>
      <SvgIcon {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33.412 33.412">
          <g
            id="Group_5292"
            data-name="Group 5292"
            transform="translate(-776.155 -363.288)"
          >
            <path
              id="Path_315"
              data-name="Path 315"
              d="M865.778,444.558V436.2h-8.353l-8.353,8.353Z"
              transform="translate(-64.564 -64.564)"
              fill="#00b400"
              // fill="#fff"
            ></path>
            <rect
              id="Rectangle_1"
              data-name="Rectangle 1"
              width="8.353"
              height="16.706"
              transform="translate(784.508 379.994)"
              fill="#eee"
            ></rect>
            <rect
              id="Rectangle_2"
              data-name="Rectangle 2"
              width="8.353"
              height="8.353"
              transform="translate(776.155 371.641)"
              fill="#eee"
            ></rect>
            <path
              id="Path_316"
              data-name="Path 316"
              d="M1003.26,366.8h-3.516v-3.516h-1.321V366.8h-3.516v1.321h3.516v3.516h1.321v-3.516h3.516Z"
              transform="translate(-193.693)"
              fill="#eee"
            ></path>
          </g>
          <title>Logo thairath plus</title>
        </svg>
      </SvgIcon>
    </>
  )
}
