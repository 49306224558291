import { Button, ButtonProps } from '@material-ui/core'
import React, { ReactElement } from 'react'
import { Link } from 'react-router-dom'

/**
 *  ---- Example ----
 * <ButtonLink to="/" variant="text" className={classes.button}>Home</ButtonLink>
 */
export interface ButtonLinkProps extends ButtonProps {
  to: string
}

export default function ButtonLink(props: ButtonLinkProps): ReactElement {
  return (
    <Button
      {...props}
      component={({ innerRef, ...linkProps }) => (
        <Link {...linkProps} to={props.to} />
      )}
    >
      {props.children}
    </Button>
  )
}
