import Paper from '@material-ui/core/Paper'
import React, { ReactElement } from 'react'
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { IMirrorProduct } from '@Types/mirror/product_type'
import clsx from 'clsx'
import Divider from '@material-ui/core/Divider'
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    product_wrapper: {
      height: 100,
      overflowY: 'hidden',
      display: 'flex',
    },
    img: {
      // borderRadius: theme.shape.borderRadius,
      width: 100,
      height: 100,
    },
    product_content: {
      padding: theme.spacing(1),
      width: '100%',
      height: '100%',
    },
    product_title: {
      display: '-webkit-box',
      boxOrient: 'vertical',
      lineClamp: 1,
      wordBreak: 'break-all',
      overflow: 'hidden',
    },
    product_sub_title: {
      display: '-webkit-box',
      boxOrient: 'vertical',
      lineClamp: 1,
      wordBreak: 'break-all',
      overflow: 'hidden',
    },
    product_action: {
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0.5),
      flexDirection: 'column',
      justifyContent: 'center',
    },
    has_discount: {
      textDecoration: 'line-through',
    },
    category: {
      color: theme.palette.info.main,
    },
    type: {
      color: theme.palette.warning.main,
    },
    product_detail_wrapper: {
      display: 'flex',
      gap: 6,
    },
    discount: {
      marginLeft: theme.spacing(1),
    },
  }),
)
interface CardProductProps {
  product: IMirrorProduct
  action?: () => React.ReactNode
  className?: string
}

export default function CardProduct({
  product,
  action,
  className,
}: CardProductProps): ReactElement {
  const classes = useStyles()
  // if (error) return <>error :(</>
  return (
    <Paper
      variant="outlined"
      className={clsx(classes.product_wrapper, className)}
    >
      <img
        draggable={false}
        alt={product.name}
        className={classes.img}
        src={product.image_url}
      />
      <div className={classes.product_content}>
        <Typography className={classes.product_title} variant="subtitle2">
          <a
            target="_blank"
            href={`/product/edit/${product._id}`}
            rel="noreferrer"
            style={{ textDecoration: 'none' }}
          >
            {product.name}
          </a>
        </Typography>
        <Typography
          className={classes.product_sub_title}
          variant="body2"
          color="textSecondary"
        >
          {product.description}
        </Typography>
        <div className={classes.product_detail_wrapper}>
          <Typography className={classes.type} variant="body2" display="inline">
            {product.type}
          </Typography>
          <Divider orientation="vertical" flexItem />
          <Typography
            className={classes.category}
            variant="body2"
            display="inline"
          >
            {product.category}
          </Typography>
        </div>
        <Typography
          className={clsx(product.discount_price && classes.has_discount)}
          variant="body2"
          display="inline"
          color={product.discount_price ? 'textSecondary' : 'secondary'}
        >
          {product.price}
        </Typography>

        {product.discount_price && (
          <Typography
            className={classes.discount}
            variant="body2"
            color="secondary"
            display="inline"
          >
            {product.discount_price}
          </Typography>
        )}
      </div>
      {action && <div className={classes.product_action}>{action()}</div>}
    </Paper>
  )
}
