import { Fragment, useContext } from 'react'
import { Box, makeStyles, TextField } from '@material-ui/core'
import { ContentContext, ContentContextType } from '../context/ContentContext'
import { styles } from './styles'
import { useGlobal } from '@Hooks/useGlobal'

const useStyles = makeStyles(styles)

export default function Main() {
  const {
    handleChange,
    content: [content],
  } = useContext(ContentContext) as ContentContextType
  const { wording } = useGlobal()
  const classes = useStyles()
  return (
    <Fragment>
      <Box className={classes.mainContainer}>
        <div className={classes.mainItem}>
          <div className='input-text'>
            <TextField
              id="outlined-textarea"
              placeholder="หัวข้อ"
              multiline
              value={content?.title || ''}
              style={{
                fontSize: '30px',
                outline: 'none',
                width: '100%',
              }}
              onChange={(ev) => {
                handleChange('title', ev.target.value)
              }}
            />
          </div>
          <p style={{ textAlign: 'right' }}><span style={{ color: content?.title.length > 90 ? 'red' : '' }}>{content?.title.length}</span>/90</p>
        </div>
        { process.env.REACT_APP_THEME !== 'mirror'  && (
          <div className={classes.mainItem}>
            <TextField
              id="outlined-textarea"
              multiline
              //rowsMax={3}
              placeholder={wording.content_page.abstract}
              value={content?.abstract || ''}
              fullWidth
              onChange={(ev) => {
                handleChange('abstract', ev.target.value)
              }}
            />
          </div>
        )}
        <div className={classes.mainItem}>
          <TextField
            id="outlined-textarea"
            multiline
            rowsMax={5}
            placeholder={wording.content_page.summary}
            value={content?.summary || ''}
            fullWidth
            onChange={(ev) => {
              handleChange('summary', ev.target.value)
            }}
          />
           <p style={{ textAlign: 'right' }}><span style={{ color: content?.summary.length > 170 ? 'red' : '' }}>{content?.summary.length}</span>/170</p>
        </div>
      </Box>
    </Fragment>
  )
}
