import { useMutation } from '@apollo/client'
import {
  Button,
  Container,
  Divider,
  Grid,
  makeStyles,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core'
import { Save } from '@material-ui/icons'
import React, { ReactElement, useState } from 'react'
import { MUTATION_CHANGE_PASSWORD } from '@Api/user_api'
import { useAuth } from '@Hooks/useAuth'
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  divider: {
    marginBlock: theme.spacing(1),
  },
  grow: {
    flexGrow: 1,
  },
  text_error: {
    color: theme.palette.error.main,
    marginTop: theme.spacing(2),
  },
}))
export default function ChangePasswordUser(): ReactElement {
  const classes = useStyles()
  const { user, logout } = useAuth()
  const [errorMessage, setErrorMessage] = useState<string>()
  const [changePassword] = useMutation(MUTATION_CHANGE_PASSWORD)
  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    const target = e.target as typeof e.target & {
      // old_password: { value: string }
      password: { value: string }
      confirm_password: { value: string }
    }
    const obj = {
      _id: user._id,
      // old_password: target.old_password.value,
      password: target.password.value,
      confirm_password: target.confirm_password.value,
    }
    if (obj.password !== obj.confirm_password) {
      setErrorMessage('password and confirm password not matching')
      return
    }

    const res = await changePassword({
      variables: obj,
    })
    if (res.data?.ChangePassword?.statusCode === 200) {
      setErrorMessage('')
      const res_logout = logout()
      if (res_logout) window.location.href = '/'
    } else {
      setErrorMessage(res.data?.ChangePassword?.message)
    }
  }
  return (
    <Paper className={classes.root}>
      <form onSubmit={handleSubmit}>
        <Grid container>
          <Grid item>
            <Typography variant="h6">เปลี่ยนรหัสผ่าน</Typography>
          </Grid>
          <div className={classes.grow} />
          <Grid item>
            <Button startIcon={<Save />} variant="outlined" type="submit">
              บันทึกข้อมูล
            </Button>
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
        <Container maxWidth="xs">
          <Grid container spacing={1} direction="column">
            {/* <Grid item>
              <TextField
                name="old_password"
                label="old password"
                type="password"
                size="small"
                // variant="outlined"
                fullWidth
                autoComplete={'false'}
              />
            </Grid> */}
            <Grid item>
              <TextField
                name="password"
                label="password"
                type="password"
                size="small"
                // variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item>
              <TextField
                name="confirm_password"
                label="confirm password"
                type="password"
                size="small"
                // variant="outlined"
                fullWidth
              />
            </Grid>
          </Grid>
          <div className={classes.text_error}>{errorMessage}</div>
        </Container>
      </form>
    </Paper>
  )
}
