import { gql } from '@apollo/client'

export const QUERY_GET_ALL_FOLLOWS = gql`
    query {
        Follows: CmsFollows {
            _id
            title
            slug
            description
            vdopath
            data_id
            type
            cover
            is_active
            createId
            createDate(format: "DD/MM/YYYY HH:mm:ss")
            lastUpdateId
            lastUpdateDate(format: "DD/MM/YYYY HH:mm:ss")
        }
    }
`

export const QUERY_GET_ALL_FOLLOWS_OPTION = gql`
  query {
    Follows {
      id: _id
      title
      value: title
    }
  }
`

export const QUERY_GET_FOLLOW_BY_ID = gql`
  query ($_id: ID!) {
    Follow: CmsFollow(_id: $_id) {
      _id
      title
      slug
      description
      type
      cover
      is_active
    }
  }
`

export const MUTATION_ADD_FOLLOW = gql`
  mutation (
    $title: String
    $slug: String
    $description: String
    $type: String
    $cover: String
    $is_active: String
  ) {
    AddFollow(
      title: $title
      slug: $slug
      description: $description
      type: $type
      cover: $cover
      is_active: $is_active
    ) {
      statusCode
      message
      data
    }
  }
`

export const MUTATION_EDIT_FOLLOW = gql`
  mutation (
    $_id: ID
    $title: String
    $slug: String
    $description: String
    $type: String
    $cover: String
    $is_active: String
  ) {
    EditFollow(
      _id: $_id
      title: $title
      slug: $slug
      description: $description
      type: $type
      cover: $cover
      is_active: $is_active
    ) {
      statusCode
      message
      data
    }
  }
`

