import FroalaEditor from 'froala-editor/js/froala_editor.min.js'
global.FroalaEditor = FroalaEditor
;(function (FroalaEditor) {
  FroalaEditor.DefineIconTemplate(
    'quora',
    `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="svg-inline--fa fa-quora fa-w-14" viewBox="0 0 448 512">
      <path d="M440.5 386.7h-29.3c-1.5 13.5-10.5 30.8-33 30.8-20.5 0-35.3-14.2-49.5-35.8 44.2-34.2 74.7-87.5 74.7-153C403.5 111.2 306.8 32 205 32 105.3 32 7.3 111.7 7.3 228.7c0 134.1 131.3 221.6 249 189C276 451.3 302 480 351.5 480c81.8 0 90.8-75.3 89-93.3zM297 329.2C277.5 300 253.3 277 205.5 277c-30.5 0-54.3 10-69 22.8l12.2 24.3c6.2-3 13-4 19.8-4 35.5 0 53.7 30.8 69.2 61.3-10 3-20.7 4.2-32.7 4.2-75 0-107.5-53-107.5-156.7C97.5 124.5 130 71 205 71c76.2 0 108.7 53.5 108.7 157.7.1 41.8-5.4 75.6-16.7 100.5z"/>
    </svg>`,
  )

  // <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="quora" class="svg-inline--fa fa-quora fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d=""></path></svg>
  FroalaEditor.DefineIcon('qa_block', {
    NAME: 'quora',
    template: 'quora',
  })

  FroalaEditor.RegisterCommand('qa_block', {
    title: 'QA',
    focus: false,
    undo: false,
    refreshAfterCallback: false,
    callback: function () {
      this.qa_block.insert()
    },
  })

  // Add an option for your plugin.
  FroalaEditor.DEFAULTS = Object.assign(FroalaEditor.DEFAULTS, {
    qa_block: false,
  })

  // Define the plugin.
  // The editor parameter is the current instance.
  FroalaEditor.PLUGINS.qa_block = function (editor) {
    // Private variable visible only inside the plugin scope.
    var quote = null

    // Private method that is visible only inside plugin scope.
    function _privateMethod() {
      console.log(quote)
    }

    // Public method that is visible in the instance scope.
    function publicMethod() {
      console.log(_privateMethod())
    }

    function insert() {
      editor.html.insert(`<p class="qa_widget_label">Q</p>
      <p class="qa_widget_question">พิมพ์คำถาม</p>
      <p class="qa_widget_label">A</p>
      <p class="qa_widget_answer">พิมพ์คำตอบ</p>
      <hr/>`)
    }

    // The start point for your plugin.
    function _init() {
      // You can access any option from documentation or your custom options.
      // console.log('editor.opts.qa_block', editor.events)

      // Call any method from documentation.
      // editor.methodName(params);

      // You can listen to any event from documentation.
      editor.events.on('html.get', function () {})
    }

    // Expose public methods. If _init is not public then the plugin won't be initialized.
    // Public method can be accessed through the editor API:
    // editor.myPlugin.publicMethod();
    return {
      _init: _init,
      publicMethod: publicMethod,
      insert,
    }
  }
})(FroalaEditor)
