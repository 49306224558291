import { Theme } from '@material-ui/core'
import { Styles } from '@material-ui/styles'

export const styles: Styles<Theme, {}, string> = (theme: Theme) => ({
  box_image: {
    margin: theme.spacing(1),
  },
  bagde: {
    cursor: 'pointer',
    position: 'relative',
    bottom: '124px',
    right: '-128px',
  },
  image_list: {
    marginTop: theme.spacing(1),
    border: '1px solid #ddd',
    minHeight: '140px',
  },
  image_wrapper: {
    position: 'relative',
    maxHeight: '180px',
    '&:hover': {
      cursor: 'pointer',
      opacity: 0.7,
    },
    '&:hover $image_tool': {
      display: 'flex',
    },
  },
  image_tool: {
    display: 'none',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(255,255,255,0.5)',
  },
  image: {
    maxHeight: '130px',
    padding: theme.spacing(0.2),
  },
  file_name: {
    color: '#000',
  },
  drop_container: {
    backgroundColor: '#fff',
    cursor: 'pointer',
    display: 'flex',
    height: '160px',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0',
    border: '2px dashed #ddd',
    // boxSizing: 'border-box',
    // borderRadius: theme.spacing(1.5),
  },
  input_image: {
    display: 'none',
  },
  box: {
    padding: '20px',
  },
  title: {
    fontSize: 14,
    textAlign: 'center',
  },
  button_upload: {
    background: '#03b404',
    color: '#FFF',
  },
  row_image: {
    display: 'flex',
    position: 'absolute',
  },
  loading_container: {
    marginTop: theme.spacing(1),
    border: '1px solid #ddd',
    minHeight: '150px',
    background: '#efefef',
  },
  image_selected: {
    border: '2.5px solid',
    borderColor: theme.palette.error.main,
  },
  image_before_selected: {
    border: '2.5px solid',
    borderColor: '#fff',
  },
  button_delete: {
    marginBottom: theme.spacing(1),
  },
  confirm_delete_paper: {
    padding: theme.spacing(1),
    margin: theme.spacing(1),
  },
  title_custom: {
    width: '100%',
    textAlign: 'center',
    float: 'left',
  },
  button_watermark: {
    marginBottom: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
    maxWidth: 300,
  },
  saveBtn: {
    background: theme.palette.success.main,
    color: '#fff',
    '&:hover': {
      background: '#439c46',
    },
    margin: theme.spacing(1),
  },
  label_watermark: {
    fontSize: 14,
  },
  gridList: {
    height: 200,
  },
})
