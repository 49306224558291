export interface widgetRulesIf {
  [template: string]: rulesIf
}

export interface rulesIf {
  title: boolean
  abstract: boolean
  description: boolean
  image: 'one' | 'multiple' | 'none'
}

export const basicWidget: rulesIf = {
  title: false,
  abstract: false,
  description: true,
  image: 'one',
}

export const widgetRules: widgetRulesIf = {
  basic: basicWidget,
}
