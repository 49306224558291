import '../../../src/global.css'
import React, { useEffect, useState } from 'react'
import { Typography } from '@material-ui/core'
import { useAuth } from '@Hooks/useAuth'

export default function Logout() {
  const { logout } = useAuth()
  const [error, setError] = useState('')
  useEffect(() => {
    const logoutFunction = async () => {
      const response = await logout()
      if (response) {
        window.location.href = '/'
      } else {
        setError('Something went wrong.')
      }
    }
    logoutFunction()
  }, [logout])

  return <>{error && <Typography>{error}</Typography>}</>
}
