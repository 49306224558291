export enum STATUS {
  DRAFT = '00',
  WAITING_TO_APPROVE = '10',
  APPROVED = '20',
  PUBLISH = '30',
}
export enum STATUS_LABEL {
  DRAFT = 'Draft',
  WAITING_TO_APPROVE = 'Waiting To Approve',
  APPROVED = 'Approved',
  PUBLISH = 'Publish',
}
export const STATUS_MAPPING = {
  [STATUS.DRAFT]: STATUS_LABEL.DRAFT,
  [STATUS.WAITING_TO_APPROVE]: STATUS_LABEL.WAITING_TO_APPROVE,
  [STATUS.APPROVED]: STATUS_LABEL.APPROVED,
  [STATUS.PUBLISH]: STATUS_LABEL.PUBLISH,
}

/* premium_type */

export enum PREMIUM_TYPE {
  FREE = '00',
  MEMBERSHIP = '10',
  SUBSCRIPTION = '20',
}
export enum PREMIUM_TYPE_LABEL {
  FREE = 'Free',
  MEMBERSHIP = 'Membership',
  SUBSCRIPTION = 'Subscription',
}
export const PREMIUM_TYPE_MAPPING = {
  [PREMIUM_TYPE.FREE]: PREMIUM_TYPE_LABEL.FREE,
  [PREMIUM_TYPE.MEMBERSHIP]: PREMIUM_TYPE_LABEL.MEMBERSHIP,
  [PREMIUM_TYPE.SUBSCRIPTION]: PREMIUM_TYPE_LABEL.SUBSCRIPTION,
}

/* end premium_type */
