import { Grid, IconButton, ListItemSecondaryAction } from '@material-ui/core'
import React, { ReactElement, useState, Fragment, useContext } from 'react'
import { useQuery } from '@apollo/client'
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Divider from '@material-ui/core/Divider'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'
import VisibilityIcon from '@material-ui/icons/Visibility'
import { Add, Close } from '@material-ui/icons'
import { IContent } from '@Types/content_type'
import Loading from '@Components/Loading'
import isEmpty from 'lodash.isempty'
import SearchForm, { FormSearchType } from '../../Content/Widget/searchForm'
import { QUERY_GET_CONTENT_FOR_PICK } from '@Api/editor_pick_api'
import { QUERY_GET_RELATED_CONTENT } from '@Api/content_api'
import { VideoContentContext, VideoContentContextType } from '../context/VideoContext'
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      // maxWidth: '36ch',
      backgroundColor: theme.palette.background.paper,
    },
    inline: {
      display: 'inline',
    },
    img: {
      width: theme.spacing(13),
      height: theme.spacing(7),
    },
    content_img: {
      width: theme.spacing(13),
      height: theme.spacing(7),
    },
    title: {
      paddingLeft: theme.spacing(1),
    },
    sub_title: {
      textIndent: theme.spacing(2),
      display: '-webkit-box',
      boxOrient: 'vertical',
      lineClamp: 2,
      wordBreak: 'break-all',
      overflow: 'hidden',
    },
    content_sub_title: {
      textIndent: theme.spacing(2),
      display: '-webkit-box',
      boxOrient: 'vertical',
      lineClamp: 1,
      wordBreak: 'break-all',
      overflow: 'hidden',
    },
    count_view: {
      fontSize: theme.spacing(1.8),
      display: 'flex',
      alignItems: 'center',
    },
    icon_view: {
      fontSize: theme.spacing(2),
      marginRight: theme.spacing(1),
    },
    grow: {
      flexGrow: 1,
    },
    picked: {
      marginLeft: theme.spacing(2),
    },
    divider: {
      marginInline: theme.spacing(2),
    },
    success: {
      color: theme.palette.success.main,
    },
    button_save: {
      marginRight: theme.spacing(2),
    },
    list_item: {
      border: '1px solid #dfdfdf',
      borderRadius: '3px',
      marginBottom: '10px',
      boxShadow: '2px 2px 4px #e6e6e6',
    },
    link: {
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  }),
)

function Related(): ReactElement {
  const {
    content: [content],
    handleChange,
  } = useContext(VideoContentContext) as VideoContentContextType
  const classes = useStyles()
  const [selected, setSelected] = useState<IContent[]>([])

  const { loading: loading_selected } = useQuery(QUERY_GET_RELATED_CONTENT, {
    variables: {
      id: content.id,
    },
    onCompleted: (data) => {
      setSelected(data?.ContentDraft?.related)
    },
    fetchPolicy: 'no-cache',
  })

  const { data, loading, error, refetch } = useQuery(
    QUERY_GET_CONTENT_FOR_PICK,
    {
      variables: {
        contentParams: {
          order_by: [
            {
              field: 'publish_time',
              sort: 'desc',
            },
          ],
        },
        page: 1,
        limit: 30,
      },
      notifyOnNetworkStatusChange: true,
    },
  )

  const handleClickAdd = (content: IContent) => {
    const new_arr = [...selected, content]
    setSelected(new_arr)
    handleChange(
      'related',
      new_arr.map((item) => item.id),
    )
  }

  const handleClickRemove = (content: IContent) => {
    const new_arr = selected.filter((item) => item.id !== content.id)
    setSelected([...new_arr])
    handleChange(
      'related',
      new_arr.map((item) => item.id),
    )
  }

  const handleSearchSubmit = async (form_search: FormSearchType) => {
    await refetch({
      contentParams: {
        ...form_search,
        order_by: [
          {
            field: 'publish_time',
            sort: 'desc',
          },
        ],
      },
      page: 1,
      limit: 30,
    })
  }
  if (error) return <>error :(</>
  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={4}>
          <Grid container>
            <Grid item>
              <Typography className={classes.picked} variant="h6">
                เลือกแล้ว
              </Typography>
            </Grid>
          </Grid>
          <Divider variant="fullWidth" className={classes.divider} />
          <List className={classes.root} dense>
            {loading_selected ? (
              <Loading />
            ) : (
              !isEmpty(selected) &&
              selected.map((item: IContent, index: number) => (
                <ListItem
                  key={item.id}
                  alignItems="flex-start"
                  className={classes.list_item}
                >
                  <ListItemAvatar>
                    <Avatar
                      variant="rounded"
                      src={!isEmpty(item?.cover) ? item.cover : undefined}
                      className={classes.img}
                      imgProps={{
                        draggable: false,
                      }}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    className={classes.title}
                    primary={
                      <React.Fragment>
                        <a
                          href={`/content/edit/${item.id}`}
                          target="_blank"
                          rel="noreferrer"
                          className={classes.link}
                        >
                          {item.id} - {item.title}
                        </a>
                      </React.Fragment>
                    }
                    primaryTypographyProps={{
                      noWrap: true,
                    }}
                    secondary={
                      <React.Fragment>
                        <Typography
                          variant="body2"
                          className={classes.sub_title}
                          color="textSecondary"
                          component="div"
                        >
                          {item.summary}
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          className={classes.count_view}
                          color="primary"
                          component="div"
                          noWrap
                        >
                          <VisibilityIcon
                            className={classes.icon_view}
                            color="primary"
                          />
                          {item.count_view?.toLocaleString()}
                        </Typography>
                      </React.Fragment>
                    }
                  />
                  <ListItemSecondaryAction>
                    <IconButton
                      onClick={() => handleClickRemove(item)}
                      size="small"
                    >
                      <Close color="error" />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))
            )}
          </List>
        </Grid>
        <Grid item xs={12} lg={1}></Grid>
        <Grid item xs={12} lg={7}>
          <Grid container xs={12}>
            <Grid item xs={3}>
              <Typography className={classes.picked} variant="h6">
                บทความ
              </Typography>
            </Grid>
            {/* <div className={classes.grow} /> */}
            <Grid item>
              <SearchForm
                onSubmit={handleSearchSubmit}
                display={{
                  status: false,
                }}
              />
            </Grid>
          </Grid>

          <List className={classes.root} dense>
            {loading ? (
              <Loading />
            ) : (
              <>
                {!isEmpty(data?.ContentForPick) &&
                  data?.ContentForPick?.map((item: IContent, index: number) => (
                    <ListItem
                      key={item.id}
                      alignItems="flex-start"
                      className={classes.list_item}
                    >
                      <ListItemAvatar>
                        <Avatar
                          variant="rounded"
                          src={!isEmpty(item?.cover) ? item.cover : undefined}
                          className={classes.content_img}
                          imgProps={{
                            draggable: false,
                          }}
                        />
                      </ListItemAvatar>
                      <ListItemText
                        className={classes.title}
                        primary={
                          <React.Fragment>
                            <a
                              href={`/content/edit/${item.id}`}
                              target="_blank"
                              rel="noreferrer"
                              className={classes.link}
                            >
                              {item.id} - {item.title}
                            </a>
                          </React.Fragment>
                        }
                        primaryTypographyProps={{
                          noWrap: true,
                        }}
                        secondary={
                          <React.Fragment>
                            <Typography
                              variant="body2"
                              className={classes.content_sub_title}
                              color="textSecondary"
                              component="div"
                            >
                              {item.summary}
                            </Typography>
                            <Typography
                              variant="subtitle2"
                              className={classes.count_view}
                              color="primary"
                              component="div"
                              noWrap
                            >
                              <VisibilityIcon
                                className={classes.icon_view}
                                color="primary"
                              />
                              {item.count_view?.toLocaleString()}
                            </Typography>
                          </React.Fragment>
                        }
                      />
                      <ListItemSecondaryAction>
                        <IconButton size="small">
                          <Add
                            onClick={() => handleClickAdd(item)}
                            className={classes.success}
                          />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
              </>
            )}
          </List>
        </Grid>
      </Grid>
    </Fragment>
  )
}

export default Related
