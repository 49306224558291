import React, { createContext, useCallback, useEffect, useState } from 'react'
import { useLazyQuery, useMutation } from '@apollo/client'
import {
  MUTATION_ADD_CONTENT,
  QUERY_GET_ALL_CONTENTS,
  MUTATION_EDIT_CONTENT,
  MUTATION_PUBLISH_CONTENT,
  MUTATION_EDIT_CONTENT_STATUS,
} from '@Api/video_api'
import { IVideo } from '@Types/video_type'
import set from 'lodash.set'
import debounce from 'lodash.debounce'
import { useHistory } from 'react-router'
import { CONTENT } from '@Libs/const'
import { IUserJWT } from '@Types/user_type'
import { useAuth } from '@Hooks/useAuth'
import isEmpty from 'lodash.isempty'
import { ISuggestionTag } from '@Types/suggestion_tag_type'
import { QUERY_GET_SUGGESTION_TAG_BY_SECTION } from '@Api/suggestion_tags_api'

export const VideoContentContext = createContext({})
export interface IUserWriting {
  content_id: string
  user: IUserJWT
}
type PropertyType = keyof IVideo | Array<keyof IVideo> | string | Array<string>
// แก้ any ด้วย
export type VideoContentContextType = {
  content: [IVideo, React.Dispatch<React.SetStateAction<IVideo>>]
  publishNow: [boolean, React.Dispatch<React.SetStateAction<boolean>>]
  saveContent: () => boolean
  handleChange: (property: PropertyType, value: any) => IVideo
  handleSelectChange: (property: PropertyType, value: any) => void
  newContentDraft: () => IVideo
  saveContentDraft: () => void
  onPublish: (publish_time?: string | Date, expire_time?: string | Date) => void
  statusSave: StatusSaveType
  dialog: [boolean, React.Dispatch<React.SetStateAction<boolean>>]
  onInitContent: () => void
  gallery: string[]
  handleChangeStatus: (status: string) => void
  userWriting: IUserWriting[]
  isDisabled: boolean
  suggestionTags: ISuggestionTag | undefined
  onFetchSuggestionTags: (section: string) => void
}

export type StatusSaveType = {
  loading: boolean
  status_code?: number
  error_message?: string
}
const getDefaultContent = () =>
  ({
    id: '',
    title: '',
    abstract: '',
    summary: '',
    widgets: [
      {
        layout: 'default',
        data: [
          {
            title: '',
            abstract: '',
            description: '',
            thumbnails: [],
          },
        ],
      },
    ],
    categories: [],
    topic: [],
    subtopic: [],
    tags: [],
    status: CONTENT.STATUS.DRAFT,
    assign_to: 'user_id',
    unlist: false,
    premium: CONTENT.PREMIUM_TYPE.MEMBERSHIP,
    allow_comment: false,
    cover: '',
    gallery: [],
    share: '',
    count_view: 0,
    token: '',
    images: [],
    thumbnail_share_9_16: '',
    thumbnail_1_1: '',
    thumbnail_9_16: '',
    JWPlayerId: '',
    content_type: 'content',
  } as IVideo)

export const VideoContentContextProvider: React.FC<{
  children?: React.ReactNode
}> = ({ children }) => {
  const history = useHistory()
  const [content, setContent] = useState<IVideo>()
  const [publishNow, setPublishNow] = useState(true)
  const [addContent] = useMutation(MUTATION_ADD_CONTENT)
  const [editContent] = useMutation(MUTATION_EDIT_CONTENT)
  const [publishContent] = useMutation(MUTATION_PUBLISH_CONTENT)
  const [editContentStatus] = useMutation(MUTATION_EDIT_CONTENT_STATUS)
  const [statusSave, setStatusSave] = useState<StatusSaveType>({
    loading: false,
    status_code: 200,
  })
  const [dialog, setDialog] = useState(false)
  const [gallery] = useState<string[]>()
  const [userWriting, setUserWriting] = useState<IUserWriting[]>([])
  const { user: userJwt, checkPermission } = useAuth()
  const [isDisabled, setIsDisabled] = useState(false)
  const onInitContent = () => {
    setContent(getDefaultContent())
  }

  const [suggestionTags, setSuggestionTags] = useState<ISuggestionTag>()

  const [fetchSuggestionTags] = useLazyQuery(
    QUERY_GET_SUGGESTION_TAG_BY_SECTION,
    {
      onCompleted: ({ CmsSuggestionTag }) => {
        setSuggestionTags(CmsSuggestionTag)
      },
    },
  )

  const onFetchSuggestionTags = useCallback(
    (section: string) => {
      fetchSuggestionTags({
        variables: { section },
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [content?.topic],
  )

  const newContentDraft = async (data: IVideo) => {
    const {
      data: { AddVideoDraft },
      errors,
    } = await addContent({
      variables: data,
      refetchQueries: [
        {
          query: QUERY_GET_ALL_CONTENTS,
          variables: {
            videoParams: {
              order_by: [
                {
                  field: 'updated_time',
                  sort: 'desc',
                },
              ],
            },
          },
        },
      ],
    })
    if (isEmpty(errors)) {
      setStatusSave({
        loading: false,
        status_code: AddVideoDraft?.statusCode,
      })
    }

    return AddVideoDraft.data
  }

  const handleChange = (property: PropertyType, value: any): IVideo => {
    if (!checkPermission('content_edit')) return {} as IVideo
    console.log(`LOG: property ---> `, property, ` \nLOG:  value ---> `, value)
    setStatusSave({
      loading: true,
    })
    // let newData = { ...content } as IContent
    let newData = { ...content, status: CONTENT.STATUS.DRAFT } as IVideo
    if (Array.isArray(property)) {
      for (let i = 0; i < property.length; i++) {
        const key = property[i]
        const val = value[i]
        newData = set<IVideo>(newData, key, val)
      }
    } else {
      newData = set<IVideo>(newData, property, value)
    }
    setContent(newData)
    saveDraftDelay(newData)
    return newData
  }
  const handleSelectChange = (property: PropertyType, value: any) => {
    if (!checkPermission('content_edit')) return
    console.log(`LOG: property ---> `, property, ` \nLOG:  value ---> `, value)
    setStatusSave({
      loading: true,
    })

    let newData = { ...content, status: CONTENT.STATUS.DRAFT } as IVideo
    if (Array.isArray(property)) {
      for (let i = 0; i < property.length; i++) {
        const key = property[i]
        const val = value[i]
        newData = set<IVideo>(newData, key, val)
      }
    } else {
      newData = set<IVideo>(newData, property, value)
    }

    setContent(newData)
    saveDraft(newData)
  }
  const handleChangeStatus = async (status: string) => {
    if (!checkPermission('content_edit')) return
    if (!content?.id) return
    setStatusSave({
      loading: true,
    })
    const newData = set<IVideo>({ ...content }, 'status', status)
    setContent(newData)
    const { data } = await editContentStatus({
      variables: {
        id: content.id,
        status: status,
      },
    })
    if (data?.EditContentStatus?.statusCode === 200) {
      setStatusSave({
        loading: false,
        status_code: 200,
      })
    } else {
      // alert('error')
      console.log(`LOG: error ---> `, data?.EditContentStatus?.message)
      setStatusSave({
        loading: true,
        status_code: 400,
      })
    }
  }

  const insertContentDraft = async (newData: IVideo) => {
    if (!newData) {
      console.log('no data')
      return
    }
    newData = set(
      newData,
      'topic',
      newData.topic.map((item) => item?._id || item),
    )
    newData = set(
      newData,
      'categories',
      newData.categories.map((item) => item?._id || item),
    )

    if (!newData.id) {
      const response = await newContentDraft(newData)
      if (!response) return
      newData.id = response.id
      setContent({ ...newData })
      history.push(`/video/edit/${newData.id}`)
      return
    } else {
      console.log('save', new Date().toLocaleTimeString())
      const { data } = await editContent({
        variables: newData,
      })
      if (data.EditVideoDraft?.statusCode === 200) {
        setStatusSave({
          loading: false,
          status_code: 200,
        })
        setContent(data.EditVideoDraft?.data)
        console.log('auto save success', newData.id)
      } else {
        setStatusSave({
          loading: false,
          status_code: 400,
          error_message: 'auto save fail',
        })
        console.log('auto save fail')
      }
    }
  }

  const saveDraft = useCallback(debounce(insertContentDraft, 1000), []) // eslint-disable-line react-hooks/exhaustive-deps
  const saveDraftDelay = useCallback(debounce(insertContentDraft, 5000), []) // eslint-disable-line react-hooks/exhaustive-deps

  const onPublish = async (
    publish_time?: string | Date,
    expire_time?: string | Date,
  ) => {
    if (!checkPermission('content_publish_edit')) return
    const variables = {
      id: content?.id,
      publish_time: publishNow ? '' : publish_time,
      expire_time: !expire_time ? '' : expire_time,
    }
    const { data } = await publishContent({
      variables: variables,
    })
    console.log(`LOG: data ---> `, data)
    if (data.PublishContent?.statusCode === 200) {
      setContent(data.PublishContent.data)
    } else {
    }
  }

  // check user close tab or browser
  const onBeforeUnload = (e: BeforeUnloadEvent) => {
    if (statusSave.loading) {
      var confirmationMessage = 'o/'
      ;(e || window.event).returnValue = confirmationMessage //Gecko + IE
      return confirmationMessage //Webkit, Safari, Chrome
    }
  }

  const [isReconnect, setReconnect] = useState(0)
  useEffect(() => {
    if (content?.id) {
      let ws = new WebSocket(process.env.REACT_APP_WEB_SOCKET || '')

      ws.onopen = () => {
        const payload = {
          type: 'join',
          content_id: content?.id,
          user: userJwt,
        }
        ws.send(JSON.stringify(payload))
        if (isReconnect) {
          setReconnect(0)
        }
      }

      ws.onmessage = (e: any) => {
        const message = JSON.parse(e.data)
        // console.log(`LOG: message ---> `, message)
        const found = message?.users?.findIndex(
          (item: IUserWriting) => item.user._id === userJwt._id,
        )
        setIsDisabled(found !== 0)
        setUserWriting(message?.users)
      }

      ws.onerror = (e: any) => {
        ws.close()
      }

      ws.onclose = (e: any) => {
        // ไม่ได้เกิดจาก client close
        if (e.code === 1006) {
          setIsDisabled(true)
          setTimeout(() => {
            setReconnect(isReconnect + 1)
          }, 1000)
        } else {
          setReconnect(0)
        }
      }

      return () => {
        ws.close()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content?.id, isReconnect])

  useEffect(() => {
    window.addEventListener('beforeunload', onBeforeUnload)
    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusSave.loading])

  const store = {
    content: [content, setContent],
    publishNow: [publishNow, setPublishNow],
    handleChange,
    handleSelectChange,
    newContentDraft,
    statusSave,
    onPublish,
    dialog: [dialog, setDialog],
    onInitContent,
    gallery,
    handleChangeStatus,
    userWriting,
    isDisabled,
    suggestionTags,
    onFetchSuggestionTags,
  }

  return (
    <VideoContentContext.Provider value={store}>
      {children}
    </VideoContentContext.Provider>
  )
}
