import React from 'react'
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { Box, Chip, ListItemSecondaryAction } from '@material-ui/core'
import { IQuote } from '@Types/quote_type'



const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            paddingLeft: theme.spacing(1),
        },
        sub_title: {
            textIndent: theme.spacing(2),
            display: '-webkit-box',
            boxOrient: 'vertical',
            lineClamp: 2,
            wordBreak: 'break-all',
            overflow: 'hidden',
        },
        content_sub_title: {
            textIndent: theme.spacing(2),
            display: '-webkit-box',
            boxOrient: 'vertical',
            lineClamp: 1,
            wordBreak: 'break-all',
            overflow: 'hidden',
        },
        list_item: {
            border: '1px solid #dfdfdf',
            borderRadius: '4px',
            marginBottom: theme.spacing(2),
            // boxShadow: '2px 2px 4px #e6e6e6',
            background: 'white',
            // padding: 0
            minHeight: '125px',
        },
        link: {
            textDecoration: 'none',
            '&:hover': {
                textDecoration: 'underline',
            },
        },
            content_type_expire_time: {
            fontSize: theme.spacing(1.8),
            display: 'flex',
            alignItems: 'center',
        },
        chip: {
            textTransform: 'capitalize',
        },
        is_content_type_expire_time: {
            content: '',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: theme.palette.grey[100],
            opacity: 0.85,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            '& span': {
                fontWeight: 'bold',
                marginRight: theme.spacing(0.5),
            },
            '& a': {
                fontWeight: 'bold',
                // textDecoration: 'none',
                fontSize: theme.typography.subtitle1,
                color: '#0000ff',
            },
        },
        none: {
            display: 'none',
        },
    }),
)

interface Props {
    qoute: IQuote
    action?: () => React.ReactNode
}

const CardQuote: React.FC<Props> = ({ qoute, action }: Props) => {
    const classes = useStyles()
    //console.log('card qoute ==>', qoute)
    return (
        <ListItem>
            <ListItemText
                className={classes.title}
                primary={
                <React.Fragment>
                    <a 
                    href={`/quote/edit/${qoute?._id}`}
                    target="_blank"
                    rel="noreferrer"
                    className={classes.link}
                    >
                    <div dangerouslySetInnerHTML={{ __html: qoute?.description }} />
                    </a>
                </React.Fragment>
                }
                // primaryTypographyProps={{
                //     noWrap: false,
                // }}
            />
            {action && <ListItemSecondaryAction>{action()}</ListItemSecondaryAction>}
        </ListItem>
    )
}

export default CardQuote

