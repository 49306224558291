import { gql } from '@apollo/client'
export const QUERY_GET_ALL_MIRROR_PRODUCTS = gql`
  query getCmsProducts($page: Int, $limit: Int) {
    CmsMirrorProducts(page: $page, limit: $limit) {
      _id
      name
      image_url
      product_url
      status
      type
      price
      discount_price
      category
      created_time(format: "DD/MM/YYYY HH:mm:ss")
      updated_time(format: "DD/MM/YYYY HH:mm:ss")
    }
  }
`
export const QUERY_GET_MIRROR_PRODUCT_BY_ID = gql`
  query getCmsProduct($_id: ID!) {
    CmsMirrorProduct(_id: $_id) {
      _id
      name
      image_url
      product_url
      status
      type
      price
      discount_price
      category
      created_time(format: "DD/MM/YYYY HH:mm:ss")
      updated_time(format: "DD/MM/YYYY HH:mm:ss")
      description
      is_hide_price
    }
  }
`

export const MUTATION_ADD_MIRROR_PRODUCT = gql`
  mutation addProduct(
    $name: String
    $image_url: String
    $product_url: String
    $status: String
    $type: String
    $price: Float
    $discount_price: Float
    $category: String
    $description: String
    $is_hide_price: Boolean
  ) {
    AddMirrorProduct(
      name: $name
      image_url: $image_url
      product_url: $product_url
      status: $status
      type: $type
      price: $price
      discount_price: $discount_price
      category: $category
      description: $description
      is_hide_price: $is_hide_price
    ) {
      statusCode
      message
      data
    }
  }
`

export const MUTATION_EDIT_MIRROR_PRODUCT = gql`
  mutation editProduct(
    $_id: ID!
    $name: String
    $image_url: String
    $product_url: String
    $status: String
    $type: String
    $price: Float
    $discount_price: Float
    $category: String
    $description: String
    $is_hide_price: Boolean
  ) {
    EditMirrorProduct(
      _id: $_id
      name: $name
      image_url: $image_url
      product_url: $product_url
      status: $status
      type: $type
      price: $price
      discount_price: $discount_price
      category: $category
      description: $description
      is_hide_price: $is_hide_price
    ) {
      statusCode
      message
      data
    }
  }
`

export const QUERY_GET_ALL_MIRROR_PRODUCTS_FOR_PICK = gql`
  query getCmsProducts($params: MirrorProductParams, $page: Int, $limit: Int) {
    CmsMirrorProducts(params: $params, page: $page, limit: $limit) {
      _id
      name
      image_url
      product_url
      type
      category
      description
      price
      discount_price
    }
  }
`
