import React, { Fragment, FunctionComponent } from 'react'
import { makeStyles, Typography } from '@material-ui/core'
import { styles } from './styles'
import useUpload from '@Hooks/useUpload'

const useStyles = makeStyles(styles)

export type GalleryType = {
  mimetype: string
  image_id: string
  width: number
  height: number
  url: string
  thumb: string
}

export interface UploadImageProps {
  title?: string | React.ReactNode
  onUploaded?: (files: string[]) => void
  multi?: boolean
  uploadParams?: {
    [key: string]: string
  }
  style?: React.CSSProperties
}

export const UploadImage: FunctionComponent<UploadImageProps> = (
  props: UploadImageProps,
) => {
  const {
    title = 'upload image',
    multi = false,
    onUploaded,
    uploadParams = {},
    ...other
  } = props

  const classes = useStyles()
  const { onUpload } = useUpload()

  const onUploadBrowse = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.currentTarget.files
    if (files) await onSendUpload(files)

    // multi ? onSelectFile([...images, ...files]) : onSelectFile(files)
  }

  const onSendUpload = async (images: FileList) => {
    const imgType = ['image/jpeg', 'image/png'] 
    if (onUploaded) {
      if (images[0].size >= 2097152) {
        onUploaded(['1'])
      }else if (!imgType.includes(images[0].type)){
        onUploaded(['2'])
      } else {
        const res = await onUpload(images, 'image_files', uploadParams)
        if (res.data.status === 200) {
          onUploaded([
            ...res.data.data.items.map((item: GalleryType) => item.url),
          ])
        }
      }
    }
  }

  const overrideEventDefaults = (
    event: Event | React.DragEvent<HTMLLabelElement>,
  ) => {
    event.preventDefault()
  }

  const onDrop = async (event: React.DragEvent<HTMLLabelElement>) => {
    event.preventDefault()
    const files = event.dataTransfer.files
    await onSendUpload(files)
    // multi ? onSelectFile([...images, ...files]) : onSelectFile(files)
  }

  // const onDelete = (index: number) => {
  //   if (images.length > 0) {
  //     const newImages = images.filter((list: File, i: number) => {
  //       return i !== index
  //     })
  //     onSelectFile(newImages)
  //   }
  // }

  return (
    <Fragment>
      {/* <Grid container spacing={1}>
        <Grid item xs={12}> */}
      <div {...other}>
        <label
          className={classes.drop_container}
          onDragOver={overrideEventDefaults}
          onDragEnter={overrideEventDefaults}
          onDragLeave={overrideEventDefaults}
          onDrop={onDrop}
        >
          <input
            className={classes.input_image}
            accept="image/*"
            type="file"
            key={Math.random()}
            name="myImage"
            onChange={onUploadBrowse}
            multiple={multi}
          />
          {typeof title === 'string' ? (
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
            >
              {title}
            </Typography>
          ) : (
            <>{title}</>
          )}
        </label>
      </div>

      {/* </Grid> */}
      {/* </Grid> */}
    </Fragment>
  )
}
