import { useMutation, useQuery } from '@apollo/client'
import { Avatar, makeStyles } from '@material-ui/core'
import { ReactElement, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import {
  MUTATION_EDIT_USER,
  QUERY_GET_ALL_USERS,
  QUERY_GET_USER_BY_ID,
} from '@Api/user_api'
import Loading from '@Components/Loading'
// import user_api from '@Api/user_api'
import Form from '@Components/User/Form'
import { userType } from '@Types/user_type'
import { styles } from '@Components/User/form.style'
import useUpload from '@Hooks/useUpload'
const useStyles = makeStyles(styles)
interface ParamType {
  id: string
}
export default function EditUser(): ReactElement {
  const classes = useStyles()
  const history = useHistory()
  const { id } = useParams<ParamType>()
  const [imageProfile, setImageProfile] = useState<FileList>()
  const { onUpload } = useUpload()
  const [editUser] = useMutation(MUTATION_EDIT_USER)
  const { loading, data, error } = useQuery(QUERY_GET_USER_BY_ID, {
    variables: {
      _id: id,
    },
    fetchPolicy: 'network-only',
  })
  const handleSubmit = async (obj: userType) => {
    let current_img = data.User.profile_image
    if (imageProfile) {
      const res_image = await onUpload(imageProfile, 'image_files', {
        ref_id: id,
        type: 'replace',
      })
      current_img = res_image.data.data?.items[0]?.url
    }

    const res = await editUser({
      variables: {
        ...obj,
        profile_image: current_img,
        _id: id,
      },
      refetchQueries: [
        {
          query: QUERY_GET_ALL_USERS,
        },
      ],
    })
    if (res.data.EditUser.statusCode === 200) {
      history.push('/user')
    }
  }
  const handleChangeFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const imgs = event.target.files
    if (imgs) setImageProfile(imgs)
  }

  if (loading) return <Loading />
  if (error) {
    console.log('LOG: error ---> ', error)
    return <p>Error :(</p>
  }
  return (
    <>
      {!loading && (
        <Form
          bypass={false}
          defaultData={data?.User}
          avatar={() => {
            return (
              <>
                <Avatar
                  className={classes.avatar}
                  src={
                    imageProfile
                      ? URL.createObjectURL(imageProfile[0])
                      : data?.User?.profile_image + '?width=160'
                  }
                />
                <input
                  type="file"
                  onChange={handleChangeFile}
                  className={classes.upload_profile}
                />
              </>
            )
          }}
          onSubmit={handleSubmit}
          title="แก้ไขข้อมูลผู้ใช้งาน"
          display={{
            password: false,
            username: false,
          }}
        />
      )}
    </>
  )
}
