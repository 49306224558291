import React from 'react'
import { Draggable } from 'react-beautiful-dnd'

interface Props {
  index: number
  draggableId: string
  isDragDisabled?: boolean
  children?: React.ReactNode
}

const DragItem: React.FC<Props> = ({
  index,
  draggableId,
  children,
  isDragDisabled = false,
}) => {
  return (
    <Draggable
      isDragDisabled={isDragDisabled}
      draggableId={draggableId}
      index={index}
    >
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          {children}
        </div>
      )}
    </Draggable>
  )
}

export default DragItem
