import React, { useContext, useState } from 'react'
//Autocomplete
import Autocomplete from '@material-ui/lab/Autocomplete'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import { makeStyles, TextField, Checkbox } from '@material-ui/core'
import {
  ContentContext,
  ContentContextType,
} from '../../context/ContentContext'
import { styles } from '../styles'
import { useQuery } from '@apollo/client'
import { 
  QUERY_GET_TOPIC_AND_CATEGORY, 
  QUERY_GET_SUBTOPICS 
} from '@Api/content_api'
import { ICategory } from '@Types/category_type'
import { ITopic } from '@Types/topic_type'
import { ISubTopic } from '@Types/topic_sub_type'
import { useGlobal } from '@Hooks/useGlobal'
import Loading from '@Components/Loading'

const useStyles = makeStyles(styles)

function SettingThairathPlus() {
  const classes = useStyles()
  const { wording } = useGlobal()
  const { data, loading } = useQuery(QUERY_GET_TOPIC_AND_CATEGORY)
  const { data: dataSubTopic, loading: loadingSubtopic } = useQuery(QUERY_GET_SUBTOPICS)
  const {
    content: [content],
    handleSelectChange,
    onFetchSuggestionTags,
  } = useContext(ContentContext) as ContentContextType
  
  const setFormatData = (data: ITopic[] | ICategory[] | ISubTopic[]) => {
    return data.map((item: any) => ({
      id: item?._id,
      value: item?.label,
    }))
  }
  const setFormatSubTopicData = (data: ISubTopic[]) => {
    return data.map((item: any) => ({
      id: item?._id,
      value: item?.label,
    }))
  }

  const [topicSelected, setTopicSelected] = useState(
    setFormatData(content.topic),
  )
  const [categorySelected, setCategorySelected] = useState(
    setFormatData(content.categories),
  )
  const [subTopicSelected, setSubTopicSelected] = useState(
    setFormatSubTopicData(content.subtopic),
  )

  const arrSubTopics = () => {
    let listSubTopics = dataSubTopic.SubTopics
    let selectTopicId: string = topicSelected[0]?.id || '';
    let filteredListSubTopics:any = []

    if(selectTopicId.length > 0) {
      // eslint-disable-next-line array-callback-return
      listSubTopics.map((obj: any) => { 
        if(obj.topicRef === selectTopicId) 
          filteredListSubTopics[filteredListSubTopics.length] = obj
      })

      return filteredListSubTopics
    }
    else {
      return listSubTopics
    }
  }

  if (loading || loadingSubtopic) return <Loading />

  return (
    <>
      {/* Topic */}
      <Autocomplete
        onChange={(_, values) => {
          if (values) {
            handleSelectChange('subtopic', [])
            setSubTopicSelected([])

            handleSelectChange('topic', [values.id])
            setTopicSelected([values])
            
            const topic_value = data.Topics.find(
              (item: { id: string; label: string; topic_value: string }) =>
                item.id === values.id,
            ).topic_value
            onFetchSuggestionTags(topic_value)
          } else {
            handleSelectChange('subtopic', [])
            setSubTopicSelected([])

            handleSelectChange('topic', [])
            setTopicSelected([])
          }
        }}
        size="small"
        options={data.Topics}
        value={topicSelected[0]}
        getOptionLabel={(option) => option.value}
        classes={{
          paper: classes.menu_item,
        }}
        renderInput={(params) => (
          <TextField {...params} variant="outlined" label={wording['topic']} />
        )}
      />
      {/* SubTopic */}
      <Autocomplete
        onChange={(_, values) => {
          if (values) {
            handleSelectChange('subtopic', [values.id])
            setSubTopicSelected([values])
          } else {
            handleSelectChange('subtopic', [])
            setSubTopicSelected([])
          }
        }}
        size="small"
        options={arrSubTopics()}
        value={subTopicSelected[0]}
        getOptionLabel={(option) => option.value}
        classes={{
          paper: classes.menu_item,
        }}
        renderInput={(params)=>(
          <TextField {...params} variant="outlined" label={wording['subtopic']} />
        )}
      />
      {/* Category */}
      <Autocomplete
        onChange={(_, values) => {
          handleSelectChange(
            'categories',
            values.map(({ id }) => id),
          )
          setCategorySelected([...values])
        }}
        multiple
        size="small"
        options={data.Categories}
        disableCloseOnSelect
        value={categorySelected}
        getOptionSelected={(option, value) => value.id === option.id}
        getOptionLabel={(option: { id: string; value: string }) => option.value}
        classes={{
          paper: classes.menu_item,
        }}
        renderOption={(option: { id: string; value: string }, { selected }) => (
          <React.Fragment>
            <Checkbox
              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
              checkedIcon={<CheckBoxIcon fontSize="small" />}
              checked={selected}
              size="small"
              style={{
                height: 28,
                width: 28,
                marginRight: 8,
              }}
            />
            {option.value}
          </React.Fragment>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label={wording['category']}
          />
        )}
      />
    </>
  )
}

export default SettingThairathPlus
