import React, { useContext, useState } from 'react'
//Autocomplete
import Autocomplete from '@material-ui/lab/Autocomplete'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import { makeStyles, TextField, Checkbox } from '@material-ui/core'
import {
  VideoContentContext,
  VideoContentContextType,
} from '../../context/VideoContext'
import { styles } from '../styles'
import { useQuery } from '@apollo/client'
import { QUERY_GET_TOPIC_AND_CATEGORY } from '@Api/content_api'
import { ICategory } from '@Types/category_type'
import { ITopic } from '@Types/topic_type'
import { useGlobal } from '@Hooks/useGlobal'
import Loading from '@Components/Loading'

const useStyles = makeStyles(styles)

function SettingThairathPlus() {
  const classes = useStyles()
  const { wording } = useGlobal()
  const { data, loading } = useQuery(QUERY_GET_TOPIC_AND_CATEGORY)
  const {
    content: [content],
    handleSelectChange,
    onFetchSuggestionTags,
  } = useContext(VideoContentContext) as VideoContentContextType

  const setFormatData = (data: ITopic[] | ICategory[]) => {
    return data.map((item: any) => ({
      id: item?._id,
      value: item?.label,
    }))
  }

  const [topicSelected, setTopicSelected] = useState(
    setFormatData(content.topic),
  )
  const [categorySelected, setCategorySelected] = useState(
    setFormatData(content.categories),
  )

  if (loading) return <Loading />
  return (
    <>
      {/* Topic */}
      <Autocomplete
        onChange={(_, values) => {
          if (values) {
            handleSelectChange('topic', [values.id])
            setTopicSelected([values])
            
            const topic_value = data.Topics.find(
              (item: { id: string; label: string; topic_value: string }) =>
                item.id === values.id,
            ).topic_value
            onFetchSuggestionTags(topic_value)
          } else {
            handleSelectChange('topic', [])
            setTopicSelected([])
          }
        }}
        size="small"
        options={data.Topics}
        value={topicSelected[0]}
        getOptionLabel={(option) => option.value}
        classes={{
          paper: classes.menu_item,
        }}
        renderInput={(params) => (
          <TextField {...params} variant="outlined" label={wording['topic']} />
        )}
      />
      {/* Category */}
      <Autocomplete
        onChange={(_, values) => {
          handleSelectChange(
            'categories',
            values.map(({ id }) => id),
          )
          setCategorySelected([...values])
        }}
        multiple
        size="small"
        options={data.Categories}
        disableCloseOnSelect
        value={categorySelected}
        getOptionSelected={(option, value) => value.id === option.id}
        getOptionLabel={(option: { id: string; value: string }) => option.value}
        classes={{
          paper: classes.menu_item,
        }}
        renderOption={(option: { id: string; value: string }, { selected }) => (
          <React.Fragment>
            <Checkbox
              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
              checkedIcon={<CheckBoxIcon fontSize="small" />}
              checked={selected}
              size="small"
              style={{
                height: 28,
                width: 28,
                marginRight: 8,
              }}
            />
            {option.value}
          </React.Fragment>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label={wording['category']}
          />
        )}
      />
    </>
  )
}

export default SettingThairathPlus
