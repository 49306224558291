import { gql } from '@apollo/client'

export const QUERY_GET_PRODUCT_MANAGE_BY_SECTION = gql`
  query getProductManageBySection($section: String!) {
    CmsMirrorProductManage(section: $section) {
      _id
      section
      products {
        _id
        name
        description
        image_url
        product_url
        price
        is_hide_price
        status
        discount_price
        type
        category
      }
      created_time
      is_random
    }
  }
`

export const MUTATION_ADD_PRODUCT_MANAGE = gql`
  mutation addProductManage(
    $section: String
    $products: [String]
    $is_random: Boolean
  ) {
    AddMirrorProductManage(
      section: $section
      products: $products
      is_random: $is_random
    ) {
      statusCode
      message
    }
  }
`
