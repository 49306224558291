import { Drawer, List, makeStyles } from '@material-ui/core'
import React, { useMemo } from 'react'
import ListItemLink from '../ListItemLink'
import { styles } from './layout.style'
// import { checkPermission } from '../checkPermission'
import { useAuth } from '@Hooks/useAuth'
import { routes, RouteType } from '../../routes'
import { useGlobal } from '@Hooks/useGlobal'
import isEmpty from 'lodash.isempty'
import ThairathPlusIcon from '@Components/Icon/thairath_plus'
import MirrorIcon from '@Components/Icon/mirror'
import clsx from 'clsx'
import { matchPath, useLocation } from 'react-router'

const useStyles = makeStyles(styles)
const Sidebar: React.FC = () => {
  const classes = useStyles()
  const { theme_name, openMenu } = useGlobal()
  const menu = useMemo(
    () =>
      routes.map((route, index) => (
        <RenderMenu route={route} key={`${route.path}${index}`} />
      )),
    [],
  )
  const getIconApp = () => {
    switch (theme_name) {
      case 'thairath-plus':
        return (
          <ThairathPlusIcon
            // onClick={() => onChangeThemeName('mirror')}
            className={classes.logo}
          />
        )
      case 'mirror':
        return (
          <MirrorIcon
            // onClick={() => onChangeThemeName('thairath-plus')}
            className={classes.logo}
          />
        )
      default:
        return <></>
    }
  }
  return (
    <>
      <Drawer
        variant="persistent"
        anchor="left"
        open={openMenu}
        // className={classes.drawer}
        classes={{
          paper: classes.drawer_paper,
        }}
        className='sidebar-left'
      >
        {getIconApp()}
        <List component="nav" className={classes.list}>
          {menu}
        </List>
      </Drawer>
    </>
  )
}

const RenderMenu = ({ route }: { route: RouteType }) => {
  const classes = useStyles()
  const { checkPermission } = useAuth()
  const { theme_name } = useGlobal()
  const location = useLocation()
  const checkDisplayMenu = (data: RouteType) => {
    /**
     * check flag in sidebar
     */
    if (!data.in_sidebar) return false

    /**
     * check theme
     */
    if (!isEmpty(data.themes) && !data.themes?.some((v) => v === theme_name))
      return false

    /**
     * bypass when is not require permissions
     */
    if (isEmpty(data.permissions)) return true

    /**
     * check permission
     */
    return checkPermission(data.permissions, 'or')
  }

  /**
   * useMemo must declare before return
   * summary permissions in childrens
   */
  const route_root = useMemo(
    () =>
      route.childrens.reduce((a, b) => {
        const prev_data = a.permissions || []
        return { ...a, permissions: [...prev_data, ...b.permissions] }
      }, route),
    [route],
  )

  /**
   * check have not childrens in sidebar
   */
  if (route.childrens.every((children) => children.in_sidebar === false)) {
    /**
     * check permission display when have single menu
     */
    return checkDisplayMenu(route) ? (
      <ListItemLink
        id={`${route.path}`}
        icon={route.icon}
        className={clsx(
          classes.button,
          !!matchPath(location.pathname, {
            path: route.path,
          }) && classes.menu_active,
        )}
        label={route.label}
        to={route.path}
        key={route.path}
      />
    ) : null
  }

  /**
   * if summary permissions is not empty then check permission
   * check permission display when have multi menu in childrens
   */
  if (!checkDisplayMenu(route_root)) return null

  return (
    <ListItemLink
      id={`${route.path}`}
      icon={route.icon}
      label={route.label}
      to={route.path}
      key={route.path}
      className={classes.button}
      open={
        route.childrens.findIndex(
          (f) =>
            !!matchPath(location.pathname, {
              path: f.path,
              exact: true,
            }),
        ) !== -1
      }
    >
      {route.childrens && (
        <List component="div" disablePadding>
          {route.childrens.map((children, index2) => {
            return (
              checkDisplayMenu(children) && (
                <ListItemLink
                  to={children.path}
                  id={`${children.path}${index2}`}
                  icon={children.icon}
                  parent={route.path}
                  label={children.label}
                  key={children.path}
                  className={clsx(
                    classes.nested,
                    !!matchPath(location.pathname, {
                      path: children.path,
                    }) && classes.menu_active,
                  )}
                />
              )
            )
          })}
        </List>
      )}
    </ListItemLink>
  )
}

export default Sidebar
