import { IRule } from './index'

export const rules: Array<IRule> = [
  {
    name: 'Content',
    group: [
      {
        name: 'Content All (ดู , สร้าง , แก้ไข)',
        key: 'content',
        type: {
          view: true,
          edit: true,
        },
      },
      {
        name: 'Content Publish',
        key: 'content_publish',
        type: {
          view: false,
          edit: true,
        },
      },
    ],
  },
  {
    name: 'Editor',
    group: [
      {
        name: 'Editor Pick',
        key: 'editor',
        type: {
          view: true,
          edit: true,
        },
      },
    ],
  },
  {
    name: 'Latest',
    group: [
      {
        name: 'Latest Pick',
        key: 'latest',
        type: {
          view: true,
          edit: true,
        },
      },
    ],
  },
  {
    name: 'Sub-Latest',
    group: [
      {
        name: 'Sub-Latest Pick',
        key: 'sublatest',
        type: {
          view: true,
          edit: true,
        },
      },
    ],
  },
  {
    name: 'Author',
    group: [
      {
        name: 'Author All (ดู , สร้าง , แก้ไข)',
        key: 'author',
        type: {
          view: true,
          edit: true,
        },
      },
    ],
  },
  {
    name: 'Admin',
    group: [
      {
        name: 'Admin all (ดู , สร้าง , แก้ไข)',
        key: 'admin',
        type: {
          view: true,
          edit: true,
        },
      },
    ],
  },
  {
    name: 'SEO',
    group: [
      {
        name: 'Seo Tag Suggestion (tag แนะนำ)',
        key: 'seo_tag_suggestion',
        type: {
          view: true,
          edit: true,
        },
      },
      {
        name: 'Meta Tag Pages',
        key: 'seo_tag_pages',
        type: {
          view: true,
          edit: true,
        },
      },
    ],
  },
  {
    name: 'Category',
    group: [
      {
        name: 'Category (ดู , สร้าง , แก้ไข)',
        key: 'category',
        type: {
          view: true,
          edit: true,
        },
      },
    ],
  },
]

export const getPermissionByRole = (role: string) => {
  switch (role) {
    case 'Editor':
      return [
        'content_view',
        'content_edit',
        'content_publish_edit',
        'podcast_view',
        'podcast_edit',
        'podcast_publish_edit',
        'editor_view',
        'editor_edit',
        'latest_view',
        'latest_edit',
        'sub_latest_view',
        'sub_latest_edit',
      ]
    case 'Editor Manager':
      return [
        'content_view',
        'content_edit',
        'content_publish_edit',
        'podcast_view',
        'podcast_edit',
        'podcast_publish_edit',
        'editor_view',
        'editor_edit',
        'latest_view',
        'latest_edit',
        'sub_latest_view',
        'sub_latest_edit',
      ]
    case 'Product':
      return [
        'content_view',
        'content_edit',
        'content_publish_edit',
        'podcast_view',
        'podcast_edit',
        'podcast_publish_edit',
        'editor_view',
        'editor_edit',
        'latest_view',
        'latest_edit',
        'sub_latest_view',
        'sub_latest_edit',
        'author_view',
        'author_edit',
        'seo_tag_suggestion_view',
        'seo_tag_suggestion_edit',
        'seo_tag_pages_view',
        'seo_tag_pages_edit',
      ]
    case 'Admin':
      return [
        'content_view',
        'content_edit',
        'content_publish_edit',
        'podcast_view',
        'podcast_edit',
        'podcast_publish_edit',
        'editor_view',
        'editor_edit',
        'latest_view',
        'latest_edit',
        'sub_latest_view',
        'sub_latest_edit',
        'author_view',
        'author_edit',
        'admin_view',
        'admin_edit',
        'seo_tag_suggestion_view',
        'seo_tag_suggestion_edit',
        'seo_tag_pages_view',
        'seo_tag_pages_edit',
        'category_view',
        'category_edit',
      ]
    case 'SEO':
      return [
        'content_view',
        'content_edit',
        'content_publish_edit',
        'podcast_view',
        'podcast_edit',
        'podcast_publish_edit',
        'seo_tag_suggestion_view',
        'seo_tag_suggestion_edit',
        'seo_tag_pages_view',
        'seo_tag_pages_edit',
      ]
    case 'Custom':
      return []
    default:
      return []
  }
}

const thairath_plus_role = {
  rules,
  getPermissionByRole,
}
export default thairath_plus_role
