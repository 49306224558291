import { useState, useEffect, Fragment } from 'react'
import { useParams } from 'react-router-dom'
import { useSnackbar } from '@Hooks/useSnackbar'
import useSeo from '../context/SeoContext'
import { useAuth } from '@Hooks/useAuth'
import { useMutation } from '@apollo/client'
import { MUTATION_EDIT_METATAG, QUERY_ALL_METATAGS } from '@Api/metatag_api'
import { UploadImage } from '@Components/Upload'
import { Grid, makeStyles, Paper, Typography } from '@material-ui/core'
import { isEmpty } from 'lodash'

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    minHeight: 650,
    padding: theme.spacing(2),
  },
  grow: {
    flexGrow: 1,
  },
}))
const TopicImage = () => {
  const classes = useStyles()
  const { onToggle } = useSnackbar()
  const { data, setData } = useSeo() as any
  const { id } = useParams<{ id: string }>()
  const { checkPermission } = useAuth()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [topic, setTopic] = useState<string>('/topic')

  // const [errorMessages, setErrorMessages] = useState({
  //   image_url: '',
  // })

  useEffect(() => {
    if (!checkPermission('seo_tag_pages_edit')) window.location.href = '/'
    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const [editMetatag] = useMutation(MUTATION_EDIT_METATAG)
  const onUploaded = async (images: string[]) => {
    if (images && images.length > 0) {
      const res = await editMetatag({
        variables: {
          cover_url: images[0],
          _id: id,
        },
        refetchQueries: [
          {
            query: QUERY_ALL_METATAGS,
          },
        ],
      })
      if (res.data?.EditMetatag?.statusCode === 200) {
        setData({ ...data, cover_url: images[0] })
        onToggle({ type: 'success', open: true, message: 'อัพโหลดรูปสำเร็จ!' })
      } else {
        onToggle({
          type: 'error',
          open: true,
          message: 'อัพโหลดรูปไม่สำเร็จ!',
        })
        console.log(`LOG: cannot save latest pick ---> `, res.data.message)
      }
    }
  }
  const getTypeUrlImage = (url: string) => {
    if (url && url !== null) {
      const dataCoverUrl = url.split('.')
      return dataCoverUrl[dataCoverUrl.length - 1]
    }
  }

  return (
    <Paper className={classes.paper}>
      <Grid container>
        <Grid item>
          <Typography variant="h6">Image</Typography>
        </Grid>
        <div className={classes.grow} />
        {/* <Grid item>
          <Button
            startIcon={<Save />}
            onClick={onSubmit}
            variant="outlined"
            type="submit"
          >
            บันทึกข้อมูล
          </Button>
        </Grid> */}
      </Grid>
      <UploadImage
        title={
          <>
            {isEmpty(data.cover_url) ? (
              <div style={{ marginBottom: 4 }}>
                <strong>Drag and Drop image(s) here</strong>
              </div>
            ) : (
              <Fragment>
                {/* {console.log(getTypeUrlImage(data.cover_url))} */}
                {getTypeUrlImage(data.cover_url) === 'mp4' ? (
                  <video autoPlay={true} muted loop src={data.cover_url}  style={{ height: '100%' }}/>
                ) : (
                  <img
                    alt="image_topic"
                    src={data.cover_url}
                    style={{ height: '100%' }}
                  />
                )}
              </Fragment>
            )}
          </>
        }
        onUploaded={(images) => onUploaded(images)}
        uploadParams={{
          ref_id: topic,
        }}
      />
    </Paper>
  )
}

export default TopicImage
