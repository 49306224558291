import { removeTokenUser } from './../hooks/useAuth'
import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
} from '@apollo/client'
import Axios, { AxiosResponse, AxiosRequestConfig, Method } from 'axios'
import { setContext } from '@apollo/client/link/context'
import { getTokenUser } from '@Hooks/useAuth'
import { onError } from '@apollo/client/link/error'
import { GraphQLError } from 'graphql'
interface GraphQLErrorCustom extends GraphQLError {
  statusCode: number
}

const thairathPlus = async function (
  method: Method,
  path: string,
  body?: any,
): Promise<any> {
  const options: AxiosRequestConfig = {
    method,
    data: body,
    url: `${process.env.REACT_APP_TR_PLUS_API}/${path}`,
    withCredentials: true,
  }
  const response: AxiosResponse = await Axios(options)
  if (response.data.status === 200) {
    return response.data
  } else {
    // console.log(`User lib error ${path}: ${response.data.message}`)
    return response.data
  }
}
const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = getTokenUser()
  // return the headers to the context so httpLink can read them
  // console.log(`LOG: token ---> `, token)
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  }
})
const errorLink = onError(({ graphQLErrors }) => {
  const graphql_errors = graphQLErrors as GraphQLErrorCustom[]
  if (graphql_errors?.find((item) => item.statusCode === 401)) {
    removeTokenUser()
    window.location.reload()
  }
})
const link: ApolloLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPH_QL, // Apollo Server is served from port 4000
  credentials: 'include',
})

const graphql = new ApolloClient({
  link: errorLink.concat(authLink).concat(link),
  cache: new InMemoryCache({
    addTypename: false,
  }),
})

const wrapper = {
  thairathPlus,
  graphql,
}
export default wrapper
